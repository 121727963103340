import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select'

export default function BillingChoose() {
    let navigate = useNavigate();
    const [teams,SetTeams] = useState([]);
    const [companies,SetCompanies] = useState([]);
    const [agents,SetAgents] = useState([]);
    const [agentChoose,SetAgentChoose] = useState(false);
    const [companyChoose,SetCompanyChoose] = useState(false);
    const [groupChoose,SetGroupChoose] = useState(false);
    const [selectedCompany,SetSelectedCompany] = useState();
    const [selectedAgent,SetSelectedAgent] = useState();
    const [selectedGroup,SetSelectedGroup] = useState();

    useEffect(()=>{
        if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
            axios.get('https://www.clearcall.tel:8078/companies')
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Title
                    })
            
        })
        SetCompanies(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
        }
        else{
        axios.post('https://www.clearcall.tel:8078/agents/access', {
            user_info_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
            company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            if(el.has_access===1){
            arr.push({
                        value:el.id,
                        label:el.title
                    })
            }
            
        })
        SetTeams(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    },[])
    const HandleCompanyChoose=(el)=>{
        SetCompanyChoose(true);
        SetSelectedCompany(el.value);
        axios.post('https://www.clearcall.tel:8078/teams/company', {
            company_id:el.value
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.id,
                        label:el.title
                    })
        })
        SetTeams(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    const HandleGroupChoose=(el)=>{
        SetGroupChoose(true);
        SetSelectedGroup(el.value);
        axios.post('https://www.clearcall.tel:8078/agents/getFromTeam', {
            team_id:el.value
       })
       .then((res)=>{
        var arr = [];
        res.data.forEach((el)=>{
            console.log(el);
            arr.push({
                        value:el.Id,
                        label:el.Login
                    })
        })
        SetAgents(arr);
        
        console.log(res.data);
       })
       .catch((err)=>console.log(err))
    }
    const HandleAgentChoose=(el)=>{
        SetAgentChoose(true);
        SetSelectedAgent(el.label);
    }
    const HandleCompanyTeamRecords=()=>{
        navigate(`/billing/management/${selectedCompany}/${selectedGroup}`);
    }
    const HandleCompanyAgentRecords=()=>{
        navigate(`/records/${selectedCompany}/${selectedGroup}/${selectedAgent}`);
        
    }
    const HandleCompanyRecords=()=>{
        navigate(`/billing/management/${selectedCompany}`);
        
    }
    const HandleTeamRecords=()=>{
        navigate(`/records/${JSON.parse(secureLocalStorage.getItem('user')).company_id}/${selectedGroup}`);
    }
    const HandleAgentRecords=()=>{
        navigate(`/records/${JSON.parse(secureLocalStorage.getItem('user')).company_id}/${selectedGroup}/${selectedAgent}`);
        
    }
  return (
    <div className="container-xxl flex-grow-1 container-p-y" style={{marginInline:0}} >
    <div className='card' style={{height:"30.5%", borderRadius:"1.5vh", width:"100%", padding:'0.5vw', paddingTop:0,width:"70vw"}}>
        <div className="card-header ">
            <h5 className="card-title" style={{fontSize:"2.5vh", fontWeight:"bold"}}>Select billing to show</h5>
        </div>
        <div style={{display:'flex', flexDirection:"row", marginLeft:"-10px"}}>
            <div style={{display:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?'flex':'none',flexDirection:'column',fontSize:"1.5vh"}}>
            <div className="mb-3" style={{width:'12.5vw', margin:'0.5vw',marginTop:"2.5vh"}}>
                {/* <label htmlFor="flatpickr-range" className="form-label">Select company</label> */}
                <Select placeholder='Select company'
                                onChange={(e)=>HandleCompanyChoose(e)}
                                id='operatorLb' 
                                isDisabled={false} 
                                    options={companies} 
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                            borderColor:"#444663"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#949fae",
                                        }),
                                    }}/>
            </div>
        </div>
        <div style={{display:'flex',flexDirection:'column',fontSize:"1.5vh"}}>
        <div className="mb-3" style={{width:'12.5vw', margin:'0.5vw',marginTop:"2.5vh"}}>
                {/* <label htmlFor="flatpickr-range" className="form-label">Select group</label> */}
                <Select placeholder='Select region'      
                                onChange={(e)=>HandleGroupChoose(e)}
                                id='operatorLb' 
                                isDisabled={false} 
                                options={[
                                    {value:'ru',label:'ru'},
                                    {value:'eu',label:'eu'},
                                    {value:'full',label:'full'},
                                ]} 
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#fff":"#444663":"#444663",
                                        color:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#b8b8cd":"#b8b8cd",
                                        borderColor:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#444663":"#444663"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                        };
                                    },
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#fff":"#444663":"#444663",
                                        color:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#b8b8cd":"#b8b8cd",
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#fff":"#444663":"#444663",
                                        color:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?!companyChoose?"#949fae":"#b8b8cd":"#b8b8cd",
                                    }),
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:!companyChoose?"#fff":"#444663",
                                        color:"#949fae",
                                    }),
                                }}/>
            </div>
        </div>
        <div style={{display:'flex',flexDirection:'row', marginTop:"-0.5vh"}}>
            <button className='btn btn-label-primary' 
            style={{width:'8vw', height:'5vh', margin:'0.5vw', alignSelf:"center", marginTop:'2vh',fontSize:'1.5vh', fontWeight:"bold"}}
            disabled={!groupChoose} onClick={()=>HandleCompanyTeamRecords()}>Region billing</button>
            <button className='btn btn-label-primary' 
            style={{display:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?'flex':'none',width:'8vw', height:'5vh', margin:'0.5vw', alignSelf:"center", marginTop:'2vh',fontSize:'1.5vh', fontWeight:"bold"}}
            disabled={!companyChoose} onClick={()=>HandleCompanyRecords()}>Company billing</button>
        </div>
        </div>
        
    </div>
    </div>
  )
}
