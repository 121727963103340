import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';

export default function Departments() {
    const nav = useNavigate();
    const params = useParams(); 
    const officeid = params.officeid;
    const title = useRef(null);
    const [Entities, SetEntities] = useState([]);
    const [departmentid, setdepartmentid] = useState(0);
    const {t, i18n} = useTranslation();
    function handleSubmit(){
        const element = title.current;
        if(element.value !== ""){
            axios.post("https://www.clearcall.tel:8078/departments/add",{title:element.value, office_id:officeid})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  icon: 'success',
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
      connect()
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
      connect()
              console.log(err)
            });
        }
        else alert("enter title");
    }
    function handleUpdate(){
        const element = title.current;
        if(element.value !== ""){
            axios.post("https://www.clearcall.tel:8078/departments/update",{title:element.value, id:departmentid})
            .then((res)=>{
                console.log(res);
                Swal.fire({
                  title: 'Result',
                  text: res.statusText,
                  timer:2000,
                  customClass: {
                  confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
              })
      connect()
            })
            .catch((err)=>{
              Swal.fire({
                title: 'Result',
                text: err.statusText,
                timer:2000,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
      connect()
              console.log(err)
            });
        }
        else alert("enter title");
    }
    async function handleDelete () {
      let isdel = await SwalDel();
      console.log(isdel);
      if(isdel){
        axios.delete("https://www.clearcall.tel:8078/departments/delete",{data:{id:departmentid} })
        .then((res)=>{
          console.log(res);
          Swal.fire({
            icon:'success',
            title: 'Result',
            text: res.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connect()
      })
        .catch((err)=>{
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Authentification error.',
            text: err.statusText,
            timer:2000,
            customClass: {
            confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
        })
      connect()
        })
      }
    }
    const SwalDel =()=>{
        return Swal.fire({ // <-- return Promise
          title: 'Delete record?',
          text: 'This action cannot be undone.',
          icon: 'question',
          confirmButtonText: 'Delete',
          confirmButtonColor: '#DC004E',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false
        }).then((result) => {
          return result.isConfirmed;
        });
    }
    function onUpdate(ind){
        setdepartmentid(Entities[ind].id);
        const element = title.current;
        console.log(Entities);
        element.value = Entities[ind].title;
        const el = document.getElementById('submitbtn');
        el.disabled = true;
        const el1 = document.getElementById('updatebtn');
        el1.disabled = false;
    }
    function onAdd(){
        const element = document.getElementById('updatebtn');
        element.disabled = true; 
        const el1 = document.getElementById('submitbtn');
        el1.disabled = false;
    }
    function handleCheck(ind){
        nav(`${window.location.pathname}/${Entities[ind].id}/teams`);
    }
    const connect = () => {
      axios.get("https://www.clearcall.tel:8078/departments/"+officeid)
        .then((res)=>{
            //console.log(res);
            SetEntities(res.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    useEffect(()=>{
      connect()
    },[])

    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role === 5 || role === 6 || role === 8){
        return <Navigate to="/denied" replace={true}/>
    }
    else
  return (
    <div className="container-xxl flex-grow-1 container-p-y" style={{marginInline:0}}>
    <div className="card" style={{width:"80vw"}}>
      <div className="card-header border-bottom">
        <h3 className="card-title" style={{fontSize:"2.5vh", fontWeight:"bold"}}>{t("departments")}</h3>
        {/* Add new company button */}
        <div className="col-md-10">
            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-start flex-md-row flex-column mb-3 mb-md-0">
                <div className="dt-buttons btn-group flex-wrap"> 
                    <button className="btn btn-secondary add-new btn-primary"
                     tabIndex={0} aria-controls="DataTables_Table_0" 
                     type="button" data-bs-toggle="offcanvas"
                     style={{fontSize:"1.5vh"}} 
                     data-bs-target="#offcanvasAddUser"
                     onClick={onAdd}
                     >
                        <span><i className="bx bx-plus me-0 me-sm-1" 
                      style={{fontSize:"2vh"}}/>
                        <span className="d-none d-sm-inline-block">{t("departmentsAdd")}</span>
                        </span>
                    </button> 
                </div>
            </div>
        </div>
      </div>
      <div className="card-datatable table-responsive">
        <table className="datatables-users table border-top">
          <thead>
            <tr>
              <th style={{fontSize:"1.8vh"}}>{t("companiesThTitle")}</th>
              <th style={{fontSize:"1.8vh"}}>{t("companiesThUpdate")}</th>
              <th style={{fontSize:"1.8vh"}}>{t("departmentsCheck")}</th>
            </tr>
          </thead>
          <tbody>
            {Entities.length !== 0 ? 
            Entities.map((cmp,ind)=>{
              if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1
              || JSON.parse(secureLocalStorage.getItem("user")).role_id===7 
              || JSON.parse(secureLocalStorage.getItem("user")).role_id===4 
              )
              return <tr key={ind}  style={{fontWeight:"bold", fontSize:"1.5vh"}}>
                        <td><b>{cmp.title}</b></td>
                        <td><button type="button" className='btn btn-warning' 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#offcanvasAddUser"
                        onClick={()=>onUpdate(ind)}
                        style={{fontSize:"1.5vh"}}
                        >{t("updatebtn")}</button></td>
                        <td><button type="button" className='btn btn-info' onClick={()=>handleCheck(ind)}
                    style={{fontSize:"1.5vh"}}>{t("departmentsCheck")}</button></td>
                    </tr>
              else 
                return JSON.parse(secureLocalStorage.getItem("user")).department_id !== cmp.id
                ? null
                : <tr key={ind}  style={{fontWeight:"bold", fontSize:"1.5vh"}}>
                <td><b>{cmp.title}</b></td>
                <td><button type="button" className='btn btn-warning' 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasAddUser"
                onClick={()=>onUpdate(ind)}
                style={{fontSize:"1.5vh"}}
                >{t("updatebtn")}</button></td>
                <td><button type="button" className='btn btn-info' onClick={()=>handleCheck(ind)}
                    style={{fontSize:"1.5vh"}}>{t("departmentsCheck")}</button></td>
                </tr>
            })
            :null }
          </tbody>
        </table>
      </div>
      {/* Offcanvas to add new instance */}
      <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser"  style={{width:'22.5vw',fontSize:"1.5vh"}} aria-labelledby="offcanvasAddUserLabel">
        <div className="offcanvas-header">
          <h3 id="offcanvasAddUserLabel" className="offcanvas-title">{t("departments")}</h3>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body mx-0 flex-grow-0">
          <form className="add-new-user pt-0" id="addNewDepartment" onsubmit="return false">
            <div className="mb-3">
              <label className="form-label" htmlFor="add-department-title" style={{fontSize:"1.5vh"}}>{t("companiesThTitle")}</label>
              <input type="text"  style={{fontSize:"1.5vh"}}
                className="form-control" 
                id="add-department-title" 
                placeholder={t("departmentsPlaceholder")} 
                name="departmentTitle" 
                aria-label="Department title"
                ref={title} />
            </div>
            <button 
                id='submitbtn' style={{fontSize:"1.5vh"}}
                type="button" 
                className="btn btn-success me-sm-3 me-1 data-submit"
                onClick={handleSubmit}
                >{t("submitbtn")}</button>
            <button 
                id='updatebtn' style={{fontSize:"1.5vh"}}
                type="button" 
                className="btn btn-warning me-sm-3 me-1 data-submit"
                onClick={handleUpdate}
                >{t("updatebtn")}</button>
                <button type="button" className='btn btn-danger me-sm-3 me-1' style={{fontSize:"1.5vh"}} onClick={()=>handleDelete()} disabled={false}>{t("deletebtn")}</button>
            <button 
                type="button"  style={{fontSize:"1.5vh"}}
                className="btn btn-label-secondary" 
                data-bs-dismiss="offcanvas">
                {t("cancelbtn")}</button>
          </form>
        </div>
      </div>
    </div>
    
    </div>
  )
}
