import React from 'react'
import { useTranslation } from 'react-i18next';
import HashLoader from 'react-spinners/HashLoader';

export default function NoAccess() {
  const {t,i18n} = useTranslation();
  return (
    <div className="container-xxl flex-grow-1 container-p-y"
        style={{justifyContent:"start", display:"flex", fontFamily:"Poppins"}}>
            <div className='container-xxl flex-grow-1 container-p-y' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center'}}>
                <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto', 
                fontWeight:"bold", color:'red', textAlign:'center'}}>
                  You have no rights to access this page.<br></br>
                </h1>
            </div>
    </div>
  )
}
