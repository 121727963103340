import axios from 'axios';
import flatpickr from 'react-flatpickr';
import React, { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select'
import MoonLoader from 'react-spinners/MoonLoader';
import { useTranslation } from 'react-i18next';
require("flatpickr/dist/themes/material_green.css");
export default function Analytics() {
    const [daterange,SetDateRange] = useState({
        from:"",
        to:""
    });
    const { t, i18n} = useTranslation();
    const [companies,setCompanies] = useState([]);
    const [groups,setGroups] = useState([]);
    const [agents,setAgents] = useState([]);
    const [group,setGroup] = useState("");
    const [groupid,setGroupId] = useState(0);
    const [companyActive,setCompanyActive] = useState(false);
    const [groupActive,setGroupActive] = useState(false);
    const [agentActive,setAgentActive] = useState(false);
    const [agent,setAgent] = useState(null);
    const [loading,setLoading] = useState(false);
    const [tdata,setTData] = useState({
        sip_user:"",
        pending:0,
        conversation:0,
        count:0,
        answer:0,
        noanswer:0
    });
    const [tGroup, setTgroup] = useState([]);
    function addOneDay(date = new Date()) {
        date.setDate(date.getDate() + 1);
      
        return date;
    }
    function subOneDay(date = new Date()) {
        date.setDate(date.getDate() - 1);
      
        return date;
    }
    useEffect(()=>{
        var flatpickrRange = document.querySelector("#flatpickr-range");
            flatpickrRange.flatpickr({
                mode: "range",
                onChange: function (selectedDates, dateStr, instance){
                    console.log(selectedDates,dateStr);
                    if(dateStr.includes("to")){
                        SetDateRange({
                            from:dateStr.split(' ')[0],
                            to:dateStr.split(' ')[2]
                        })
                        setCompanyActive(true);
                        axios.get("https://www.clearcall.tel:8078/companies")
                        .then((res)=>{
                            var arr = [];
                            if(res.data.length>0){

                                if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                res.data.forEach((item) => {
                                    arr.push({
                                        value:item.Id,
                                        label:item.Title
                                    });
                                })
                                setCompanies(arr);
                                }
                                else{
                                    res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                        ?console.log(item)
                                        :arr.push({
                                            value:item.Id,
                                            label:item.Title
                                        })
                                    })
                                    setCompanies(arr);
                                }
                            }
                        })
                        .catch((err)=>console.log(err))
                    }
                    else if(!dateStr.includes("to")){
                        console.log(selectedDates,dateStr);
                            SetDateRange({
                                from:selectedDates[0],
                                to:addOneDay(selectedDates[1])
                            })
                            setCompanyActive(true);
                            axios.get("https://www.clearcall.tel:8078/companies")
                            .then((res)=>{
                                var arr = [];
                                if(res.data.length>0){
    
                                    if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                    res.data.forEach((item) => {
                                        arr.push({
                                            value:item.Id,
                                            label:item.Title
                                        });
                                    })
                                    setCompanies(arr);
                                    }
                                    else{
                                        res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                            ?console.log(item)
                                            :arr.push({
                                                value:item.Id,
                                                label:item.Title
                                            })
                                        })
                                        setCompanies(arr);
                                    }
                                }
                            })
                            .catch((err)=>console.log(err))
                        }
                    }
            });
        
            SetDateRange({
                from:"",
                to:""
            })
    },[])
    const handleCompanyChoose=(e)=>{
        console.log(daterange)
        setGroupActive(true);
        setAgentActive(false);
        setAgent(null);
        if(JSON.parse(secureLocalStorage.getItem('user')).role_id===1){
            axios.post(`https://www.clearcall.tel:8078/teams/company/`,{company_id:e.value})
            .then((res)=>{
                var arr = [];
                console.log(res);
                if(res.data.length>0){
                    res.data.forEach((item) => {
                        arr.push({
                            value:item.id,
                            label:item.title
                        });
                    })
                    setGroups(arr);
                }
            })
            .catch((err)=>console.log(err))
             }
            else{
            axios.post('https://www.clearcall.tel:8078/agents/access/', {
                user_info_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
                company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
           })
           .then((res)=>{
            var arr = [];
            res.data.forEach((el)=>{
                console.log(el);
                if(el.has_access===1){
                arr.push({
                            value:el.id,
                            label:el.title
                        })
                }
                
            })
            setGroups(arr);
            
            console.log(res.data);
           })
           .catch((err)=>console.log(err))
        }
    }
    const handleGroupChoose=(e)=>{
        setAgentActive(true);
        setAgent(null);
        setGroup(e.label);
        setGroupId(e.value);
        axios.post("https://www.clearcall.tel:8078/agents/getFromTeam", {team_id:e.value})
        .then((res)=>{
            var arr = [];
            console.log(res);
            if(res.data.length>0){
                res.data.forEach((item) => {
                    arr.push({
                        value:item.Id,
                        label:item.Login
                    });
                })
                setAgents(arr);
            }
        })
        .catch((err)=>console.log(err))
    }
    const handleAgentChoose=(e)=>{
        setAgent(e.label);
    }
    const handleSubmit= async ()=>{
        setLoading(true);
        console.log(daterange)
        await axios.post("https://www.clearcall.tel:8078/agent/analyze",{
            from:daterange.from,
            to:daterange.to,
            sip:agent
        })
        .then((res)=>{
            setTData(res.data[0]);    
            console.log(res);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }
    const handleTeamSubmit= async ()=>{
        
        setLoading(true);
        console.log(daterange)
        await axios.post("https://www.clearcall.tel:8078/team/analyze",{
            from:daterange.from,
            to:daterange.to,
            team_id:groupid
        })
        .then((res)=>{
            setTgroup(res.data);    
            console.log(res);
            setLoading(false);
        })
        .catch((err)=>console.log(err));
    }

return (
    <div className="container-xxl flex-grow-1 container-p-y" style={{width:'80vw',backgroundColor:"#f5f5f9", boxShadow:'none',  paddingBottom:'0.5vh', marginTop:"5vh", marginBottom:"2vh",marginInline:0,fontSize:"1.5vh"}}>
    <div className="card" style={{height:"90.5%", borderRadius:"1.5vh", fontFamily:"Poppins",backgroundColor:"#f5f5f9", boxShadow:'none'}}>
        <div style={{display:'flex', flexDirection:"column"}}>
            <div style={{display:'flex',flexDirection:'column'}}>
            <div className="mb-3" style={{width:'62vw', margin:'0.5vw'}}>
            <label htmlFor="flatpickr-range" className="form-label">Select date</label>
            <input type="text" className="form-control" placeholder="YYYY-MM-DD to YYYY-MM-DD" id="flatpickr-range" style={{
                background:"#f5f5f9",
                color:"#9d9fff",
                borderColor:"#9d9fff",
                fontSize:'1.5vh',
                borderRadius:"20px",
            }}/>
        </div>
        <div className="mb-3" style={{width:'62vw', margin:'0.5vw',backgroundColor:"#f5f5f9", boxShadow:'none',zIndex:3}}>
            <label htmlFor="flatpickr-range" className="form-label">Select brand</label>
            <Select placeholder='Select brand'
                            onChange={(e)=>handleCompanyChoose(e)}
                            id='operatorLb' 
                            isDisabled={!companyActive}
                            options={companies}
                            styles={{
                                control:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#f5f5f9",
                                    color:"#9d9fff",
                                    borderColor:"#9d9fff",
                                    borderRadius:"20px"
                                }),
                                option: (styles, { isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        backgroundColor: isSelected ? "#949fae" : isFocused ? "#a9b2be" : undefined
                                    };
                                },
                                singleValue:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#f5f5f9",
                                    color:"#9d9fff"
                                }),
                                placeholder:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#f5f5f9",
                                    color:"#9d9fff"
                                }),
                                menuList:(baseStyles,state)=>({
                                    ...baseStyles,
                                    background:"#f5f5f9",
                                    color:"#949fae"
                                }),
                            }}/>
        </div>
            </div>
            
        
        <div style={{display:'flex',flexDirection:'column',zIndex:2,backgroundColor:"#f5f5f9", boxShadow:'none'}}>
            <div className="mb-3" style={{width:'62vw', margin:'0.5vw'}}>
                <label htmlFor="flatpickr-range" className="form-label">Select team</label>
                <Select placeholder='Select team'
                                isDisabled={!groupActive}      
                                onChange={(e)=>handleGroupChoose(e)}
                                id='operatorLb' 
                                options={groups}
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#9d9fff",
                                        borderColor:"#9d9fff",
                                        borderRadius:"20px"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#949fae" : isFocused ? "#a9b2be" : undefined
                                        };
                                    },
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#9d9fff"
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#9d9fff"
                                    }),
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#949fae"
                                    }),
                                }}/>
            </div>
            <div className="mb-3" style={{width:'62vw', margin:'0.5vw'}}>
                <label htmlFor="flatpickr-range" className="form-label">Select user</label>
                <Select placeholder='Select user'
                                onChange={(e)=>handleAgentChoose(e)}
                                id='operatorLb' 
                                isDisabled={!agentActive} 
                                options={agents} 
                                styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#9d9fff",
                                        borderColor:"#9d9fff",
                                        borderRadius:"20px"
                                    }),
                                    option: (styles, { isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected ? "#949fae" : isFocused ? "#a9b2be" : undefined
                                        };
                                    },
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#9d9fff"
                                    }),
                                    placeholder:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#9d9fff"
                                    }),
                                    menuList:(baseStyles,state)=>({
                                        ...baseStyles,
                                        background:"#f5f5f9",
                                        color:"#949fae"
                                    }),
                                }}/>
            </div> 
        </div>
        <div style={{display:'flex',flexDirection:'row', marginTop:"1vh", width:"70vw", justifyContent:"end",backgroundColor:"#f5f5f9", boxShadow:'none'}}>
                <button className='btn' 
                style={{width:'15vw', height:'5vh', margin:'0.5vw',background:"rgba(34, 197, 94, 0.16)",color:"rgb(17, 141, 87)", alignSelf:"center", marginTop:'0.5vh', fontWeight:"bold",fontSize:"1.5vh",
                borderRadius:"3vh"}}
                disabled={!(companyActive && groupActive && agentActive)} onClick={()=>handleTeamSubmit()}>Team</button>
                                        
                <button className='btn' 
                style={{width:'15vw', height:'5vh', margin:'0.5vw',background:"rgba(34, 197, 94, 0.16)",color:"rgb(17, 141, 87)",  alignSelf:"center", marginTop:'0.5vh', fontWeight:"bold",fontSize:"1.5vh",
                borderRadius:"3vh"}}
                disabled={!(companyActive && groupActive && agentActive && agent!==null)} onClick={()=>handleSubmit()}>User</button>
            </div>
        </div>
        <div className="card-body" style={{width:"70vw",height:"100%",backgroundColor:"#f5f5f9", boxShadow:'none',border:"3px solid lightgray", 
        borderRadius:17,paddingInline:0,paddingTop:0}}>
            {
                loading===true
                ?
                <div className="card" style={{width:'70vw', height:'50vh', justifyContent:'center',alignContent:'center', backgroundColor:"#f5f5f9", boxShadow:'none'}}>
                    {/* <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto',}}>Loading...</h1> */}
                    <MoonLoader
                        color="#33cc66"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
                :
                <table className="datatables-users table" style={{width:"69.5vw",maxHeight:'43vh',overflowY:'auto',borderTopLeftRadius:"17px",}}> 
                    <thead>
                    <tr style={{zIndex:1,  width:'68vw',borderTopLeftRadius:"17px"}}>
                        <th style={{width:'15vw',fontSize:"1.5vh"}}>User</th>
                        <th style={{width:'12.3vw',fontSize:"1.5vh"}}>Team</th>
                        <th style={{width:'9.6vw',fontSize:"1.5vh"}}>Dialing time</th>
                        <th style={{width:'9vw',fontSize:"1.5vh"}}>On line time</th>
                        <th style={{width:'10vw',fontSize:"1.5vh"}}>Calls</th>
                        <th style={{width:'6.7vw',fontSize:"1.5vh"}}>Success</th>
                        <th style={{width:'6.5vw',fontSize:"1.5vh"}}>Fail</th>
                    </tr>
                    </thead>
                    <tbody>
                    {agent!==null?tdata.sip_user !== ""?
                        <tr style={{height:'6vh'}}>
                            <td >{tdata.sip_user}</td>
                            <td >{group}</td>
                            <td >{Number(tdata.pending/60).toFixed()} min.</td>
                            <td >{Number(tdata.conversation/60).toFixed()} min.</td>
                            <td >{tdata.count} calls</td>
                            <td >{tdata.answer}</td>
                            <td >{tdata.noanswer}</td>
                        </tr>
                        :
                        <tr style={{height:'6vh'}}>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td >Information not found.</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                    :tGroup.length>0?tGroup.map((val,ind)=>(
                        <tr key={ind} style={{height:'10vh'}}>
                        <td >{val.sip_user}</td>
                        <td >{group}</td>
                        <td >{Number(val.pending/60).toFixed()} min.</td>
                        <td >{Number(val.conversation/60).toFixed()} min.</td>
                        <td >{val.count} calls</td>
                        <td >{val.answer}</td>
                        <td >{val.noanswer}</td>
                    </tr>
                    ))
                    :null
                    }
                    </tbody>
                </table>
            }
        </div>
    </div>
    </div>
    )
}
