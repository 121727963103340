import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useScript from '../Hooks/UseScript';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
const SideMenu = () => {

  const { t } = useTranslation();
  const {pathname} = useLocation()
const [brands, setBrands] = useState([]);
const [items, setItems] = useState({
  companies: [],
  teams: [],
  departments: [],
  offices: []
});
// const active = useRef(document.getElementById('dashboard'));
// const activeBrand = useRef(document.getElementById('dashboard'));
const active = useRef(document.getElementById('dashboard'));
const active1 = useRef(document.getElementById('dashboard'));
const activeBrand = useRef(document.getElementById('dashboard'));
const companies = useRef('menu-item');
const offices = useRef('menu-item');
const openCompanies =event=> {
  active.current = event.currentTarget;
  event.currentTarget.classList.toggle('open');
  // if(event.currentTarget.id === "companies"){
  //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
  //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
  //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
  //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
  //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
  //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
  //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
  // }
  // else if(event.currentTarget.id === "offices"){
  //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
  //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
  //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
  //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
  //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
  //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
  //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
  // }
  // else if(event.currentTarget.id === "departments"){
  //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
  //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
  //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
  //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
  //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
  //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
  //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
  // }
  // else if(event.currentTarget.id === "teams"){
  //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
  //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
  //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
  //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
  //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
  //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
  //     if(document.getElementById("brandmanage")!=null)document.getElementById("dashboard").classList.remove('open');
  // }
  // else 
  if(event.currentTarget.id === "journal"){
      if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
      if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
      if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
      if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
      if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
      if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
      if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
  }
  else if(event.currentTarget.id === "billing"){
      if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
      if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
      if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
      if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
      if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
      if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
      if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
  }
  else if(event.currentTarget.id === "dashboard"){
      if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
      if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
      if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
      if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
      if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
      if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
      if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
  }
  // else if(event.currentTarget.id === "brandmanage"){
  //     if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
  //     if(document.getElementById("teams")!=null)document.getElementById("teams").classList.remove('open');
  //     if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
  //     if(document.getElementById("companies")!=null)document.getElementById("companies").classList.remove('open');
  //     if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
  //     if(document.getElementById("departments")!=null)document.getElementById("departments").classList.remove('open');
  //     if(document.getElementById("dashboard")!=null)document.getElementById("dashboard").classList.remove('open');
  // }
  
}
const toggleActive =event=> {
  active.current = event.currentTarget;
  event.currentTarget.classList.toggle('active');
}
const toggleActive1 =event=> {
  active1.current = event.currentTarget;
  event.currentTarget.classList.toggle('active');
}
const toggleActiveBrand =event=> {
  activeBrand.current = event.currentTarget;
  event.currentTarget.classList.toggle('active');
}
useEffect(()=>{
  axios.get("https://www.clearcall.tel:8078/companies")
      .then((res)=>{
          //console.log(res);
          setBrands(res.data);
          secureLocalStorage.setItem('brands', res.data)
          res.data.forEach((val,ind)=>{
              if(JSON.parse(secureLocalStorage.getItem("user")!==null))
              if(val.Id == JSON.parse(secureLocalStorage.getItem("user")).company_id) secureLocalStorage.setItem("company_title",val.Title);
          });
      })
      .catch((err)=>{
          console.log(err);
      })
      axios.get("https://www.clearcall.tel:8081/companies")
      .then((res)=>{
          //console.log(res);
          secureLocalStorage.setItem('brands_old', res.data)
         
      })
      .catch((err)=>{
          console.log(err);
      })
  axios.get("https://www.clearcall.tel:8078/items")
  .then((res)=>{
      // console.log(res);
      setItems(res.data);
      //var options = [];
      // res.data.companies.forEach(element => {
      //     options.push({
      //         value:element.Id,
      //         label:element.Title
      //     })
      // });
      //secureLocalStorage.setItem("companiesarray",options);
  })
  .catch((err)=>console.log(err));
      //console.log();
  //console.log(JSON.parse(secureLocalStorage.getItem('user')).role_id);
  $(document.body).on('click', (e)=>{if(
      e.target.textContent !== 'History'
      && e.target.textContent !== t("billing")
      && e.target.textContent !== 'Home'
      && e.target.textContent !== t("brandmanage")
      ){
          if(document.getElementById("offices")!=null)document.getElementById("offices").classList.remove('open');
          if(document.getElementById("journal")!=null)document.getElementById("journal").classList.remove('open');
          if(document.getElementById("billing")!=null)document.getElementById("billing").classList.remove('open');
          if(document.getElementById("brandmanage")!=null)document.getElementById("brandmanage").classList.remove('open');
      }
      //console.log(e.target.textContent)
  });
  return()=>{
      $('body').off('click')
  }
  

}, [])

const nav = useNavigate();
function logoutHandle(){
  secureLocalStorage.setItem("token",null);
  secureLocalStorage.setItem("logged_user",false);
  //secureLocalStorage.setItem("user",null);
  nav("/login");
}

const renderMenuItems = () =>{
  let isrender = [
      false,
      false,
      false,
      false,
      false,
  ];
  let role = JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem('user')).role_id:6;
  if(role === 1) isrender = [true,true,true,true,true,true,true]
  else if(role===2) isrender = [true,true,false,false,true,true,true]
  else if(role===3) isrender = [true,true,false,false,true,false,true] 
  else if(role===4) isrender = [true,true,false,false,true,false,true] 
  else if(role===5) isrender = [false,true,false,false,true,false,true]
  else if(role===6) isrender = [false,false,false,false,false,false,false]
  else if(role===8) isrender = [false,true,false,false,true,false,false]
  else if(role===7) isrender = [true,true,false,true,true,false,true]
  //console.log(role+"render:"+isrender);
  return <ul className="menu-inner"style={{width:"14vw"}}>
  {
      
      isrender[0]?
      role === 1? 
      <li className={active.current!==null?active.current.id==='dashboard' && pathname === '/dashboard'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[0]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='dashboard' onClick={openCompanies}>
        <div className="menu-link menu-toggle"  style={{display:'flex',width:"12vw",gap:"5%"}}>
          <i className="menu-icon tf-icons bx bxs-dashboard" style={{fontSize:"2.5vh"}}></i>
      <div data-i18n="Dashboards"><b style={{fontSize:"1.5vh"}}>Home</b></div>
        </div>
        <ul className="menu-vertical menu-sub" style={{width:"14vw" , height:'40vh' , overflowY:'scroll'}} 
        >
            {/* {
            JSON.parse(secureLocalStorage.getItem("user"))!==null?
            JSON.parse(secureLocalStorage.getItem("user")).role_id===1
            ?  <li className={activeBrand.current!==null?activeBrand.current.id==='all'?'menu-item active':'menu-item':'menu-item'}
                id="all" onClick={toggleActiveBrand} style={{listStyleType:"none"}} >
                    <a href="/dashboard" className="menu-link">
                <i className="menu-icon tf-icons bx bx-spreadsheet" style={{listStyle:"none"}}></i>
                    <div data-i18n="All"><b style={{fontSize:"1.5vh"}}>Dashboard:{t("companiesAll")}</b></div>
                    </a>
                </li>
            :null
            :null
            } */}
            {
            brands.length > 0 
            ? 
            brands.map((val,ind)=>{
                if(val.Id == JSON.parse(secureLocalStorage.getItem("user")).company_id) secureLocalStorage.setItem("company_title",val.Title);
                if(JSON.parse(secureLocalStorage.getItem("user"))!==null?
                JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
                return <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.Title} onClick={toggleActiveBrand} >
                <a href={`/dashboard/${val.Id}`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
                    <i className="menu-icon tf-icons bx bx-spreadsheet"  style={{fontSize:"2.5vh"}}></i>
                    <div data-i18n={val.Title}><b style={{fontSize:"1.5vh"}}>{val.Title}</b></div>
                </a>
                </li>
                else 
                return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.Id
                ? null
                : <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.Title} onClick={toggleActiveBrand}>
                <a href={`/dashboard/${val.Id}`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
                <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
                <div data-i18n={val.Title}><b style={{fontSize:"1.5vh"}}>{val.Title}</b></div>
                </a>
            </li>
            })
            :
            null
            }
            
        </ul>
      </li>
      : 
      <li className={active.current!==null?active.current.id==='dashboard' && pathname === '/dashboard'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[0]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='dashboard' onClick={openCompanies}>
        <Link to="/dashboard" className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
            <i className="menu-icon tf-icons bx bxs-dashboard" style={{fontSize:"2.5vh"}}></i>
            <div data-i18n="Dashboards"><b style={{fontSize:"1.5vh"}}>Home</b></div>
        </Link> 
      </li>    
      :null
      // <li className={active.current!==null?active.current.id==='dashboard' || pathname === '/dashboard'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[0]?'visible':'hidden',width:"14vw",height:"fit-content"}} id='dashboard' onClick={toggleActive}>
      // <Link to='dashboard' className="menu-link"><i className="menu-icon tf-icons bx bx-pie-chart-alt-2"></i>
      // <div data-i18n="Dashboards"><b style={{fontSize:"1.5vh"}}>{t("dashboard")}</b></div></Link>
      // </li>
      // :null
  }
  
  {
    isrender[1]?
        <li className={active.current!==null?active.current.id==='journal' && pathname === '/journal'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[1]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='journal' onClick={openCompanies}>
            <Link to="/records/choose" className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
            <i className="menu-icon tf-icons bx bx-book-alt" style={{fontSize:"2.5vh"}}></i>
            <div data-i18n="Journal"><b style={{fontSize:"1.5vh"}}>History</b></div>
            </Link> 
        </li>
    :null
   

  }
  
  {
      isrender[2]?
      <li className={'menu-item'} 
      style={{visibility:isrender[2]?'visible':'hidden',width:"14vw",height:"fit-content"}}  
      id='brandmanage' ref={companies} >
          <Link to={"/routing"}>
          <div className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className="menu-icon tf-icons bx bx-buildings" style={{fontSize:"2.5vh"}}></i>
                  <div data-i18n="brandmanage"><b style={{fontSize:"1.5vh"}}>{t("brandmanage")}</b></div>
              </div>
          </Link>
      
      <ul className="dropdown-menu"  aria-labelledby="dropdownMenuClickableInside" >
      {
      isrender[2]?
      <li className={active.current!==null?active.current.id==='companies' && pathname.includes('/companies')?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[2]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='companies' ref={companies} onClick={openCompanies} >
      <div className="menu-link menu-toggle"  style={{display:'flex',width:"12vw",gap:"5%"}}>
      <i className="menu-icon tf-icons bx bx-buildings" style={{fontSize:"2.5vh"}}></i>
          <div data-i18n="Companies"><b style={{fontSize:"1.5vh"}}>{t("offices")}</b></div>
      </div>
      <ul className="menu-vertical menu-sub dropdown-menu-end"  style={{width:"15vw" , height:'40vh' , overflowY:'scroll'}}  >
          {
          JSON.parse(secureLocalStorage.getItem("user"))!==null?
          JSON.parse(secureLocalStorage.getItem("user")).role_id===1
          ?  <li className={activeBrand.current!==null?activeBrand.current.id==='all'?'menu-item active':'menu-item':'menu-item'}
              id="all" onClick={toggleActiveBrand} style={{listStyleType:"none"}} >
                  <Link to="/companies" className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className='bx bxs-briefcase menu-icon' style={{listStyle:"none",fontSize:"2.5vh"}}></i>
                  <div data-i18n="All"><b style={{fontSize:"1.5vh"}}>Companies:{t("companiesAll")}</b></div>
                  </Link>
              </li>
          :null
          :null
          }
          {
          brands.length > 0 
          ? 
          brands.map((val,ind)=>{
              if(val.Id == JSON.parse(secureLocalStorage.getItem("user")).company_id) secureLocalStorage.setItem("company_title",val.Title);
              if(JSON.parse(secureLocalStorage.getItem("user"))!==null?
              JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
              return <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.Title} onClick={toggleActiveBrand} >
              <Link to={`/companies/${val.Id}/offices/`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
                  <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
                  <div data-i18n={val.Title}><b style={{fontSize:"1.5vh"}}>{val.Title}</b></div>
              </Link>
              </li>
              else 
              return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.Id
              ? null
              : <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.Title} onClick={toggleActiveBrand}>
              <Link to={`/companies/${val.Id}/offices/`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
              <div data-i18n={val.Title}><b style={{fontSize:"1.5vh"}}>{val.Title}</b></div>
              </Link>
          </li>
          })
          :
          null
          }
          
      </ul>
      </li>
      :null
  }
  
  {
      isrender[2]?
      <li className={active.current!==null?active.current.id==='offices'  && pathname.includes('/companies')?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[2]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='offices' ref={offices} onClick={openCompanies} >
      <div className="menu-link menu-toggle"  style={{display:'flex',width:"12vw",gap:"5%"}}>
      <i className="menu-icon tf-icons bx bx-buildings" style={{fontSize:"2.5vh"}}></i>
          <div data-i18n="Offices"><b style={{fontSize:"1.5vh"}}>{t("departments")}</b></div>
      </div>
      <ul className="menu-vertical menu-sub" style={{width:"15vw" , height:'40vh' , overflowY:'scroll'}}  >
          {
          items.offices.length > 0 
          ? 
          items.offices.map((val,ind)=>{
              if(JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
              return <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand} >
              <Link to={`/companies/${val.company_id}/offices/${val.id}/departments`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
                  <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
                  <div data-i18n={val.title}><b style={{fontSize:"1.5vh"}}>[{val.Title}]{val.title}</b></div>
              </Link>
              </li>
              else 
              return JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.company_id
              ? null
              : <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand}>
              <Link to={`/companies/${val.company_id}/offices/${val.id}/departments`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
              <div data-i18n={val.title}><b style={{fontSize:"1.5vh"}}>[{val.Title}]{val.title}</b></div>
              </Link>
          </li>
          :null
          })
          :
          null
          }
          
      </ul>
      </li>
      :null
  }
  
  {
      isrender[2]?
      <li className={active.current!==null?active.current.id==='departments'  && pathname.includes('/companies')?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[2]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='departments' ref={offices} onClick={openCompanies} >
      <div className="menu-link menu-toggle"  style={{display:'flex',width:"12vw",gap:"5%"}}>
      <i className="menu-icon tf-icons bx bx-buildings" style={{fontSize:"2.5vh"}}></i>
          <div data-i18n="Departments"><b style={{fontSize:"1.5vh"}}>{t("teams")}</b></div>
      </div>
      <ul className="menu-vertical menu-sub" style={{width:"15vw" , height:'40vh' , overflowY:'scroll'}} >
          {
          items.departments.length > 0 
          ? 
          items.departments.map((val,ind)=>{
              if(JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
              return <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand} >
              <Link to={`/companies/${val.company_id}/offices/${val.office_id}/departments/${val.id}/teams`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
                  <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
                  <div data-i18n={val.title}><b style={{fontSize:"1.5vh"}}>[{val.Title}]{val.title}</b></div>
              </Link>
              </li>
              else 
              return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.company_id
              ? null
              : <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand}>
              <Link to={`/companies/${val.company_id}/offices/${val.office_id}/departments/${val.id}/teams`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
              <div data-i18n={val.title}><b style={{fontSize:"1.5vh"}}>[{val.Title}]{val.title}</b></div>
              </Link>
          </li>
          })
          :
          null
          }
          
      </ul>
      </li>
      :null
  }
  
  {
      isrender[2]?
      <li className={active.current!==null?active.current.id==='teams' && pathname.includes('/companies')?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[2]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='teams' ref={offices} onClick={openCompanies} >
      <div className="menu-link menu-toggle"  style={{display:'flex',width:"12vw",gap:"5%"}}>
      <i className="menu-icon tf-icons bx bx-buildings" style={{fontSize:"2.5vh"}}></i>
          <div data-i18n="Teams"><b style={{fontSize:"1.5vh"}}>{t("agents")}</b></div>
      </div>
      <ul className="menu-vertical menu-sub" style={{width:"15vw" , height:'40vh' , overflowY:'scroll'}} >
          {
          items.teams.length > 0 
          ? 
          items.teams.map((val,ind)=>{
              if(JSON.parse(secureLocalStorage.getItem("user"))!==null?JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
              return <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand} >
              <Link to={`/companies/${val.company_id}/offices/${val.office_id}/departments/${val.department_id}/teams/${val.id}/agents`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
                  <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
                  <div data-i18n={val.title}><b style={{fontSize:"1.5vh"}}>[{val.Title}]{val.title}</b></div>
              </Link>
              </li>
              else 
              return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.company_id
              ? null
              : <li className={activeBrand.current!==null?activeBrand.current.id===val.title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.title} onClick={toggleActiveBrand}>
              <Link to={`/companies/${val.company_id}/offices/${val.office_id}/departments/${val.department_id}/teams/${val.id}/agents`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
              <div data-i18n={val.title}><b style={{fontSize:"1.5vh"}}>[{val.Title}]{val.title}</b></div>
              </Link>
          </li>
          })
          :
          null
          }
          
      </ul>
      </li>
      :null
  }
      </ul>
      </li>
      :null
  }
  
  {
      isrender[3]?
      role === 1? 
      <li className={active.current!==null?active.current.id==='billing' && pathname === '/billing'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[3]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='billing' onClick={openCompanies}>

          <div className="menu-link menu-toggle"  style={{display:'flex',width:"12vw",gap:"5%"}}>
          <i className="menu-icon tf-icons bx bx-spreadsheet" style={{fontSize:"2.5vh"}}></i>
          <div data-i18n="Companies"><b style={{fontSize:"1.5vh"}}>{t("billing")}</b></div>
      </div>
      <ul className="menu-vertical menu-sub" >
          {
          brands.length > 0 
          ? 
          brands.map((val,ind)=>{
              if(val.Id == JSON.parse(secureLocalStorage.getItem("user")).company_id) secureLocalStorage.setItem("company_title",val.Title);
              if(JSON.parse(secureLocalStorage.getItem("user"))!==null?
              JSON.parse(secureLocalStorage.getItem("user")).role_id===1:false)
              return <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.Title} onClick={toggleActiveBrand} >
              <a href={`/billing/${val.Id}`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
                  <i className="menu-icon tf-icons bx bx-spreadsheet" style={{fontSize:"2.5vh"}}></i>
                  <div data-i18n={val.Title}><b style={{fontSize:"1.5vh"}}>{val.Title}</b></div>
              </a>
              </li>
              else 
              return JSON.parse(secureLocalStorage.getItem("user")).company_id !== val.Id
              ? null
              : <li className={activeBrand.current!==null?activeBrand.current.id===val.Title?'menu-item active':'menu-item':'menu-item'} key={ind} id={val.Title} onClick={toggleActiveBrand}>
              <a href={`/billing/${val.Id}`} className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className='bx bxs-briefcase menu-icon' style={{fontSize:"2.5vh"}}></i>
              <div data-i18n={val.Title}><b style={{fontSize:"1.5vh"}}>{val.Title}</b></div>
              </a>
          </li>
          })
          :
          null
          }
          
      </ul>
      </li>
      : 
      <li className={active.current!==null?active.current.id==='billing' && pathname === '/billing'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[3]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='billing' onClick={openCompanies}>
          <Link to="/billing" className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
              <i className="menu-icon tf-icons bx bx-credit-card" style={{fontSize:"2.5vh"}}></i>
              <div data-i18n="Billing"><b style={{fontSize:"1.5vh"}}>{t("billing")}</b></div>
          </Link> 
      </li> 
      :null
  }
  {
      isrender[4]?
      <li className={active.current!==null?active.current.id==='calls'  && pathname === '/onlinecalls'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[4]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='calls' onClick={toggleActive}>
  <Link to='/onlinecalls' className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
  <i className="menu-icon tf-icons bx bx-phone-call" style={{fontSize:"2.5vh"}}></i>
      <div data-i18n="OnlineCalls"><b style={{fontSize:"1.5vh"}}>On line</b></div>
  </Link>
  </li>
      :null
  }
  {
      isrender[5]?
      <li className={active.current!==null?active.current.id==='users' && pathname === '/users'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[5]?'visible':'hidden',width:"14vw",height:"fit-content"}}  id='users' onClick={toggleActive}>
  <Link to='/users' className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
  <i className='menu-icon tf-icons bx bxs-user-detail' style={{fontSize:"2.5vh"}}></i>
      <div data-i18n="Users"><b style={{fontSize:"1.5vh"}}>{t("access")}</b></div>
  </Link>
  </li>
      :null
  }
  {
      isrender[6]?
      <li className={active.current!==null?active.current.id==='analytics' && pathname === '/analytics'?'menu-item active':'menu-item':'menu-item'} style={{visibility:isrender[6]?'visible':'hidden',width:"14vw",height:"fit-content"}}  
  id='analytics' onClick={toggleActive}>
  <Link to='/analytics' className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
  <i className='menu-icon tf-icons bx bx-bar-chart-alt-2' style={{fontSize:"2.5vh"}}></i>
      <div data-i18n="Analytics"><b style={{fontSize:"1.5vh"}}>Analytics</b></div>
  </Link>
  </li>
      :null
  }
    <li className="menu-item" onClick={logoutHandle}  >
        <div className="menu-link"  style={{display:'flex',width:"12vw",gap:"5%"}}>
        <i className="menu-icon tf-icons bx bx-log-out"  style={{fontSize:"2.5vh"}}/>
        <div data-i18n="Logout"><b style={{fontSize:"1.5vh"}}>Logout</b></div>
        </div>
    </li>
</ul>
}
useScript('../../public/assets/js/menu.js');
return (
    <aside className="layout-menu-fixed menu-vertical menu bg-menu-theme" style={{position:"sticky", top:0,right:0,left:0, fontFamily:"Poppins",width:"13.5vw",height:"100vh"}}>
        <div style={{position:"sticky", top:0,right:0,bottom:0,left:0}}>
            
        <div className="app-brand demo" style={{marginLeft:-10,width:"14vw"}}>
            <Link to="dashboard" className="app-brand-link">
                <span className="app-brand-text demo menu-text fw-bolder ms-2" style={{textTransform:'uppercase', fontSize:"2.9vh", fontFamily:"Poppins"}}>Menu</span>
            </Link>
        </div>

            <div className="menu-inner-shadow"></div>
            {renderMenuItems()}
        </div>
            
    </aside>
);
};

export default SideMenu;
