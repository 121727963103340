import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
import ReactApexChart from 'react-apexcharts';
import ReactSpeedometer from "react-d3-speedometer"
import apexchart from 'apexcharts';
import MoonLoader from 'react-spinners/MoonLoader';
import moment from 'moment';

const YourComponent = () => {
    const { id } = useParams();
    const [prefixes, setPrefixes] = useState([]);
    const [company, setCompany] = useState();
    const { t, i18n } = useTranslation();
    const [number,setNumber]= useState("");
    const [incall, setInCall] = useState(0);
    const [callsState, setCallsState] = useState("Today");
    const [answer, SetAnswer] = useState(0);
    const [noanswer, SetNoAnswer] = useState(0);
    const [answerMonth, SetAnswerMonth] = useState(0);
    const [noanswerMonth, SetNoAnswerMonth] = useState(0);
    const [answerToday, SetAnswerToday] = useState(0);
    const [noanswerToday, SetNoAnswerToday] = useState(0);
    const [answerWeek, SetAnswerWeek] = useState(0);
    const [noanswerWeek, SetNoAnswerWeek] = useState(0);
    const [count, SetCount] = useState(0);
    const [activeOperator,setActiveOperator] = useState(null);
    const testweek = useRef(0);
    const testmonth = useRef(0);
    const testtoday = useRef(0);
    const testall = useRef(0);
    const [countWeek, SetCountWeek] = useState(0);
    const [countMonth, SetCountMonth] = useState(0);
    const [countToday, SetCountToday] = useState(0);
    const totalansw = useRef(0);
    const totalnoansw = useRef(0);
    const total = useRef(0);
    const [isLoading , setIsLoading] = useState(true)
    const [data, SetData] = useState({
        count: 0,
        answer: 0,
        noanswer: 0,
        avg: 0,
        incall: 0,
        activityarr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        noanswarr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        answarr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        aweek: 0,
        noaweek: 0,
        amonth: 0,
        noamonth: 0,
        countries: [],
        answerweek: [0,0,0,0,0,0,0],
        noanswerweek: [0,0,0,0,0,0,0],
        categories: [],
    })
    const [state, setState] = useState({
        seriesCalls: [answer === null ? 0 : answer,
        noanswer ? 0 : noanswer],
        optionsCalls: {
            chart: {
                foreColor: '#9b9dc1'
            },
            labels: ['Success', 'Failed'],
            dataLabels: {
                enabled: false,
                style: {
                    fontSize: '15px'
                },
                formatter: function (val) {
                    return Math.floor(val) + "%"
                }
            },
            fill: {
              type: 'gradient',
            },
            stroke: {
                curve: 'straight',
                lineCap: 'round',
                colors: '#fff',
                width: 15,
                dashArray: 0,
            },
            legend: {
                show: false,
                position: 'bottom'
            },
            colors: ['#9bf7aa', '#Fb4b4e'],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: false,
                            name: {},
                            value: {}
                        }
                    }
                }
            }
        },
        optionsSuccessCalls: {
            series: [count > 0 ? Math.floor((answer / count) * 100) : 0],
            chart: {
                height: "30vh",
                width:  "100%",
                type: 'radialBar',
                offsetY: -10
              },
              colors:['#7575ff'],
              plotOptions: {
                radialBar: {
                  startAngle: -135,
                  endAngle: 135,
                  dataLabels: {
                    name: {
                      fontSize: '1.6vh',
                      color: '#7575ff',
                      offsetY: 120
                    },
                    value: {
                      offsetY: 76,
                      fontSize: '2.2vh',
                      color: undefined,
                      formatter: function (val) {
                        return val + "%";
                      }
                    }
                  }
                }
              },
              fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91]
                },
              },
              stroke: {
                dashArray: 4
              },
              labels: ['Success percent'],
            },
        optionsSuccessCallsMin: {
            series: [data.avg],
            colors: ["#B1094f"],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                        margin: 0,
                        size: "70%",
                        background: "#2b2c3f"
                    },
                    track: {
                        startAngle: -90,
                        endAngle: 90,
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            blur: 4,
                            opacity: 0.15
                        }
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#fff",
                            fontSize: "13px"
                        },
                        value: {
                            color: "#fff",
                            fontSize: "30px",
                            fontWeight: "bold",
                            show: true
                        }
                    }
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "light",
                    type: "horizontal",
                    gradientToColors: ["#6D09B1"],
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: "round"
            },
            labels: [""]
        },
        optionsActivity: {
            chart: {
                foreColor: '#60628b',
                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 7,
                    left: 5,
                    blur: 2,
                    color: '#00e396',
                    opacity: 0.35
                },
                id: "activity"
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#00e396'],
            series: [{
                name: t("dashboardAgentsActivity"),
                data: data.activityarr !== null
                    ? data.activityarr.length === 0
                        ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        : data.activityarr
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            }
            ],
            stroke: {
                curve: 'smooth'
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    },
                    min: -100,
                    max: 100
                },
                padding: {
                    top: 0,
                    right: 10,
                    bottom: 0,
                    left: 25
                }
            },
            xaxis: {
                // categories: ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                categories: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true
                }
            },
            yaxis: {
                show: false
            },

        },
        optionsSuccess: {
            series: [
                {
                    name: t("dashboardSuccess"),
                    data: data.answarr !== null ?
                        data.answarr.length === 0
                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            : data.answarr
                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                },
                {
                    name: t("dashboardFail"),
                    data: data.noanswarr !== null ?
                        data.noanswarr.length === 0
                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            : data.noanswarr
                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                }
            ],
            plotOptions: {
                bar: {
                    barHeight: '100%',
                    borderRadius: 8,
                    borderRadiusWhenStacked: 'all',
                    columnWidth: 20,
                    horizontal: false
                }
            },
            colors: ['#00e396', '#C73327'],
            chart: {
                foreColor: '#9b9dc1',
                stacked: true,
                toolbar: {
                    show: false
                },
                stackType: 'normal',
                id: "success"
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                colors: ["#2b2c3f"]
            },
            grid: {
                borderColor: "#444662",
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            title: {
                text: t("dashboardSuccessTimeline"),
                style: {
                    fontSize: '25px',
                    fontWeight: 'bold',
                    color: "#a3a5ca"
                }
            },
            xaxis: {
                categories: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true,

                }
            },
        }
    })
    useEffect(() => {
        if (callsState === "All") {
            SetAnswer(
                data.answer === null
                    ? 1
                    : data.answer
            );
            SetNoAnswer(
                data.noanswer === null
                    ? 1
                    : data.noanswer
            );
            SetCount(
                data.count !== null
                    ? data.count
                    : 1
            );
            setState(prevState => {
                return {
                    ...prevState, seriesCalls:
                        [
                            data.answer == null
                                ? 0
                                : data.answer,
                            data.noanswer == null
                                ? 0
                                : data.noanswer
                        ]
                }
            });
            const successcalls = {...state.optionsSuccessCalls, series:[
                count > 0 ? Math.floor((data.answer / (data.answer+data.noanswer)) * 100) : 0
            ]}
            setState(prevState => {
                return {
                    ...prevState, optionsSuccessCalls:successcalls
                    }  
                }
            );
        }
        if (callsState === "Week") {
            SetAnswer(
                data.aweek == null
                    ? 0
                    : data.aweek
            );
            SetNoAnswer(
                data.noaweek == null
                    ? 0
                    : data.noaweek
            );
            SetCount(
                (data.aweek !== null && data.noaweek !== null)
                    ? data.aweek + data.noaweek
                    : 0
            );
            setState(prevState => {
                return {
                    ...prevState, seriesCalls:
                        [
                            data.aweek == null
                                ? 0
                                : data.aweek,
                            data.noaweek == null
                                ? 0
                                : data.noaweek
                        ]
                }
            });
            const successcalls = {...state.optionsSuccessCalls, series:[
                count > 0 ? Math.floor((data.aweek / (data.aweek+data.noaweek)) * 100) : 0
            ]}
            setState(prevState => {
                return {
                    ...prevState, optionsSuccessCalls:successcalls
                    }  
                }
            );
        }
        if (callsState === "Month") {
            SetAnswer(
                data.amonth == null
                    ? 0
                    : data.amonth
            );
            SetNoAnswer(
                data.noamonth == null
                    ? 0
                    : data.noamonth
            );
            SetCount(
                (data.amonth !== null && data.noamonth !== null)
                    ? data.amonth + data.noamonth
                    : 0
            );
            setState(prevState => {
                return {
                    ...prevState, seriesCalls:
                        [
                            data.amonth == null
                                ? 0
                                : data.amonth,
                            data.noamonth == null
                                ? 0
                                : data.noamonth
                        ]
                }
            });
            const successcalls = {...state.optionsSuccessCalls, series:[
                count > 0 ? Math.floor((data.amonth / (data.amonth+data.noamonth)) * 100) : 0
            ]}
            setState(prevState => {
                return {
                    ...prevState, optionsSuccessCalls:successcalls
                    }  
                }
            );
        }
        if (callsState === 'Today') {
            if (data.answarr.length !== 0 && data.noanswarr.length !== 0) {
                totalansw.current = 0;
                totalnoansw.current = 0;
                total.current = 0;
                for (let i = 0; i < data.answarr.length; i++) {
                    totalansw.current += data.answarr[i];
                    total.current += data.answarr[i];
                }
                for (let i = 0; i < data.noanswarr.length; i++) {
                    total.current -= data.noanswarr[i];
                    totalnoansw.current -= data.noanswarr[i];
                }
                SetAnswer(totalansw.current)
                SetNoAnswer(totalnoansw.current)
                SetCount(total.current);
                setState(prevState => {
                    return {
                        ...prevState, seriesCalls:
                            [
                                totalansw.current == null
                                    ? 0
                                    : totalansw.current,
                                totalnoansw.current == null
                                    ? 0
                                    : totalnoansw.current
                            ]
                    }
                });
                const successcalls = {...state.optionsSuccessCalls, series:[
                    count > 0 ? Math.floor((totalansw.current / (totalansw.current+totalnoansw.current)) * 100) : 0
                ]}
                setState(prevState => {
                    return {
                        ...prevState, optionsSuccessCalls:successcalls
                        }  
                    }
                );
            }
            setCallsToday();
        }
    }, [callsState, data])
    useEffect(() => {
        setInterval(() => {
            axios.post('https://www.clearcall.tel:8078/agents/onlinecounter', {
                ui_id: JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
                company_id: id==undefined?JSON.parse(secureLocalStorage.getItem('user')).company_id:id
            })
                .then((res) => {
                    setInCall(res.data.incall);
                })
                .catch((err) => console.log(err))
        }, 5000)
    }, [])
    useEffect(() => {
        console.log(id);
        const fetchInitialData = async () => {
            try {
                await axios.get(`https://www.clearcall.tel:8078/companies/${id==undefined?JSON.parse(secureLocalStorage.getItem('user')).company_id:id}` ).then(async(res) => {
                    if (res) {
                        axios.post("https://www.clearcall.tel:8078/dashboard/prefix", {
                            company: res.data[0].Title
                        }).then((response) => setPrefixes(response.data))
                        setCompany(res.data[0].Title)
                        const response = await axios.post('https://www.clearcall.tel:8078/dashboardV2', {
                            company: res.data[0].Title
                        });
                        if (response) {
                            SetData({
                                count: response.data.count === null ? 0 : response.data.count,
                                answer: response.data.answer === null ? 0 : response.data.answer,
                                noanswer: response.data.noanswer === null ? 0 : response.data.noanswer,
                                avg: response.data.avg === null ? 0 : response.data.avg,
                                activityarr: response.data.activityarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.activityarr,
                                noanswarr: response.data.noanswarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.noanswarr,
                                answarr: response.data.answarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.answarr,
                                aweek: response.data.lastweekansw === null ? 0 : response.data.lastweekansw,
                                noaweek: response.data.lastweeknoansw === null ? 0 : response.data.lastweeknoansw,
                                amonth: response.data.lastmonthansw === null ? 0 : response.data.lastmonthansw,
                                noamonth: response.data.lastmonthnoansw === null ? 0 : response.data.lastmonthnoansw,
                                countries: response.data.countries === null ? [] : response.data.countries,
                                answerweek:response.data.dashbweek.map((item)=>item.ANSWER),
                                noanswerweek:response.data.dashbweek.map((item)=>item.other),
                                categories:response.data.dashbweek.map((item)=>item.day),
                            })
                            console.log(response.data);
                            
                            setIsLoading(false)
                            apexchart.exec("activity", 'updateSeries', [{
                                name: t("dashboardAgentsActivity"),
                                data: response.data.activityarr !== null
                                    ? response.data.activityarr.length === 0
                                        ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                        : response.data.activityarr
                                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                            }], true)
                            apexchart.exec("activity", "render");

                            apexchart.exec("weekarea", 'updateSeries', [
                                {
                                    name: "Success",
                                    data: response.data.dashbweek.map((item)=>item.ANSWER)
                                },
                                {
                                    name: "Fail",
                                    data: response.data.dashbweek.map((item)=>item.other)
                                }
                            ], true)
                            
                            apexchart.exec("weekarea", "updateOptions",{
                                xaxis: {
                                    type: 'datetime',
                                    categories: response.data.dashbweek.map((item)=>moment(item.day).format("MMM Do")),
                                    axisBorder: {
                                        show: true
                                    },
                                    axisTicks: {
                                        show: false
                                    },
                                    labels: {
                                        show: true
                                    }
                                },
                            });
                            apexchart.exec("weekarea", "render");
        
                            apexchart.exec("success", 'updateSeries', [
                                {
                                    name: t("dashboardSuccess"),
                                    data: response.data.answarr !== null ?
                                        response.data.answarr.length === 0
                                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                            : response.data.answarr
                                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                                },
                                {
                                    name: t("dashboardFail"),
                                    data: response.data.noanswarr !== null ?
                                        response.data.noanswarr.length === 0
                                            ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                            : response.data.noanswarr
                                        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                    //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                                }
                            ], true)
                            apexchart.exec("success", "render");
                            
                    apexchart.exec('asr','updateSeries',
                    [
                        count > 0 ? Math.floor((answer / count) * 100) : 0,
                        countMonth > 0 ? Math.floor((answerMonth / countMonth) * 100) : 0,
                        countWeek > 0 ? Math.floor((answerWeek / countWeek) * 100) : 0,
                        countToday > 0 ? Math.floor((answerToday / countToday) * 100) : 0
                    ]
                    )
                    
                    apexchart.exec("asr", "updateOptions",{
                        plotOptions: {
                            radialBar: {
                              dataLabels: {
                                name: {
                                  fontSize: '22px',
                                },
                                value: {
                                  color:'rgb(163, 165, 202)',
                                  fontSize: '16px',
                                },
                                total: {
                                  show: true,
                                  label: 'Total',
                                  formatter: function (w) {
                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                    return `${count > 0 ? Math.floor((answer / count) * 100) : 0}%`
                                  }
                                }
                              }
                            }
                          }
                    });
                    apexchart.exec("asr", "render");
                        }


                    }
                });
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        };

        setTimeout(async ()=>{
            setIsLoading(true)
        fetchInitialData();
        },100)
        
        setTimeout(async ()=>{
            fetchInitialData();
        },1100)
        const intervalId = setInterval(async () => {
            fetchInitialData();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            try {
                const response = await axios.post('https://www.clearcall.tel:8078/dashboardV2', {
                    company: company
                });
                if (response) {
                    SetData({
                        count: response.data.count === null ? 0 : response.data.count,
                        answer: response.data.answer === null ? 0 : response.data.answer,
                        noanswer: response.data.noanswer === null ? 0 : response.data.noanswer,
                        avg: response.data.avg === null ? 0 : response.data.avg,
                        activityarr: response.data.activityarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.activityarr,
                        noanswarr: response.data.noanswarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.noanswarr,
                        answarr: response.data.answarr === null ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : response.data.answarr,
                        aweek: response.data.lastweekansw === null ? 0 : response.data.lastweekansw,
                        noaweek: response.data.lastweeknoansw === null ? 0 : response.data.lastweeknoansw,
                        amonth: response.data.lastmonthansw === null ? 0 : response.data.lastmonthansw,
                        noamonth: response.data.lastmonthnoansw === null ? 0 : response.data.lastmonthnoansw
                    })
                    apexchart.exec("activity", 'updateSeries', [{
                        name: t("dashboardAgentsActivity"),
                        data: response.data.activityarr !== null
                            ? response.data.activityarr.length === 0
                                ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                : response.data.activityarr
                            : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                    }], true)
                    apexchart.exec("activity", "render");

                    apexchart.exec("success", 'updateSeries', [
                        {
                            name: t("dashboardSuccess"),
                            data: response.data.answarr !== null ?
                                response.data.answarr.length === 0
                                    ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                    : response.data.answarr
                                : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                        },
                        {
                            name: t("dashboardFail"),
                            data: response.data.noanswarr !== null ?
                                response.data.noanswarr.length === 0
                                    ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                    : response.data.noanswarr
                                : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            //data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                        }
                    ], true)
                    apexchart.exec("success", "render");
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        }, 15000);

        return () => clearInterval(intervalId);
    }, [company]);
    const setCallsToday = () => {
        setCallsState('Today')
    }
    const setCallsWeek = () => {
        setCallsState('Week')
    }
    const setCallsMonth = () => {
        setCallsState('Month')
    }
    const setCallsAll = () => {
        setCallsState('All')
    }
    if(isLoading){
        return <div className='container-xxl flex-grow-1 container-p-y'>
                <div className="card" style={{width:'70vw', height:'80vh', justifyContent:'center',alignContent:'center', backgroundColor:"#f5f5f9", boxShadow:'none'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto',}}>Loading...</h1>
                    <MoonLoader
                        color="#33cc66"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
    }
    else{
        return (
            <div className="container-xxl flex-grow-1 container-p-y"
                style={{ justifyContent: "start", display: "flex", flexDirection: "column", fontFamily:"Poppins",marginLeft:0,marginRight:0 }}>
                <div className="row" style={{ height: '55vh', width: '72vw' }}>
                    <div className="col-sm" style={{ width: '25vw', height:'30vh' }}>
                        <div className="card h-100" >
                            <div className="card-header d-flex align-items-center justify-content-between pb-0">
                                <div className="card-title mb-0">
                                        
                                </div>
                                <div className="dropdown">
                                    <button className="btn p-0" type="button" id="orederStatistics" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="bx bx-dots-vertical-rounded" style={{fontSize:"2vh"}}/>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="orederStatistics">
                                        <button className="dropdown-item" onClick={() => setCallsMonth()} style={{fontSize:"2vh"}}>{t("dashboardLastMonth")}</button>
                                        <button className="dropdown-item" onClick={() => setCallsWeek()} style={{fontSize:"2vh"}}>{t("dashboardLastWeek")}</button>
                                        <button className="dropdown-item" onClick={() => setCallsToday()} style={{fontSize:"2vh"}}>{t("dashboardToday")}</button>
                                        <button className="dropdown-item" onClick={() => setCallsAll()} style={{fontSize:"2vh"}}>All</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    
                                    <div id="orderStatisticsChart" style={{width:'25vw',height:'25vh', 
                                        display:'flex', flexDirection:"column", fontSize:"5vh"}}>
                                        {/* <ReactApexChart
                                            options={state.optionsCalls}
                                            series={state.seriesCalls}
                                            labels={state.optionsCalls.labels}
                                            type='pie'
                                            width={600}
                                            height={500} /> */}
                                            
                                            <span className="m-0 me-2" >
                                                <span>Total</span>:  <span style={{ fontWeight: "bold" }}>{count}</span>
                                            </span>
                                            <span>
                                                <span style={{color:"#7cff7c"}}>
                                                Success  
                                                </span>
                                                :  <span style={{ fontWeight: "bold" }}>{state.seriesCalls[0]}</span>
                                                  
                                            </span>
                                            <span >
                                                <span style={{color:"#F75852"}}>
                                                Fail
                                                </span>
                                                :  <span style={{ fontWeight: "bold" }}>{state.seriesCalls[1]}</span>
                                                  
                                            </span>
                                    </div>
                                    {/* <div className="d-flex flex-column align-items-center gap-1" style={{marginLeft:"-200px"}}>
                                        <h2 className="mb-2">{count}</h2>
                                        <span>Total</span>
                                    </div> */}
                                </div>
                                <div style={{
                                    display: "none",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    //marginTop: "15%"
                                }}>
                                    <ul className="p-0 m-0" style={{ display: "flex", flexDirection: "column" }}>
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <span className="avatar-initial rounded " style={{ color: "#00e396", backgroundColor: "#36483f" }}><i style={{ marginLeft: 4 }} className="bx bx-user-check" /></span>
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">{t("dashboardAnswer")}</h6>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-semibold">{answer}</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <span className="avatar-initial rounded bg-label-warning"><i className="bx bx-no-entry" /></span>
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <h6 className="mb-0">{t("dashboardNoAnswer")}</h6>
                                                </div>
                                                <div className="user-progress">
                                                    <small className="fw-semibold">{noanswer}</small>
                                                </div>
                                            </div>
                                        </li>
                                        { /* 
                                            <li className="d-flex mb-4 pb-1">
                                            <div className="avatar flex-shrink-0 me-3">
                                                <span className="avatar-initial rounded bg-label-danger"><i className="bx bx-user-x" /></span>
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                <h6 className="mb-0">Busy</h6>
                                                </div>
                                                <div className="user-progress">
                                                <small className="fw-semibold">{JSON.parse(secureLocalStorage.getItem('busy'))}</small>
                                                </div>
                                            </div>
                                            </li> 
                                            */ }
                                    </ul>
                                    {/* {role===1?<Select options={secureLocalStorage.getItem("companiesarray")} 
                                        onChange={(e)=>setDashboardData("Give me dashboard,"+e.label)}
                                        placeholder="Select company for dashboard data"
                                        />:null} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"
                        style={{
                            display: "flex",
                            marginLeft: 15
                        }}>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                            <div className="card" style={{ maxWidth:"20vw",height:"30vh",fontSize:"1.4vh",display:"flex",alignItems:'start',justifyContent:"center" }}>
                                    <ReactApexChart
                                                    options={state.optionsSuccessCalls}
                                                    series={state.optionsSuccessCalls.series}
                                                    labels={state.optionsSuccessCalls.labels}

                                                    type='radialBar'
                                                />
                            </div>
                            <div className="card" style={{ width: '20vw', height: '30vh', padding: 5, marginLeft:40 }}>
                                <div className="card-header" style={{ fontSize: '2.5vh', fontWeight: "bold", alignSelf: "center", height: 10 }}>
                                    Average Call Duration
                                </div>
                                <div style={{ display: "flex", alignContent: 'center', marginTop: "15%", justifyContent: 'center' }}>
                                    <h1 style={{ fontWeight: "bold", alignSelf: "center", justifySelf: 'center', color: '#7575ff', fontSize: "6.5vh" }}>
                                        {Math.floor(data.avg)} {t("onlineSeconds")}
                                    </h1>
                                </div>
                            </div>
                            <div className="card" style={{ width: 300, height: 280, padding: 5, display:"none" }}>
                        <div className="card-header" style={{ fontSize: '25px', fontWeight: "bold", alignSelf: "center", height: 10 }}>
                            <h2 className="mb-1" style={{ color: "#a3a5ca", fontSize: '25px', fontWeight: "bold", marginLeft: "10px" }}>Agents on line</h2>
                        </div>
                        <div className="col-md-6 pe-md-4 card-separator" style={{ position: 'relative', justifyContent: "center", alignContent: "center", width: 150 }}>
                            <div className="d-flex" style={{ position: "absolute", marginTop: "55%", marginLeft: "60%" }}>
                                <div style={{ textAlign: "center" }}>
                                <h1 style={{ fontWeight: "bold", alignSelf: "center", justifySelf: 'center', color: '#7575ff' }}>
                                        <b style={{ marginLeft: "30px", top: "-35px", position: "absolute", fontSize: "80px" }}>
                                            {incall}
                                        </b>
                                        {/* <i class="fa-solid fa-phone fa-2xl" style={{color:"#a3a5ca",top:"100%", position:"absolute",}}></i> */}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default YourComponent;