import './App.css';
import Dashboard from './Components/Dashboard';
import Menu from './layout/SideMenu';
import { Routes, Route, Router, Navigate, BrowserRouter, useLocation } from "react-router-dom";
import OnlineCalls from './Components/OnlineCalls';
import Login from './Components/Login';
import Layout from './layout/Layout';
import Error from './Components/Error';
import Journal from './Components/Journal';
import { useEffect, useState } from 'react';
import PrivateRoute from './Components/PrivateRoute';
import Companies from './Components/Companies';
import Offices from './Components/Offices';
import Departments from './Components/Departments';
import Teams from './Components/Teams';
import Agents from './Components/Agents';
import Billing from './Components/Billing';
import NoAccess from './Components/NoAccess';
import Users from './Components/Users';
import BillingManagement from './Components/BillingManagement';
import FreeswitchPanel from './Components/FreeswitchPanel';
import Administration from './Components/Administration';
import Analytics from './Components/Analytics';
import Individual from './Components/Individual';
import GatewaysManagement from './Components/GatewaysManagement';
import BrandRouting from './Components/BrandRouting';
import NumberVerification from './Components/NumberVerification';
import Numbers from './Components/Numbers';

import Suspects from './Components/Suspects';
import "./css/numpad.css";
import Record from './Components/Record';
import RecordsChoose from './Components/RecordsChoose';
import BillingChoose from './Components/BillingChoose';
import RecordsChooseOld from './Components/RecordsChooseOld';
import JournalOld from './Components/JournalOld';
import RecordOld from './Components/RecordOld';
import BillingManagementOld from './Components/BillingManagementOld';
import Reports from './Components/Reports/Reports'
import PrefixReport from './Components/PrefixReport/PrefixReport';
import GatewayPrefixes from './Components/GatewayPrefixes/GatewayPrefixes';
function App() {
  //require('dotenv').config();
  const location = useLocation();
  return (
      <Routes>
        <Route element={<Layout/>} >
          <Route path='dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
          <Route path='dashboard/:id/' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
          <Route path='users' element={<PrivateRoute><Users/></PrivateRoute>}/>
          <Route path='suspects' element={<PrivateRoute><Suspects/></PrivateRoute>}/>
          <Route path='denied' element={<NoAccess/>}/>
          <Route path='individual/:id/:sip' element={<PrivateRoute><Individual/></PrivateRoute>}/>
          <Route path='/record/:uuid/:time/:dst/:conversation/:duration/:sip/:status' element={<PrivateRoute><Record/></PrivateRoute>}/>
          <Route path='journal' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='journal/:company/' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='records/:company/' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='records/:company/:team' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          <Route path='records/:company/:team/:agent' element={<PrivateRoute><Journal/></PrivateRoute>}/>
          
        <Route path='/gateway/prefixes' element={<PrivateRoute><GatewayPrefixes /></PrivateRoute>} />
          <Route path='/record/old/:uuid/:time/:dst/:conversation/:duration/:sip/:status' element={<PrivateRoute><RecordOld/></PrivateRoute>}/>
          <Route path='journal/old' element={<PrivateRoute><JournalOld/></PrivateRoute>}/>
          <Route path='journal/old/:company/' element={<PrivateRoute><JournalOld/></PrivateRoute>}/>
          <Route path='records/old/:company/' element={<PrivateRoute><JournalOld/></PrivateRoute>}/>
          <Route path='records/old/:company/:team' element={<PrivateRoute><JournalOld/></PrivateRoute>}/>
          <Route path='records/old/:company/:team/:agent' element={<PrivateRoute><JournalOld/></PrivateRoute>}/>
          <Route path='records/choose/old' element={<PrivateRoute><RecordsChooseOld/></PrivateRoute>}/>
          <Route path='billing/management/old' element={<PrivateRoute><BillingManagementOld/></PrivateRoute>}/>
          <Route path='reports/prefix' element={<PrivateRoute><PrefixReport/></PrivateRoute>}/>
          <Route path='reports' element={<PrivateRoute><Reports/></PrivateRoute>}/>
          <Route path='onlinecalls' element={<PrivateRoute><OnlineCalls/></PrivateRoute>}/>
          <Route path='routing' element={<PrivateRoute><BrandRouting/></PrivateRoute>}/>
          <Route path='billing' element={<PrivateRoute><Billing/></PrivateRoute>}/>
          <Route path='billing/:id' element={<PrivateRoute><Billing/></PrivateRoute>}/>
          <Route path='freeswitchpanel' element={<PrivateRoute><FreeswitchPanel/></PrivateRoute>}/>
          <Route path='administration' element={<PrivateRoute><Administration/></PrivateRoute>}/>
          <Route path='gateways' element={<PrivateRoute><GatewaysManagement/></PrivateRoute>}/>
          <Route path='numbers' element={<PrivateRoute><Numbers/></PrivateRoute>}/>
          <Route path='analytics' element={<PrivateRoute><Analytics/></PrivateRoute>}/>
          <Route path='records/choose' element={<PrivateRoute><RecordsChoose/></PrivateRoute>}/>
          <Route path='number/verification' element={<PrivateRoute><NumberVerification/></PrivateRoute>}/>
          <Route path='billing/management/:id/:region' element={<PrivateRoute><BillingManagement/></PrivateRoute>}/>
          <Route path='billing/management/:id' element={<PrivateRoute><BillingManagement/></PrivateRoute>}/>
          <Route path='billing/choose' element={<PrivateRoute><BillingChoose/></PrivateRoute>}/>
          {/* <Route path='companies' element={JSON.parse(localStorage.getItem("user")).role_id===1?<PrivateRoute><Companies/></PrivateRoute>:<Navigate to="/profile" replace={true} />}/> */}
          <Route path='companies' element={<PrivateRoute><Companies/></PrivateRoute>}/>
          <Route path='companies/:id/offices' element={<PrivateRoute><Offices/></PrivateRoute>}/>
          <Route path='companies/:id/offices/:officeid/departments/' element={<PrivateRoute><Departments/></PrivateRoute>}/>
          <Route path='companies/:id/offices/:officeid/departments/:depid/teams' element={<PrivateRoute><Teams/></PrivateRoute>}/>
          <Route path='companies/:id/offices/:officeid/departments/:depid/teams/:teamid/agents' element={<PrivateRoute><Agents/></PrivateRoute>}/>
          <Route exact path="/" element={<PrivateRoute><OnlineCalls/></PrivateRoute>}/>
          <Route path='/' element={<PrivateRoute><Navigate to={location.pathname} replace={true}/></PrivateRoute>} />
        </Route>
        <Route exact path="/" element={<Navigate to="/login" replace={true}/>}/>
        <Route path='/login' element={<Login/>} />
        <Route path='*' element={<Error/>}/>
      </Routes>
  );
}


export default App;
