import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import Select from 'react-select';

const SearchBar = ({callback}) => {
    const [innerValue, setInnerValue] = useState("");
    const handleSubmit = e => {
        e.preventDefault()
        callback(innerValue)
    }
    return (
        <form className="searchBar" onSubmit={handleSubmit}>
        <input
            type="text" style={{fontSize:"1.5vh"}}
            className="searchBarInput"
            value={innerValue}
            onChange={(e) => setInnerValue(e.target.value)}
        />
        </form>
    );
}; 
export default function Administration() {
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    const [prefixes,SetPrefixes] = useState([]);
    const [countries,SetCountries] = useState([]);
    const [accounts,SetAccounts] = useState([]);
    const [operators,SetOperators] = useState([]);
    const [operatorseu,SetOperatorsEU] = useState([]);
    const [operatorsfull,SetOperatorsFULL] = useState([]);
    const [operator,setOperator] = useState({id:0,country:"",num:"",title:"",price:0});
    const [operatorE,setOperatorE] = useState({id:0,country:"",num:"",title:"",price:0});
    const [operatorF,setOperatorF] = useState({id:0,country:"",num:"",title:"",price:0});
    const activeOperator = useRef(-1);
    const activeOperatorE = useRef(-1);
    const activeOperatorF = useRef(-1);
    const [currentAccount,SetCurrentAccount] = useState({id:0,Title:"",balance:0, amount: 0, access : 0})
    const activeAccount = useRef(-1);
    const [current,SetCurrent] = useState({id:0,title:"",prefix:0,description:""})
    const active = useRef(-1);
    const [currentCountry,SetCurrentCountry] = useState({id:0,title:""})
    const activeCountry = useRef(-1);
    const [description,setDescription] = useState('');
    const [price,setPrice] = useState(0);
    const [access,setAccess] = useState(0);
    const [articles, setArticles] = useState(prefixes);
    const [articlesC, setArticlesC] = useState(countries);
    const [articlesB, setArticlesB] = useState(accounts);
    const [articlesO, setArticlesO] = useState(operators);
    const [articlesOEU, setArticlesOEU] = useState(operatorseu);
    const [articlesOF, setArticlesOF] = useState(operatorsfull);
    const [searchValue, setSearchValue] = useState("");
    const [searchValueC, setSearchValueC] = useState("");
    const [searchValueB, setSearchValueB] = useState("");
    const [searchValueO, setSearchValueO] = useState("");
    const [searchValueOEU, setSearchValueOEU] = useState("");
    const [searchValueOFULL, setSearchValueOFULL] = useState("");
    

    const [podmenaChance,setPodmenaChance] = useState({value:50,label:"1:1"});
    const [podmenaId,setPodmenaId] = useState(null);
    const [gateways,setGateways] = useState([]);
const chances = [                       
        { value: 50, label: '1:1' },
        { value: 33, label: '1:2' },
        { value: 25, label: '1:3' },
        { value: 20, label: '1:4' },
        { value: 16, label: '1:5' },
        { value: 14, label: '1:6' },
        { value: 12, label: '1:7' },
        { value: 11, label: '1:8' },
        { value: 10, label: '1:9' },
        { value: 9, label: '1:10' },
    ]

    const Withdrawal=()=>{
        if(description!= "" && price!=0){
            axios.post('https://www.clearcall.tel:8078/billing/manual',{
                id:currentAccount.id,
                price:price*-1,
                description:description
            })
            .then((res)=>{
                Swal.fire({
                    title:"Success",
                    icon:"success",
                    timer:1500
                })
            })
            .catch((err)=>{
                Swal.fire({
                    title:"Error",
                    icon:"error",
                    timer:1500
                })
            })
        }
    }
    const Replenish=()=>{
        if(description!= "" && price!=0){
            axios.post('https://www.clearcall.tel:8078/billing/manual',{
                id:currentAccount.id,
                price:price,
                description:description
            })
            .then((res)=>{
                console.log(res)
                Swal.fire({
                    title:"Success",
                    icon:"success",
                    timer:1500
                })
            })
            .catch((err)=>{
                console.log(err)
                Swal.fire({
                    title:"Error",
                    icon:"error",
                    timer:1500
                })
            })
        }
    }
    const filterTable = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return prefixes;
        }
        return prefixes.filter((article) =>
            article.prefix.toString().includes(searchValue)
        );
    };
    const filterTableC = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return countries;
        }
        return countries.filter((article) =>
            article.title.toString().includes(searchValue)
        );
    };
    const filterTableBa = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return accounts;
        }
        return accounts.filter((article) =>
            article.Title.toString().includes(searchValue)
        );
    };
    const filterTableO = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return operators;
        }
        return operators.filter((article) =>
            article.title.toString().includes(searchValue)
        );
    };
    const filterTableOEU = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return operatorseu;
        }
        return operatorseu.filter((article) =>
            article.title.toString().includes(searchValue)
        );
    };
    const filterTableOFULL = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return operatorsfull;
        }
        return operatorsfull.filter((article) =>
            article.title.toString().includes(searchValue)
        );
    };

    useEffect(()=>{
        
        axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            console.log(res.data);
            SetPrefixes(res.data);
            axios.get('https://www.clearcall.tel:8078/gateways')
            .then((res)=>{
                var arr = [];
                res.data.data.forEach((val)=>{
                    arr.push({value:val.id,label:val.title});
                })
                setGateways(arr);
            })
            .catch((err)=>console.log(err))
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
        
    },[])
    useEffect(()=>{
        
        setArticles(prefixes)
        setArticlesC(countries)
        setArticlesB(accounts)
        setArticlesO(operators)
        setArticlesOEU(operatorseu);
        setArticlesOF(operatorsfull);
    },[setArticles,setArticlesC,setArticlesB,setArticlesO,setArticlesOEU,setArticlesOF,operatorseu,operatorsfull,prefixes,countries,accounts,operators])
    const handleRefresh =()=>{
        axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                setArticlesB(filterTableBa(searchValueB))
            })
            .catch((err)=>console.log(err))
            })
        })
    }

    const SubmitAccess = () =>{
        console.log(access);
        axios.post("https://www.clearcall.tel:8078/billing/account/access", {
            id:currentAccount.id,
            access:access
        })
        .then((res)=>{
            console.log(res);
            Swal.fire({
                icon:"success",
                text:"Success!"
            })
            axios.get("https://www.clearcall.tel:8078/billing/accounts")
        .then((res)=>{
            console.log(res.data);
            SetAccounts(res.data);
        })
        .catch((err)=>console.log(err))
            
        })
        .catch((err)=> Swal.fire({
            title:"Error!",
            timer:2000,
            icon:"error",
            text:err
        }))
    }

    const SwalDel =()=>{
        return Swal.fire({ // <-- return Promise
        title: 'Delete record?',
        text: 'This action cannot be undone.',
        icon: 'question',
        confirmButtonText: 'Delete',
        confirmButtonColor: '#DC004E',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false
        }).then((result) => {
        return result.isConfirmed;
        });
    }
    const HandleAdd = () => {
        console.log(current);
        if(current.title === "" || current.description === "" || current.prefix === 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/prefixes",{
                title:current.title,
                description:current.description,
                prefix:current.prefix,
                additional: current.useAdditional?current.additional: '',
                typeofPrefix: current.typeofPrefix,
                regionofPrefix: current.regionofPrefix,
                useAdditional : current.useAdditional?1:0,
                podmenaId:podmenaId!=null?podmenaId.value:null,
                podmenaChance:podmenaChance!=null?podmenaChance.value:null
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));;
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleUpdate = () => {
        console.log(current);
        if(current.title === "" || current.description === "" || current.prefix === 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.patch("https://www.clearcall.tel:8078/prefixes",{
                id:current.id,
                title:current.title,
                description:current.description,
                prefix:current.prefix,
                additional: current.useAdditional?current.additional: '',
                typeofPrefix: current.typeofPrefix,
                regionofPrefix: current.regionofPrefix,
                useAdditional : current.useAdditional?1:0,
                podmenaId:podmenaId!=null?podmenaId.value:null,
                podmenaChance:podmenaChance!=null?podmenaChance.value:null
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));;
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleDelete=async()=>{
        let isdel = await SwalDel();
        console.log(current);
        console.log(isdel);
        if(isdel){
            axios.post("https://www.clearcall.tel:8078/prefixes/del",{id:current.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));;
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting prefix.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    const HandleAddC = () => {
        console.log(currentCountry);
        if(currentCountry.title === ""){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/countries",{
                title:currentCountry.title
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));;
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleUpdateC = () => {
        console.log(currentCountry);
        if(currentCountry.title === ""){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.patch("https://www.clearcall.tel:8078/countries",{
                id:currentCountry.id,
                title:currentCountry.title
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));;
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleDeleteC=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.clearcall.tel:8078/countries/del",{id:currentCountry.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));;
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    const HandleTopUp = () => {
        console.log(currentAccount);
        if(currentAccount.amount !== 0){
            axios.post('https://www.clearcall.tel:8078/billing/insertbalance',{
                money:currentAccount.amount,
                id:currentAccount.id
            })
            .then((res)=>{Swal.fire({
                icon:"success",
                text:res.data
            })
            axios.get("https://www.clearcall.tel:8078/prefixes")
            .then((res)=>{
                SetPrefixes(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/countries")
                .then((res)=>{
                    SetCountries(res.data);
                })
                .catch((err)=>console.log(err))
                .then(()=>{
                    axios.get("https://www.clearcall.tel:8078/billing/accounts")
                .then((res)=>{
                    console.log(res.data);
                    SetAccounts(res.data);
                    axios.get("https://www.clearcall.tel:8078/alloperators")
                    .then((res)=>{
                        console.log(res.data);
                        SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));;
                        setArticles(prefixes)
                        setArticlesC(countries)
                        setArticlesB(accounts)
                        setArticlesO(operators)
                    })
                    .catch((err)=>console.log(err))
                })
                .catch((err)=>console.log(err))
                })
            })}
            )
            .catch((err)=>Swal.fire({
                icon:'error',
                text:err
            }))
        }
    }
    
    const HandleAddO = () => {
        console.log(operator);
        if(operator.title === "" || operator.country === "" || operator.num === "" || operator.price ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/operators/add",{
                title:operator.title,
                num:operator.num,
                country:operator.country,
                region: 'ru',
                price:operator.price
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            })
        console.log(err)})
        }
    }
    const HandleAddOE = () => {
        console.log(operatorE);
        if(operatorE.title === "" || operatorE.country === "" || operatorE.num === "" || operatorE.price ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/operators/add",{
                title:operatorE.title,
                num:operatorE.num,
                country:operatorE.country,
                region:"eu",
                price:operatorE.price
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            })
        console.log(err)})
        }
    }
    const HandleAddOF = () => {
        console.log(operatorF);
        if(operatorF.title === "" || operatorF.country === "" || operatorF.num === "" || operatorF.price == 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/operators/add",{
                title:operatorF.title,
                num:operatorF.num,
                country:operatorF.country,
                region:"full",
                price:operatorF.price
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            })
        console.log(err)})
        }
    }
    const HandleUpdateO = () => {
        console.log(operator);
        if(operator.title === "" || operator.country === "" || operator.num === "" || operator.price ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/operators/update",{
                id:operator.id,
                title:operator.title,
                num:operator.num,
                country:operator.country,
                price:operator.price
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleUpdateOF = () => {
        console.log(operatorF);
        if(operatorF.title === "" || operatorF.country === "" || operatorF.num === "" || operatorF.price == 0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/operators/update",{
                id:operatorF.id,
                title:operatorF.title,
                num:operatorF.num,
                country:operatorF.country,
                price:operatorF.price
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleUpdateOE = () => {
        console.log(operatorE);
        if(operatorE.title === "" || operatorE.country === "" || operatorE.num === "" || operatorE.price ==0){
            Swal.fire({
                title:"Error!",
                timer:2000,
                icon:"error",
                text:"Fill the fields!"
            })
        }
        else {
            axios.post("https://www.clearcall.tel:8078/operators/update",{
                id:operatorE.id,
                title:operatorE.title,
                num:operatorE.num,
                country:operatorE.country,
                price:operatorE.price
            })
            .then((res)=>{
                Swal.fire({
                    icon:"success",
                    text:"Success!"
                })
                axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>Swal.fire({
                title:"Database error.",
                text:err,
                icon:'warning'
            }))
        }
    }
    const HandleDeleteO=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.clearcall.tel:8078/operators/del",{id:operator.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    const HandleDeleteOF=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.clearcall.tel:8078/operators/del",{id:operatorF.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    const HandleDeleteOE=async()=>{
        let isdel = await SwalDel();
        console.log(isdel);
        if(isdel){
            axios.post("https://www.clearcall.tel:8078/operators/del",{id:operatorE.id })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                icon:'success',
                timer:2000,
                title: 'Result',
                text: res.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            axios.get("https://www.clearcall.tel:8078/prefixes")
        .then((res)=>{
            SetPrefixes(res.data);
        })
        .catch((err)=>console.log(err))
        .then(()=>{
            axios.get("https://www.clearcall.tel:8078/countries")
            .then((res)=>{
                SetCountries(res.data);
            })
            .catch((err)=>console.log(err))
            .then(()=>{
                axios.get("https://www.clearcall.tel:8078/billing/accounts")
            .then((res)=>{
                console.log(res.data);
                SetAccounts(res.data);
                axios.get("https://www.clearcall.tel:8078/alloperators")
                .then((res)=>{
                    console.log(res.data);
                    SetOperators(res.data.filter((el)=>{return el.region==='ru'}));
                    SetOperatorsEU(res.data.filter((el)=>{return el.region==='eu'}));
                    SetOperatorsFULL(res.data.filter((el)=>{return el.region==='full'}));
                    setArticles(prefixes)
                    setArticlesC(countries)
                    setArticlesB(accounts)
                    setArticlesO(operators)
                    setArticlesOEU(operatorseu);
                    setArticlesOF(operatorsfull);
                })
                .catch((err)=>console.log(err))
            })
            .catch((err)=>console.log(err))
            })
        })
            })
            .catch((err)=>{
                console.log(err);
                Swal.fire({
                icon: 'error',
                timer:2000,
                title: 'Error occured while deleting country.',
                text: err.statusText,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            })
        }
    }
    console.log(current)
    if(role !== 1){
        return <Navigate to="/denied" replace={true}/>
    }
    else
    return (
        <div className='container-xxl flex-grow-1 container-p-y' style={{marginInline:0,width:"80vw" }}>
            {/* <button className='btn btn-label-success' onClick={handleRefresh} style={{marginBottom:10}}>Refresh (click when choose to unlock buttons or when delete\update\edit)</button> */}
        <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Prefix</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={current.title} onChange={(e)=>SetCurrent((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row g-2">
                    <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="description" className="form-label" style={{fontSize:"1.5vh"}}>Description</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="description" className="form-control" placeholder="Description.." 
                    value={current.description} onChange={(e)=>SetCurrent((prev)=>{return{...prev,description:e.target.value}})}/>
                    </div>
                    <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="prefix" className="form-label" style={{fontSize:"1.5vh"}}>Prefix</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="prefix" className="form-control" max={999} min={0}  
                    value={current.prefix} onChange={(e)=>SetCurrent((prev)=>{return{...prev,prefix:e.target.value}})}/>
                    </div>
                </div>
                <div className="row g-2">
                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Use Additional prefix</label>
                    <br/>
                    <input type="checkbox" id="use" className="form-check-input" checked={current.useAdditional}
                     onChange={(e)=>{SetCurrent((prev)=>{return{...prev,useAdditional:e.target.checked}})}}/>
                </div>
                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Type of prefix</label>
                    <br/>
                    <Select options={[  { value: 'spam', label: 'spam' },
                            { value: 'pool', label: 'pool' },
                            { value: 'dedicated', label: 'dedicated' }]} 
                        onChange={(e)=>SetCurrent((prev)=>{return{...prev,typeofPrefix:e.value}})}
                        placeholder='Select type' id='prefixLb'
                        value={{value : current.typeofPrefix, label:current.typeofPrefix}}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                </div>
                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Region of prefix</label>
                    <br/>
                    <Select options={[  { value: 'eu', label: 'eu' },
                            { value: 'ru', label: 'ru' },
                            { value: 'full', label: 'full' }]} 
                        onChange={(e)=>SetCurrent((prev)=>{return{...prev,regionofPrefix:e.value}})}
                        placeholder='Select region' id='prefixLb'
                        value={{value : current.regionofPrefix, label:current.regionofPrefix}}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                </div>

                </div>
                <div className="row">
                {
                        current.useAdditional?
                        <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                        <label htmlFor="additional" className="form-label" style={{fontSize:"1.5vh"}}>Additional prefix</label>
                        <input type="text" style={{fontSize:"1.5vh"}} id="additional" className="form-control" placeholder="Additional.." 
                        value={current.additional} onChange={(e)=>SetCurrent((prev)=>{return{...prev,additional:e.target.value}})}/>
                        </div>
                        :''
                    }
                </div>
                <div className="col mb-0">
                                    <label htmlFor="use" className="form-label">Podmena</label>
                                    <br/>
                                    <input type="checkbox" id="use" className="form-check-input" checked={podmenaId!=null}
                                    onClick={(e)=>{podmenaId!=null?setPodmenaId(null):setPodmenaId(gateways[0]);setPodmenaChance(chances[0]);}}/>
                                </div>
                                {
                                    podmenaId &&
                                    <div className="row g-2">
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Podmena</label>
                                            <br/>
                                            <Select 
                                                options={gateways} 
                                                onChange={(e)=>setPodmenaId(e)}
                                                placeholder='Select gateway' id='prefixLb'
                                                value={podmenaId}
                                                 styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}
                                            />
                                        </div>
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                                Podmena chance
                                            </label>
                                            <br/>
                                            <Select 
                                                options={chances} 
                                                onChange={(e)=>setPodmenaChance(e)}
                                                placeholder='Select chance' id='prefixLb'
                                                value={podmenaChance}
                                                 styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}
                                            />
                                        </div>
                                    </div>
                                }
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" onClick={HandleUpdate} data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Save changes</button>
                <button className='btn btn-danger' data-bs-dismiss="modal"
                    onClick={HandleDelete}> 
                        Delete
                </button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalAdd" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Prefix</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={current.title} onChange={(e)=>SetCurrent((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row g-2 mb-3">
                    <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="description" className="form-label" style={{fontSize:"1.5vh"}}>Description</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="description" className="form-control" placeholder="Description.." 
                    value={current.description} onChange={(e)=>SetCurrent((prev)=>{return{...prev,description:e.target.value}})}/>
                    </div>
                    <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="prefix" className="form-label" style={{fontSize:"1.5vh"}}>Prefix</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="prefix" className="form-control" max={999} min={0}  
                    value={current.prefix} onChange={(e)=>SetCurrent((prev)=>{return{...prev,prefix:e.target.value}})}/>
                    </div>
                </div>
                <div className="row g-2">
                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Use Additional prefix</label>
                    <br/>
                    <input type="checkbox" id="use" className="form-check-input" checked={current.useAdditional}
                     onChange={(e)=>{SetCurrent((prev)=>{return{...prev,useAdditional:e.target.checked}})}}/>
                </div>
                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Type of prefix</label>
                    <br/>
                    <Select options={[  { value: 'spam', label: 'spam' },
                            { value: 'pool', label: 'pool' },
                            { value: 'dedicated', label: 'dedicated' }]} 
                        onChange={(e)=>SetCurrent((prev)=>{return{...prev,typeofPrefix:e.value}})}
                        placeholder='Select type' id='prefixLb'
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                </div>
                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Region of prefix</label>
                    <br/>
                    <Select options={[  { value: 'eu', label: 'eu' },
                            { value: 'ru', label: 'ru' },
                            { value: 'full', label: 'full' }]} 
                        onChange={(e)=>SetCurrent((prev)=>{return{...prev,regionofPrefix:e.value}})}
                        placeholder='Select region' id='prefixLb'
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                </div>
                </div>
                <div className="row">
                {
                        current.useAdditional?
                        <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                        <label htmlFor="additional" className="form-label" style={{fontSize:"1.5vh"}}>Additional prefix</label>
                        <input type="text" style={{fontSize:"1.5vh"}} id="additional" className="form-control" placeholder="Additional.." 
                        value={current.additional} onChange={(e)=>SetCurrent((prev)=>{return{...prev,additional:e.target.value}})}/>
                        </div>
                        :''
                    }
                 <div className="col mb-0">
                                    <label htmlFor="use" className="form-label">Podmena</label>
                                    <br/>
                                    <input type="checkbox" id="use" className="form-check-input" checked={podmenaId!=null}
                                    onClick={(e)=>{podmenaId!=null?setPodmenaId(null):setPodmenaId(gateways[0]);setPodmenaChance(chances[0]);}}/>
                                </div>
                                {
                                    podmenaId &&
                                    <div className="row g-2">
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>Podmena</label>
                                            <br/>
                                            <Select 
                                                options={gateways} 
                                                onChange={(e)=>setPodmenaId(e)}
                                                placeholder='Select gateway' id='prefixLb'
                                                value={podmenaId}
                                                 styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}
                                            />
                                        </div>
                                        <div style={{width:"80%"}}>
                                            <label htmlFor="use" className="form-label" style={{fontSize:"1.2vh"}}>
                                                Podmena chance
                                            </label>
                                            <br/>
                                            <Select 
                                                options={chances} 
                                                onChange={(e)=>setPodmenaChance(e)}
                                                placeholder='Select chance' id='prefixLb'
                                                value={podmenaChance}
                                                 styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}
                                            />
                                        </div>
                                    </div>
                                }
                </div>
                </div>

                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleAdd}>Submit</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalC" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Country</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={currentCountry.title} onChange={(e)=>SetCurrentCountry((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleUpdateC}>Save changes</button>
                <button className='btn btn-danger' data-bs-dismiss="modal"
                    onClick={HandleDeleteC}>
                        Delete
                </button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalAddC" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Prefix</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={currentCountry.title} onChange={(e)=>SetCurrentCountry((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleAddC}>Submit</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalB" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Billing account (id={currentAccount.id})</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Balance</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="title" className="form-control" placeholder="Enter amount" min={0}
                    onChange={(e)=>SetCurrentAccount((prev)=>{return{...prev,amount:e.target.value-0}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleTopUp}>Top up</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalO" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Operator RU</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={operator.title} onChange={(e)=>setOperator((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="country" className="form-label" style={{fontSize:"1.5vh"}}>Country</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="country" className="form-control" placeholder="Enter country" 
                    value={operator.country} onChange={(e)=>setOperator((prev)=>{return{...prev,country:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Num</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="num" className="form-control" placeholder="Enter num" 
                    value={operator.num} onChange={(e)=>setOperator((prev)=>{return{...prev,num:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Price</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="num" className="form-control" placeholder="Enter price" 
                    value={operator.price} onChange={(e)=>setOperator((prev)=>{return{...prev,price:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleUpdateO}>Save changes</button>
                <button className='btn btn-danger' data-bs-dismiss="modal"
                    onClick={HandleDeleteO}>
                        Delete
                </button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalOE" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Operator EU</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={operatorE.title} onChange={(e)=>setOperatorE((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="country" className="form-label" style={{fontSize:"1.5vh"}}>Country</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="country" className="form-control" placeholder="Enter country" 
                    value={operatorE.country} onChange={(e)=>setOperatorE((prev)=>{return{...prev,country:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Num</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="num" className="form-control" placeholder="Enter num" 
                    value={operatorE.num} onChange={(e)=>setOperatorE((prev)=>{return{...prev,num:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Price</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="num" className="form-control" placeholder="Enter price" 
                    value={operatorE.price} onChange={(e)=>setOperatorE((prev)=>{return{...prev,price:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleUpdateOE}>Save changes</button>
                <button className='btn btn-danger' data-bs-dismiss="modal"
                    onClick={HandleDeleteOE}>
                        Delete
                </button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalOF" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Operator FULL</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={operatorF.title} onChange={(e)=>setOperatorF((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="country" className="form-label" style={{fontSize:"1.5vh"}}>Country</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="country" className="form-control" placeholder="Enter country" 
                    value={operatorF.country} onChange={(e)=>setOperatorF((prev)=>{return{...prev,country:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Num</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="num" className="form-control" placeholder="Enter num" 
                    value={operatorF.num} onChange={(e)=>setOperatorF((prev)=>{return{...prev,num:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Price</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="num" className="form-control" placeholder="Enter price" 
                    value={operatorF.price} onChange={(e)=>setOperatorF((prev)=>{return{...prev,price:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleUpdateOF}>Save changes</button>
                <button className='btn btn-danger' data-bs-dismiss="modal"
                    onClick={HandleDeleteOF}>
                        Delete
                </button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalAddO" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Operator RU</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={operator.title} onChange={(e)=>setOperator((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="country" className="form-label" style={{fontSize:"1.5vh"}}>Country</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="country" className="form-control" placeholder="Enter country" 
                    value={operator.country} onChange={(e)=>setOperator((prev)=>{return{...prev,country:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Num</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="num" className="form-control" placeholder="Enter num" 
                    value={operator.num} onChange={(e)=>setOperator((prev)=>{return{...prev,num:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Price</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="num" className="form-control" placeholder="Enter price" 
                    value={operator.price} onChange={(e)=>setOperator((prev)=>{return{...prev,price:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleAddO}>Submit</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalAddOF" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Operator FULL</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={operatorF.title} onChange={(e)=>setOperatorF((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="country" className="form-label" style={{fontSize:"1.5vh"}}>Country</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="country" className="form-control" placeholder="Enter country" 
                    value={operatorF.country} onChange={(e)=>setOperatorF((prev)=>{return{...prev,country:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Num</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="num" className="form-control" placeholder="Enter num" 
                    value={operatorF.num} onChange={(e)=>setOperatorF((prev)=>{return{...prev,num:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Price</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="num" className="form-control" placeholder="Enter price" 
                    value={operatorF.price} onChange={(e)=>setOperatorF((prev)=>{return{...prev,price:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleAddOF}>Submit</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="basicModalAddOE" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel1">Operator EU</h3>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="title" className="form-label" style={{fontSize:"1.5vh"}}>Title</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="title" className="form-control" placeholder="Enter title" 
                    value={operatorE.title} onChange={(e)=>setOperatorE((prev)=>{return{...prev,title:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="country" className="form-label" style={{fontSize:"1.5vh"}}>Country</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="country" className="form-control" placeholder="Enter country" 
                    value={operatorE.country} onChange={(e)=>setOperatorE((prev)=>{return{...prev,country:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Num</label>
                    <input type="text" style={{fontSize:"1.5vh"}} id="num" className="form-control" placeholder="Enter num" 
                    value={operatorE.num} onChange={(e)=>setOperatorE((prev)=>{return{...prev,num:e.target.value}})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                    <label htmlFor="num" className="form-label" style={{fontSize:"1.5vh"}}>Price</label>
                    <input type="number"  style={{fontSize:"1.5vh"}}id="num" className="form-control" placeholder="Enter price" 
                    value={operatorE.price} onChange={(e)=>setOperatorE((prev)=>{return{...prev,price:e.target.value}})}/>
                    </div>
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}} onClick={HandleAddOE}>Submit</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="modalTopTitle">Replenishment</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="nameSlideTop" className="form-label" style={{fontSize:"1.5vh"}}>Description</label>
                                    <input type="text" style={{fontSize:"1.5vh"}} id="nameSlideTop" className="form-control" 
                                    value={description} onChange={(e)=>setDescription(e.target.value)}/>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                                    <label htmlFor="emailSlideTop" className="form-label" style={{fontSize:"1.5vh"}}>Cost</label>
                                    <input type="number"  style={{fontSize:"1.5vh"}}id="emailSlideTop" className="form-control"
                                    value={price} onChange={(e)=>setPrice(e.target.value)} min={0}/>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>Replenish()}>Replenish</button>
                        </div>
                        </form>
                    </div>
        </div>
        <div className="modal fade" data-bs-backdrop="static" id="modalDown" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="modalTopTitle">Withdrawal</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="nameSlideTop" className="form-label" style={{fontSize:"1.5vh"}}>Description</label>
                                    <input type="text" style={{fontSize:"1.5vh"}} id="nameSlideTop" className="form-control" 
                                    value={description} onChange={(e)=>setDescription(e.target.value)}/>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                                    <label htmlFor="emailSlideTop" className="form-label" style={{fontSize:"1.5vh"}}>Cost</label>
                                    <input type="number"  style={{fontSize:"1.5vh"}}id="emailSlideTop" className="form-control"
                                    value={price} onChange={(e)=>setPrice(e.target.value)} max={0}/>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>Withdrawal()}>Withdrawal</button>
                        </div>
                        </form>
                    </div>
        </div>
        
        <div className="modal fade" data-bs-backdrop="static" id="modalAccess" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="modalTopTitle">Withdrawal</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="row g-2">
                                <div className="col mb-0" style={{fontSize:"1.5vh"}}>
                                    <label htmlFor="emailSlideTop" className="form-label" style={{fontSize:"1.5vh"}}>Negative balance access:</label>
                                    <input type="checkbox" id="emailSlideTop" style={{marginLeft:"5px"}}
                                    checked={access} onChange={(e)=>setAccess(e.target.checked)}/>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" style={{fontSize:"1.5vh"}}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>SubmitAccess()}>Set</button>
                        </div>
                        </form>
                    </div>
        </div>

            <div style={{display:"flex",flexDirection:"row", width:'70vw' , flexWrap:'wrap',gap:'10px' , justifyContent:'center'}}>
                <div className="card" style={{width:'20vw',fontSize:"1.5vh"}}>
                    <div className="card-header" style={{fontWeight:"bold"}}>
                        Prefixes
        <div style={{display:"flex", flexDirection:"row"}}>
            <label style={{marginRight:10, fontWeight:"normal"}}>Find prefix</label>
            <SearchBar callback={(searchValue) => {setSearchValue(searchValue); console.log(searchValue); 
        setArticles(filterTable(searchValue))}} />
        </div>
                    </div>
                    <div className="card-body"  style={{display:'flex' , flexDirection:'column', alignItems:'center'}}>
                    <div className="list-group" style={{width:"16vw" , height:'60vh' , overflowY:'scroll', paddingBottom:'1vh', margin:' 0 auto'}}>
                        <fieldset>
                        {
                             
                            articles.length>0?articles.map((element,idx) => {
                                return <label className="list-group-item" key={idx} style={{display:"flex",flexDirection:"row",fontSize:"1.5vh"}}>
                                    <input className="form-check-input me-1" type="radio" style={{alignSelf:"center"}} 
                                    id={element.title} name="prefixes" value={element.prefix} onClick={()=>{active.current = idx ;console.log(prefixes[active.current]); handleRefresh()}}/>
                                    <input className='form-control' name="" style={{fontSize:'1.5vh'}} id="" value={`${element.title} | ${element.prefix}`} disabled/>
                                </label>
                            })
                            :null
                        }
                        </fieldset>          
                        </div>
                        <div style={{display:"flex", flexDirection:"row", marginTop:"1vh", width:'18vw'}}>
                            <button className="btn btn-primary" style={{width:"10vw",fontSize:"1.5vh"}} data-bs-toggle="modal" 
                            data-bs-target="#basicModalAdd"
                            onClick={()=>{SetCurrent({
                                id:0,
                                title:"",
                                description:"",
                                prefix:0
                            })}
                            }
                            >Add new prefix</button>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:'end', height:'4vh', width:'20vw'}}>
                                <button className='btn btn-warning' style={{marginRight:'0.4vw',fontSize:"1.5vh"}} data-bs-toggle="modal" data-bs-target="#basicModal"
                                    onClick={()=>{SetCurrent({
                                        id:articles[active.current].id,
                                        title:articles[active.current].title,
                                        description:articles[active.current].description,
                                        prefix:articles[active.current].prefix,
                                        useAdditional : articles[active.current].use_additional === 1? true : false,
                                        additional : articles[active.current].use_additional === 1? articles[active.current].additional_prefix: '',
                                        typeofPrefix: articles[active.current].type,
                                        regionofPrefix: articles[active.current].region
                                    })}
                                    }
                                    disabled={active.current===-1}
                                >
                                        Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card"  style={{width:'20vw',fontSize:"1.5vh"}}>
                    <div className="card-header" style={{fontWeight:"bold"}}>
                        Countries
        <div style={{display:"flex", flexDirection:"row"}}>
            <label style={{marginRight:10, fontWeight:"normal"}}>Find country</label>
            <SearchBar callback={(searchValueC) => {setSearchValueC(searchValueC); console.log(searchValueC); 
        setArticlesC(filterTableC(searchValueC))}} />
        </div>
                    </div>
                    <div className="card-body">
                    <div className="list-group" style={{width:"16vw"}}>
                        <div className="list-group" style={{width:"16vw", height:'60vh' , overflowY:'scroll', paddingBottom:'1vh', margin:' 0 auto'}}>
                        <fieldset>
                        {
                            
                            articlesC.length>0?articlesC.map((element,idx) => {
                                return <label className="list-group-item" key={idx} style={{display:"flex",flexDirection:"row",fontSize:"1.5vh"}}>
                                    <input className="form-check-input me-1" type="radio" style={{alignSelf:"center"}} 
                                    id={element.title} name="countries" value={element.title} onClick={()=>{activeCountry.current = idx ;console.log(countries[activeCountry.current]);handleRefresh() }}/>
                                    <input className='form-control' name="" style={{fontSize:'1.5vh'}} id="" value={`${element.title}`} disabled/>
                                </label>
                            })
                            :null
                        }
                        </fieldset>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", marginTop:10}}>
                            <button className="btn btn-primary" style={{width:'12.5vw',fontSize:"1.5vh"}} data-bs-toggle="modal" 
                            data-bs-target="#basicModalAddC"
                            onClick={()=>{SetCurrentCountry({
                                id:0,
                                title:""
                            })}
                            }
                            >Add new country</button>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:'end', height:'4vh', width:'20vw'}}>
                                <button className='btn btn-warning' style={{marginRight:'0.4vw',fontSize:"1.5vh"}} data-bs-toggle="modal" data-bs-target="#basicModalC"
                                    onClick={()=>{SetCurrentCountry({
                                        id:countries[activeCountry.current].id,
                                        title:countries[activeCountry.current].title
                                    })}
                                    }
                                    disabled={activeCountry.current===-1}
                                >
                                        Edit
                                </button>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
                <div className="card"  style={{width:'20vw',fontSize:"1.5vh"}}>
                    <div className="card-header" style={{fontWeight:"bold"}}>
                        Billing accounts
        <div style={{display:"flex", flexDirection:"row"}}>
            <label style={{marginRight:10, fontWeight:"normal"}}>Find account</label>
            <SearchBar callback={(searchValueB) => {setSearchValueB(searchValueB); console.log(searchValueB); 
        setArticlesB(filterTableBa(searchValueB))}} />
        </div>
                    </div>
                    <div className="card-body">
                    <div className="list-group" style={{width:"16vw"}}>
                        <div className="list-group" style={{width:"16vw", height:'60vh' , overflowY:'scroll', paddingBottom:'1vh', margin:' 0 auto'}}>
                        <fieldset>
                            
                        {
                            
                            articlesB.length>0?articlesB.map((element,idx) => {
                                return <label className="list-group-item" key={idx} style={{display:"flex",flexDirection:"row",fontSize:"1.5vh"}}>
                                    <input className="form-check-input me-1" type="radio" style={{alignSelf:"center"}} 
                                    id={element.title} name="accounts" value={element.title} onClick={()=>{activeAccount.current = idx ;console.log(accounts[activeAccount.current]);handleRefresh() }}/>
                                    <input className='form-control' name="" style={{fontSize:'1.5vh'}} id="" value={`${element.Title} | ${element.balance}$ | ${element.negative_access?"Access +":"Access -"}`} disabled/>
                                </label>
                            })
                            :null
                        }
                        </fieldset>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", marginTop:10}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:'end', height:'4vh', width:'20vw'}}>
                                {/* <button className='btn btn-success' style={{marginRight:'0.4vw',fontSize:"1.5vh"}} data-bs-toggle="modal" data-bs-target="#basicModalB"
                                    onClick={()=>{SetCurrentAccount({
                                        id:accounts[activeAccount.current].id,
                                        Title:accounts[activeAccount.current].Title,
                                        balance:accounts[activeAccount.current].balance
                                    })}
                                    }
                                    disabled={activeAccount.current===-1}
                                >
                                        Top up
                                </button> */}
                                <button className='btn btn-label-success' style={{margin:'0.2vw',fontSize:"1.5vh"}} data-bs-toggle="modal" data-bs-target="#modalTop"
                                onClick={()=>{SetCurrentAccount({
                                    id:accounts[activeAccount.current].id,
                                    Title:accounts[activeAccount.current].Title,
                                    balance:accounts[activeAccount.current].balance
                                })}
                                }
                                disabled={activeAccount.current===-1}
                                >
                                    Replenishment
                                </button>
                                <button className='btn btn-label-danger' style={{margin:'0.2vw',fontSize:"1.5vh"}} data-bs-toggle="modal" data-bs-target="#modalDown"
                                onClick={()=>{SetCurrentAccount({
                                    id:accounts[activeAccount.current].id,
                                    Title:accounts[activeAccount.current].Title,
                                    balance:accounts[activeAccount.current].balance
                                })}
                                }
                                disabled={activeAccount.current===-1}
                                >
                                    Withdrawal
                                </button>
                                <button className='btn btn-label-info' style={{margin:'0.2vw', padding:'0.5vw',fontSize:"1.5vh"}} data-bs-toggle="modal" data-bs-target="#modalAccess"
                                onClick={()=>{SetCurrentAccount({
                                    id:accounts[activeAccount.current].id,
                                    Title:accounts[activeAccount.current].Title,
                                    balance:accounts[activeAccount.current].balance,
                                    access: accounts[activeAccount.current].negative_access
                                });setAccess(accounts[activeAccount.current].negative_access)
                                }
                                }
                                disabled={activeAccount.current===-1}
                                >
                                    Set access
                                </button>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
                <div className="card"  style={{width:'20vw',fontSize:"1.5vh"}}>
                        <div className="card-header" style={{fontWeight:"bold"}}>
                            Operators RU
            <div style={{display:"flex", flexDirection:"row"}}>
                <label style={{marginRight:10, fontWeight:"normal"}}>Find operator</label>
                <SearchBar callback={(searchValueO) => {setSearchValueO(searchValueO); console.log(searchValueO); 
            setArticlesO(filterTableO(searchValueO))}} />
            </div>
                        </div>
                        <div className="card-body" style={{display:'flex' , flexDirection:'column', alignItems:'center'}}>
                        <div className="list-group" style={{width:"16vw", height:'60vh' , overflowY:'scroll', paddingBottom:'1vh', margin:' 0 auto'}}>
                            <fieldset>
                            {
                                
                                articlesO.length>0?articlesO.map((element,idx) => {
                                    return <label className="list-group-item" key={idx} style={{display:"flex",flexDirection:"row",fontSize:"1.5vh"}}>
                                        <input className="form-check-input me-1" type="radio" style={{alignSelf:"center"}} 
                                        id={element.title} name="operators" value={element.title} 
                                        onClick={()=>{activeOperator.current = idx ;console.log(operators[activeOperator.current]); handleRefresh()}}/>
                                        <input className='form-control' name="" style={{fontSize:'1.5vh'}} id="" value={`${element.country} | ${element.num} | ${element.price}`} disabled/>
                                    </label>
                                })
                                :null
                            }
                            </fieldset>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", marginTop:"1vh", width:'18vw'}}>
                                <button className="btn btn-primary" style={{width:'16vw',fontSize:"1.5vh"}} data-bs-toggle="modal" 
                                data-bs-target="#basicModalAddO"
                                onClick={()=>{setOperator({
                                    id:0,
                                    title:"",
                                    country:"",
                                    num:"",
                                    price:0
                                })}
                                }
                                >Add new operator RU</button>
                                <div style={{display:"flex", flexDirection:"row", justifyContent:'end', height:'4vh', width:'20vw'}}>
                                    <button className='btn btn-warning' style={{marginRight:'0.4vw',fontSize:"1.5vh"}} data-bs-toggle="modal" 
                                    data-bs-target="#basicModalO"
                                        onClick={()=>{setOperator({
                                            id:operators[activeOperator.current].id,
                                            title:operators[activeOperator.current].title,
                                            num:operators[activeOperator.current].num,
                                            country:operators[activeOperator.current].country,
                                            price:operators[activeOperator.current].price
                                        })}
                                        }
                                        disabled={activeOperator.current===-1}
                                    >
                                            Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="card"  style={{width:'20vw',fontSize:"1.5vh"}}>
                        <div className="card-header" style={{fontWeight:"bold"}}>
                            Operators EU
            <div style={{display:"flex", flexDirection:"row"}}>
                <label style={{marginRight:10, fontWeight:"normal"}}>Find operator</label>
                <SearchBar callback={(searchValueOEU) => {setSearchValueOEU(searchValueOEU); console.log(searchValueOEU); 
            setArticlesOEU(filterTableOEU(searchValueOEU))}} />
            </div>
                        </div>
                        <div className="card-body" style={{display:'flex' , flexDirection:'column', alignItems:'center'}}>
                        <div className="list-group" style={{width:"16vw", height:'60vh' , overflowY:'scroll', paddingBottom:'1vh', margin:' 0 auto'}}>
                            <fieldset>
                            {
                                
                                articlesOEU.length>0?articlesOEU.map((element,idx) => {
                                    return <label className="list-group-item" key={idx} style={{display:"flex",flexDirection:"row",fontSize:"1.5vh"}}>
                                        <input className="form-check-input me-1" type="radio" style={{alignSelf:"center"}} 
                                        id={element.title} name="operators" value={element.title} 
                                        onClick={()=>{activeOperatorE.current = idx ;console.log(operatorseu[activeOperatorE.current]);handleRefresh() }}/>
                                        <input className='form-control' name="" style={{fontSize:'1.5vh'}} id="" value={`${element.country} | ${element.num} | ${element.price}`} disabled/>
                                    </label>
                                })
                                :null
                            }
                            </fieldset>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", marginTop:"1vh", width:'18vw'}}>
                                <button className="btn btn-primary" style={{width:'16vw',fontSize:"1.5vh"}} data-bs-toggle="modal" 
                                data-bs-target="#basicModalAddOE"
                                onClick={()=>{setOperatorE({
                                    id:0,
                                    title:"",
                                    country:"",
                                    num:"",
                                    price:0
                                })}
                                }
                                >Add new operator EU</button>
                                <div style={{display:"flex", flexDirection:"row", justifyContent:'end', height:'4vh', width:'20vw'}}>
                                    <button className='btn btn-warning' style={{marginRight:'0.4vw',fontSize:"1.5vh"}} data-bs-toggle="modal" 
                                    data-bs-target="#basicModalOE"
                                        onClick={()=>{setOperatorE({
                                            id:operatorseu[activeOperatorE.current].id,
                                            title:operatorseu[activeOperatorE.current].title,
                                            num:operatorseu[activeOperatorE.current].num,
                                            country:operatorseu[activeOperatorE.current].country,
                                            price:operatorseu[activeOperatorE.current].price
                                        })}
                                        }
                                        disabled={activeOperatorE.current===-1}
                                    >
                                            Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="card"  style={{width:'20vw',fontSize:"1.5vh"}}>
                    <div className="card-header" style={{fontWeight:"bold"}}>
                        Operators FULL
        <div style={{display:"flex", flexDirection:"row"}}>
            <label style={{marginRight:10, fontWeight:"normal"}}>Find operator</label>
            <SearchBar callback={(searchValueOFULL) => {setSearchValueOFULL(searchValueOFULL); console.log(searchValueOFULL); 
        setArticlesOF(filterTableOFULL(searchValueOFULL))}} />
        </div>
                    </div>
                    <div className="card-body" style={{display:'flex' , flexDirection:'column', alignItems:'center'}}>
                    <div className="list-group" style={{width:"16vw", height:'60vh' , overflowY:'scroll', paddingBottom:'1vh', margin:' 0 auto'}}>
                        <fieldset>
                        {
                            
                            articlesOF.length>0?articlesOF.map((element,idx) => {
                                return <label className="list-group-item" key={idx} style={{display:"flex",flexDirection:"row",fontSize:"1.5vh"}}>
                                    <input className="form-check-input me-1" type="radio" style={{alignSelf:"center"}} 
                                    id={element.title} name="operators" value={element.title} 
                                    onClick={()=>{activeOperatorF.current = idx ;console.log(operatorsfull[activeOperatorF.current]);handleRefresh() }}/>
                                    <input className='form-control' name="" style={{fontSize:'1.5vh'}} id="" value={`${element.country} | ${element.num} | ${element.price}`} disabled/>
                                </label>
                            })
                            :null
                        }
                        </fieldset>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", marginTop:"1vh", width:'18vw'}}>
                            <button className="btn btn-primary" style={{width:'16vw',fontSize:"1.5vh"}} data-bs-toggle="modal" 
                            data-bs-target="#basicModalAddOF"
                            onClick={()=>{setOperatorF({
                                id:0,
                                title:"",
                                country:"",
                                num:"",
                                price:0
                            })}
                            }
                            >Add new operator FULL</button>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:'end', height:'4vh', width:'20vw'}}>
                                <button className='btn btn-warning' style={{marginRight:'0.4vw',fontSize:"1.5vh"}} data-bs-toggle="modal" 
                                data-bs-target="#basicModalOF"
                                    onClick={()=>{setOperatorF({
                                        id:operatorsfull[activeOperatorF.current].id,
                                        title:operatorsfull[activeOperatorF.current].title,
                                        num:operatorsfull[activeOperatorF.current].num,
                                        country:operatorsfull[activeOperatorF.current].country,
                                        price:operatorsfull[activeOperatorF.current].price
                                    })}
                                    }
                                    disabled={activeOperatorF.current===-1}
                                >
                                        Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
