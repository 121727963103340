import axios from 'axios';
import React, { useState } from 'react'
import secureLocalStorage from 'react-secure-storage';

export default function NumberVerification() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [checkResult, setCheckResult] = useState("");
    const [checkSpam , setCheckSpam] = useState("");
    const [spamResult , setSpamResult] = useState("");
    const onPhoneChange=(e)=>{
        console.log(e.target.value);
        setPhoneNumber(e.target.value);
        console.log(secureLocalStorage.getItem("user"))
    }
    const VerifyPhone=()=>{
        axios.post("https://www.clearcall.tel:8078/verifynumber", {
            phoneNumber,
            sip:JSON.parse(secureLocalStorage.getItem("user")).login
    }).then((res)=>{
        setCheckResult(res.data);
        })
    }

    const SpamNumber =async ()=>{
        const num = checkSpam
        const {data} =  await axios.post('https://www.clearcall.tel:8078/callFilterCheck', {num})
        setSpamResult(data)
    }
  return (
    <div className='container-xxl flex-grow-1 container-p-y' style={{display:"flex", flexDirection:"row", fontFamily:"Poppins"}}>
        <div className='card' style={{width:"650px", height:"500px", margin:"10px"}}>
            <div className='card-header' style={{fontWeight:"bold", fontSize:"25px"}}>Check for spam</div>
            <div className='card-body'>
                <div class="mb-3">
                    <label for="defaultFormControlInput" className="form-label">Phone number</label>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <input type="text" className="form-control" id="defaultFormControlInput" 
                        placeholder="33312345678901" onChange={e=> setCheckSpam(e.target.value)} aria-describedby="defaultFormControlHelp"  />
                        <button className='btn btn-label-primary' onClick={()=>SpamNumber()} style={{marginLeft:"10px"}}>Check</button>
                    </div>
                </div>
                <textarea rows="5" value={spamResult} className="form-control" 
                style={{width:"100%", height:300}}
                readOnly/>
            </div>
        </div>
        <div className='card' style={{width:"650px", height:"500px", margin:"10px"}}>
            <div className='card-header' style={{fontWeight:"bold", fontSize:"25px"}}>Verify number for call</div>
            <div className='card-body'>
                <div class="mb-3">
                    <label for="defaultFormControlInput" class="form-label">Phone number</label>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <input type="number" class="form-control" id="defaultFormControlInput" 
                        onChange={(e)=>onPhoneChange(e)}
                        placeholder="33312345678901" aria-describedby="defaultFormControlHelp" />
                        <button className='btn btn-label-primary' style={{marginLeft:"10px"}}
                        onClick={()=>VerifyPhone()}>Verify</button>
                    </div>
                </div>
                <textarea rows="5" className="form-control" 
                style={{width:"100%", height:300}}
                value={checkResult}
                readOnly/>
            </div>

        </div>
    </div>
  )
}
