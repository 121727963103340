import React, { Component } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {ic_phone_missed} from 'react-icons-kit/md/ic_phone_missed'
import { withBaseIcon } from 'react-icons-kit';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../css/table.css";
import DataTable from 'datatables.net-dt';
import DateTime from 'datatables.net-datetime';
import 'datatables.net-plugins/api/sum().mjs';
import moment from 'moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const SideIconContainer = 
    withBaseIcon({ size: 32, style: {color: '#a3a5ca'}})

export const PhoneMissedIcon = () => <SideIconContainer icon={ic_phone_missed}/>
   class BillingRecords extends Component {
        company = '';
        options = [
            { value: 2, label: 'Company admin'},
            { value: 3, label: 'Office admin'},
            { value: 4, label: 'Department admin'},
            { value: 5, label: 'Team admin'},
            { value: 6, label: 'Agent'},
            { value: 7, label: 'BrandManager'},
            { value: 8, label: 'Coach'},
        ]
        options1 = [
            { value: 1, label: 'Russia'},
            { value: 2, label: 'Armenia'},
            { value: 3, label: 'Kazakhstan'},
            { value: 4, label: 'Azerbaijan'},
        ]
        options2 = []
        state = { company:1,prefix:1,inbound:"",outbound:"",operator:1,price:0.0,priceUpdate:0.0,prefixUpdate:0,status:0,
        prefixDisabled:true, countryDisabled:true,operatorDisabled:true,priceDisabled:true,idUpdate:0, sum:0, pref:"",
        inputs:{
            company: false,
            prefix:false,
            inbound:false,
            outbound:false,
            operator:false,
            price:false
        }, valid:false }
        SwalDel(){
            return Swal.fire({ // <-- return Promise
                title: 'Delete record?',
                text: 'This action cannot be undone.',
                icon: 'question',
                confirmButtonText: 'Delete',
                confirmButtonColor: '#DC004E',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
                scrollbarPadding: false
                }).then((result) => {
                return result.isConfirmed;
                });
        }
        componentDidMount(){
            const { t , billingData } = this.props;
            console.log(billingData);
            console.log(JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id);
            const self = this;
            if (!$.fn.DataTable.isDataTable("#myTable")) {
                $(document).ready(function () {
                    setTimeout(function () {
                    $("#myTable").DataTable({
                        pagingType: "full_numbers",
                        pageLength: 20,
                        processing: true,
                        serverSide: false,
                        dom: "frtip",
                        paging:true,
                        select: {
                            style: "single",
                        },
                        language: {
                            "processing": 'Loading data...'
                        },
                        ajax:{
                            url:'https://www.clearcall.tel:8078/billing/records',
                            type:'POST',
                            dataType:"json",
                            dataSrc: (json)=>{
                                // secureLocalStorage.setItem('totalRecords',json.totalRecords);
                                return json.data;
                            },
                            data: function (d) {
                                // Добавление дополнительных параметров к запросу
                                d.id = billingData !== undefined || null 
                                    ? billingData[0].id
                                    : secureLocalStorage.getItem("billingCurrentData") !== undefined || null
                                        ? secureLocalStorage.getItem("billingCurrentData")[0].id
                                        : JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id;
                                // d.prefix = $("#prefix").val();
                                // d.startTime = $("#min").val().length>0?moment($("#min").val(), "MMMM Do YYYY").format("YYYY-MM-DD"):null;
                                // d.endTime =  $("#max").val().length>0?moment($("#max").val(), "MMMM Do YYYY").format("YYYY-MM-DD"):null;
                            },
                            error: function (xhr, textStatus, errorThrown) {
                                console.log(xhr);
                            }
                        },
                        columns: [
                            { data: null,name: '#'},
                            { data: 'Title',name: 'Company'},
                            { data: 'team',name: 'Team'},
                            { data: 'sip_user',name: 'User'},
                            { data: 'cost',name: 'Cost'},
                            { data: 'price',name: 'Price'},
                            { data: 'conversation',name: 'Conversation',
                            render: function(data,type,row){
                            return data<3600?new Date(data * 1000).toISOString().substring(14, 19)
                                :new Date(data * 1000).toISOString().substring(11, 19)
                            }},
                            { data: 'dst',name: 'Number'},
                            { data: 'country',name: 'Country'},
                            { data: 'time',name: 'Date',
                            render: function(data){
                                const date = new Date(data); 
                                const formattedDate = moment.parseZone(date).local().format('YYYY-MM-DD HH:mm:ss')
                                return formattedDate
                            }
                        },
                        ],
                        bDestroy:true,
                        buttons: [
                            {
                                extend: "copy",
                                text:t("onlineButtonsCopy"),
                                className: "btn btn-secondary bg-primary",
                            },
                            {
                                extend: "print",
                                text:t("onlineButtonsPrint"),
                                customize: function (win) {
                                $(win.document.body).css("font-size", "10pt");
                                $(win.document.body)
                                    .find("table")
                                    .addClass("compact")
                                    .css("font-size", "inherit");
                                },
                                className: "btn btn-secondary bg-primary",
                            },
                            {
                                extend: 'spacer',
                                style: 'bar',
                                text: 'Export files:'
                            },
                            {
                                extend: "csv",
                                text:t("onlineButtonsCSV"),
                                className: "btn btn-secondary bg-primary",
                            },
                            {
                                extend: "excel",
                                text:"Excel",
                                className: "btn btn-secondary bg-primary",
                            },
                            {
                                extend:'pdf',
                                text:"PDF",
                                className: "btn btn-secondary bg-primary",
                            }
    
                            
                            ],
                        fnRowCallback: function (
                        nRow,
                        aData,
                        iDisplayIndex,
                        iDisplayIndexFull
                        ) {
                        var index = iDisplayIndexFull + 1;
                        $("td:first", nRow).html(index);
                        return nRow;
                        },
                        lengthMenu: [
                        [10, 20, 30, 50, -1],
                        [10, 20, 30, 50, "All"],
                        ],
                        columnDefs: [
                        {
                            targets: 0,
                            render: function (data, type, row, meta) {
                            return type === "export" ? meta.row + 1 : data;
                            },
                        },
                        {
                            targets: [1,2,3,4,5,6,7,8,9],
                            className:"bolded"
                        }
                        ],
                    });
                    }, 1000);
                });
                    let minDate, maxDate;
                    DataTable.ext.search.push(function (settings, data, dataIndex) {
                        let min = minDate.val();
                        let max = maxDate.val();
                        let date = new Date(data[9]);
                        console.log(data);
                     
                        if (
                            (min === null && max === null) ||
                            (min === null && date <= max) ||
                            (min <= date && max === null) ||
                            (min <= date && date <= max)
                        ) {
                            return true;
                        }
                        return false;
                    });
                     
                    // Create date inputs
                    minDate = new DateTime('#min', {
                        format: 'MMMM Do YYYY'
                    });
                    maxDate = new DateTime('#max', {
                        format: 'MMMM Do YYYY'
                    });
                     
            
                    DataTable.ext.search.push(function (settings, data, dataIndex) {
                        let pref = self.state.pref;
                        let dst = data[7];
                        if (
                            (pref === null) ||
                            (pref === "") ||
                            (dst.slice(0,2) === pref)
                        ) {
                            return true;
                        }
                        return false;
                    });
                    document.querySelector('#prefix').addEventListener('change', () => {$('#myTable').DataTable().draw();
                    self.setState({sum:$('#myTable').DataTable().column( 5, {search:'applied'} ).data().sum()});});
                    // Refilter the table
                    document.querySelectorAll('#min, #max').forEach((el) => {
                        el.addEventListener('change', () => {$('#myTable').DataTable().draw();
                        self.setState({sum:$('#myTable').DataTable().column( 5, {search:'applied'} ).data().sum()});
                    
                    });
                    });

                    // var table = ;
                    self.setState({sum:$('#myTable').DataTable().column( 5 ).data().sum()});

            }
            
        }
        render(){
            const { t } = this.props;
            return(
            <div className="table-responsive text-nowrap" style={{fontFamily:"Poppins",fontSize:'1.5vh'}}>
            <div style={{display:"flex", flexDirection:'row'}}>
                <table border={0} cellSpacing={5} style={{marginBottom:'1vh'}}>
                <tbody><tr>
                    <td className="form-label" style={{ color:'black', display:'flex' , alignItems:'center',gap:'0.6vh',fontSize:"1.5vh"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: '#9d9fff'}}><path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path><path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path></svg>
                        Minimum date:</td></tr>
                    <tr>
                    <td><input type="text" id="min" name="min" className='form-control' style={{width:"10vw",fontSize:"1.5vh"}}/></td>
                    </tr>
                    <tr>
                    <td className="form-label" style={{ color:'black' , display:'flex' , alignItems:'center',gap:'0.6vh', marginTop:'1vh',fontSize:"1.5vh"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: '#9d9fff'}}><path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path><path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path></svg>
                        Maximum date:</td></tr>
                    <tr>
                    <td><input type="text" id="max" name="max" className='form-control' style={{width:"10vw",fontSize:"1.5vh"}}/></td>
                    </tr>
                </tbody></table>
                <div>
                <div style={{display:"flex", flexDirection:'column',marginLeft:'0.75vw'}}>
                    <label className="form-label" style={{ marginRight:'0.5vw' , marginBottom:'1vh' , color:'black', display:'flex' , alignItems:'center',gap:'6px',fontSize:"1.5vh"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: '#9d9fff'}}><path d="M21 4H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 11a3 3 0 0 0-3 3H7a3 3 0 0 0-3-3V9a3 3 0 0 0 3-3h10a3 3 0 0 0 3 3v6z"></path><path d="M12 8c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path></svg>
                        Spended money:</label>
                    <input type="text" disabled className='form-control' style={{width:"10vw", height:'3.5vh3.5vh',fontSize:"1.5vh"}}
                    value={`${Number(this.state.sum).toFixed()} $`}/>
                </div>
                <div style={{display:"flex",flexDirection:"column",marginLeft:'0.75vw', marginTop:'1.4vh'}}>
                <label className="form-label" htmlFor="add-team-title" style={{ marginRight:'0.25vw', marginBottom:'1vh' , color:'black', display:'flex' , alignItems:'center',gap:'6px',fontSize:"1.5vh"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" 
                style={{fill: '#9d9fff'}}><path d="m21.781 13.875-2-2.5A1 1 0 0 0 19 11h-6V9h6c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2H5a1 1 0 0 0-.781.375l-2 2.5a1.001 1.001 0 0 0 0 1.25l2 2.5A1 1 0 0 0 5 9h6v2H5c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2h6v4h2v-4h6a1 1 0 0 0 .781-.375l2-2.5a1.001 1.001 0 0 0 0-1.25zM4.281 5.5 5.48 4H19l.002 3H5.48L4.281 5.5zM18.52 16H5v-3h13.52l1.2 1.5-1.2 1.5z" /></svg>
                    From:</label>
                <input type="text" 
                    className="form-control" 
                    id="prefix" 
                    placeholder="enter from"
                    name="prefix"
                    onChange={(e)=>this.setState({pref:e.target.value})}
                    value={this.state.pref}
                    style={{width:"10vw",height:'3.5vh',fontSize:"1.5vh"}}
                    />  
                </div>
                </div>
                
                </div>
                <table id="myTable" className="table table-stripped align-items-center justify-content-center mb-0 cell-border hover compact row-border order-column stripe" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{fontSize:"1.8vh"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>{t("recordsThCompany")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>Team</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>{t("recordsThAgent")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>Tariff</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>Price</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>On line</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>{t("recordsThNumber")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>Country</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-6 ps-2" style={{fontSize:"1.8vh"}}>{t("recordsThDate")}</th>
                        </tr>
                    </thead>
                    <tbody style={{fontSize:'2vh'}}>
                    </tbody>
                </table>
            </div>
        )
        }
    }

export default withTranslation()(BillingRecords)
