import React, { useEffect, useState } from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link, Outlet } from 'react-router-dom';
import SideMenu from './SideMenu';
import NavbarHorizontal from './NavbarHorizontal';
import SideMenuHorizontal from './SideMenuHorizontal';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import secureLocalStorage from 'react-secure-storage';
import Alert from '@mui/material/Alert';
import NumPad from 'react-numpad';
// import "../css/numpad.css";
import Backspace from "@material-ui/icons/Backspace";
import CallEnd from "@material-ui/icons/CallEnd";
const Pad = ({ value, onChange, active, hangup, makeCall, status, clear }) => {
  return (
    <div className="pad">
      <div className="row">
        <input className="form-control" placeholder="Enter Number" value={value} style={{width:"400px", marginTop:"20px", fontSize:"24px", fontWeight:"bold"}} />
        {/* <button className="key">
            <Clear />
          </button> */}
      </div>
      <span className="status">{status}</span>
      <div className="row">
        <button className="key" onClick={() => onChange(1)}>
          1
        </button>
        <button className="key" onClick={() => onChange(2)}>
          2
        </button>
        <button className="key" onClick={() => onChange(3)}>
          3
        </button>
      </div>
      <div className="row">
        <button className="key" onClick={() => onChange(4)}>
          4
        </button>
        <button className="key" onClick={() => onChange(5)}>
          5
        </button>
        <button className="key" onClick={() => onChange(6)}>
          6
        </button>
      </div>
      <div className="row">
        <button className="key" onClick={() => onChange(7)}>
          7
        </button>
        <button className="key" onClick={() => onChange(8)}>
          8
        </button>
        <button className="key" onClick={() => onChange(9)}>
          9
        </button>
      </div>
      <div className="row">
        <button
          className="key-action"
          onClick={makeCall}
          style={{ color: "green" }}
        >
          {/* {active ? <CallEnd /> : <Phone />} */}
          <CallEnd />
        </button>
        <button className="key" onClick={() => onChange(0)}>
          0
        </button>
        <button className="key-action" onClick={clear}>
          <Backspace />
        </button>
      </div>
    </div>
  );
};
const Layout = () => {
  const [open, setOpen] = useState(false);
  const [suspects, setSuspects] = useState(0);
  const [number,SetNumber] = useState("");
  const { t,i18n } = useTranslation();
useEffect(()=>{
    let countdownworker;
    if(JSON.parse(secureLocalStorage.getItem('user'))?.role_id == 1)
    {
    if(typeof Worker !== undefined){
    const workerCode = `self.onmessage=function(event){
      setInterval(async ()=>{
        self.postMessage('go')
      },60000*60*2)
  }`
    const blob = new Blob([workerCode], {type:'application/javascript'});
    countdownworker = new Worker(URL.createObjectURL(blob));
    }
    countdownworker.onmessage = function (event) {
      axios.get('https://www.clearcall.tel:8078/suspectsCount')
      .then((res)=>{
          setSuspects(res.data[0].count);
          setOpen(true)
      })
      .catch((err)=>console.log(err));
    };
    countdownworker.postMessage('start')
    return () => {
      if(countdownworker)
        countdownworker.terminate();
    }
  }
  },[])
  return (
    <div className="layout-container" style={{width:"100vw",height:"100vh"}}>
       <Snackbar
        open={open}
        onClose={()=>setOpen(false)}
      >
        <Link to="/suspects">
          <Alert
            onClose={()=>setOpen(false)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {`Numbers in spam: ${suspects}`}
          </Alert>
        </Link>
      </Snackbar>
      <Navbar/>
     <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasStart" style={{width:'22.5vw',fontSize:"1.5vh"}} aria-labelledby="offcanvasStartLabel">
                <div className="offcanvas-header">
                <h5 id="offcanvasStartLabel" className="offcanvas-title">Admin panel</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <Link to="/billing/choose" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-success d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>{t("adminbilling")}</button>
                </Link>
                <Link to='billing/management/old' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-success d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Old billing management</button>
                </Link>
                <Link to="/freeswitchpanel" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-info d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Freeswitch admin panel</button>
                </Link>
                <Link to="/administration" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-warning d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Administration</button>
                </Link>
                <Link to="/gateways" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-primary d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Gateways</button>
                </Link>
                <Link to="/numbers" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-danger d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Numbers</button>
                </Link>
                <Link to="/number/verification" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-primary d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Number Verification</button>
                </Link>
                <Link to="/suspects" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-danger d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Suspected numbers</button>
                </Link>
                <Link to="/records/choose/old" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-danger d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Old journal</button>
                </Link>
                <Link to="/reports" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-info d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Daily reports</button>
                </Link>
                <Link to="/reports/prefix" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-success d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>Prefix report</button>
                </Link>
                <Link to="/gateway/prefixes" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:5}}>
                  <button type='button' className='btn btn-label-success d-grid' style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center'}}>GatewayPrefixes</button>
                </Link>


                <button type="button" className="btn btn-label-secondary d-grid" data-bs-dismiss="offcanvas" style={{width:'12.5vw',fontSize:'1.5vh', alignSelf:'center', margin:10}}>{t("cancelbtn")}</button>
        </div>
        {/* <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                      <form className="modal-content"  style={{marginLeft:"55px",backgroundColor:"#232333", borderRadius:"30px", width:"438px"}}>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{position:"absolute", marginLeft:"390px", marginTop:"20px"}}/>
                            <Pad
                              onChange={(a) => SetNumber((prev)=>`${prev}${a}`)}
                              hangup={() => console.log("hangup")}
                              makeCall={() => console.log("call")}
                              value={number}
                            />
                      </form>
                    </div>
        </div> */}
      <div className="layout-page">
    <SideMenu/>
        <div className="content-wrapper" style={{position:"absolute", top:"8vh", maxWidth:"80vw",width:"80vw", left:"15%",maxHeight:"80vh", bottom:0,right:0}}>
          <Outlet/>
          
          <div className="content-backdrop fade" style={{maxHeight:"80vh"}} />
        </div>
        
      </div>
    </div>
    // <div className='layout'>
    //   <div>
    //     <NavbarHorizontal/>
    //   </div>
    //   <div className='main__layout' >
    //       <div className='content'>
    //           <SideMenuHorizontal/>
    //           <Outlet/>
    //       </div>
    //   </div>
    //     {/* <Footer/> */}
    // </div>
  );
};


export default Layout;