import React, { Component } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {ic_phone_missed} from 'react-icons-kit/md/ic_phone_missed'
import { withBaseIcon } from 'react-icons-kit';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
const SideIconContainer = 
    withBaseIcon({ size: 32, style: {color: '#a3a5ca'}})

export const PhoneMissedIcon = () => <SideIconContainer icon={ic_phone_missed}/>
class BillingTableOld extends Component {
        company = '';
        options = [
            { value: 2, label: 'Company admin'},
            { value: 3, label: 'Office admin'},
            { value: 4, label: 'Department admin'},
            { value: 5, label: 'Team admin'},
            { value: 6, label: 'Agent'},
            { value: 7, label: 'BrandManager'},
            { value: 8, label: 'Coach'},
        ]
        options1 = [
            { value: 1, label: 'Russia'},
            { value: 2, label: 'Armenia'},
            { value: 3, label: 'Kazakhstan'},
            { value: 4, label: 'Azerbaijan'},
        ]
        options2 = []
        state = { company:1,prefix:1,inbound:"",outbound:"",operator:1,price:0.0,priceUpdate:0.0,prefixUpdate:0,status:0,
        prefixDisabled:true, countryDisabled:true,operatorDisabled:true,priceDisabled:true,idUpdate:0, 
        billingaccount_id:0,
        inputs:{
            company: false,
            prefix:false,
            inbound:false,
            outbound:false,
            operator:false,
            price:false
        }, valid:false }
        SwalDel(){
            return Swal.fire({ // <-- return Promise
                title: 'Delete record?',
                text: 'This action cannot be undone.',
                icon: 'question',
                confirmButtonText: 'Delete',
                confirmButtonColor: '#DC004E',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
                scrollbarPadding: false
                }).then((result) => {
                return result.isConfirmed;
                });
        }
        componentDidMount(){
            const self = this;
            const { t } = this.props;
            if (!$.fn.DataTable.isDataTable("#myTable")) {
                    $(document).ready(function () {
                        setTimeout(function () {
                        $("#table").DataTable({
                            pagingType: "full_numbers",
                            pageLength: 20,
                            processing: true,
                            dom: "Bfrtip",
                            paging:true,
                            select: {
                            style: "single",
                            },
                            language: {
                                "processing": t("loadingData")+'...'
                            },
                            ajax:{
                            url:'https://www.clearcall.tel:8081/billing',
                            type:'GET',
                            dataType:"json",
                            dataSrc: 'data',
                            error: function (xhr, textStatus, errorThrown) {
                                console.log(xhr);
                            }},
                            columns: [
                                { data: null,name: '#'},
                                { data: 'Title',name: 'Company'},
                                { data: 'prefix',name: 'Prefix'},
                                { data: 'inbound_country',name: 'Inbound country'},
                                // { data: 'outbound_country',name: 'Outbound country'},
                                { data: 'operator',name: 'Operator'},
                                { data: 'price',name: 'Price'},
                                { data: 'status',className:'status',name: 'Status', render: function(data,type,row){
                                    //console.log(data);
                                    return data===1?`<button  class='btn btn-label-success' disabled>&nbsp&nbsp${t("managementActive")}&nbsp</button>`:`<button  class='btn btn-label-danger' disabled>${t("managementInActive")}</button>`
                                }, orderable:false},
                                { 
                                    data: null,name: 'action',className:'update', orderable:false,
                                    render: function(data,type,row){
                                        //console.log(data);
                                        return `<button  class='btn btn-primary' data-bs-toggle="offcanvas" 
                                        data-bs-target="#offcanvasEdit" >${t("updatebtn")}</button>`
                                    },
                                },
                                // { data: null,name: 'action',className:'delete', render: function(data,type,row){
                                //     //console.log(data);
                                //     return `<button  class='btn btn-label-danger'>${t("deletebtn")}</button>`
                                // }, orderable:false}
                              
                            ],
                            bDestroy:true,
                            buttons: [
                                {
                                    extend: "copy",
                                    text:t("onlineButtonsCopy"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "print",
                                    text:t("onlineButtonsPrint"),
                                    customize: function (win) {
                                    $(win.document.body).css("font-size", "10pt");
                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css("font-size", "inherit");
                                    },
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: 'spacer',
                                    style: 'bar',
                                    text: 'Export files:'
                                },
                                {
                                    extend: "csv",
                                    text:t("onlineButtonsCSV"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "excel",
                                    text:"Excel",
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend:'pdf',
                                    text:"PDF",
                                    className: "btn btn-secondary bg-primary",
                                }
        
                                
                                ],
                
                            fnRowCallback: function (
                            nRow,
                            aData,
                            iDisplayIndex,
                            iDisplayIndexFull
                            ) {
                            var index = iDisplayIndexFull + 1;
                            $("td:first", nRow).html(index);
                            return nRow;
                            },
                
                            lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "All"],
                            ],
                            columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                                },
                            }
                            ],
                        });
                        }, 1000);
                    });
                    $('#table').on('click', 'td.update', function(e, type, action){
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log(row[0]);
                        axios.post('https://www.clearcall.tel:8081/prefixes/company',{id:row[0].company_id})
                        .then((res)=>{
                            console.log(res.data);
                            this.options2 = [];
                            res.data.forEach((element, idx) => {
                                this.options2.push({value:element.prefix_id,label:`${element.title|element.prefix}`});
                            });
                            secureLocalStorage.setItem('prefixes_update',this.options2);
                            console.log(secureLocalStorage.getItem('prefixes_update'));
                            document.getElementById('prefixupdLb').options = secureLocalStorage.getItem('prefixes_update');
                        })
                        .catch((err)=>console.log(err))
                        self.setState({
                            idUpdate:row[0].id,
                            priceUpdate:row[0].price
                        })
                    });
                    $('#table').on('click', 'td.delete', async function(e, type, action){
                        let isdel = await self.SwalDel();
                        console.log(isdel);
                        if(isdel){
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        axios.post('https://www.clearcall.tel:8081/billing/delete',{id:row[0].id})
                        .then((res)=>{
                            console.log(res);
                            Swal.fire({
                                icon:'success',
                                title: 'Result',
                                text: res.statusText,
                                customClass: {
                                confirmButton: 'btn btn-primary'
                                },
                                buttonsStyling: false
                            })
                            $('#table').DataTable().ajax.reload();
                        })
                        .catch((err)=>{
                            Swal.fire({
                            icon: 'warning',
                            title: 'Result',
                            text: err,
                            customClass: {
                            confirmButton: 'btn btn-primary'
                            },
                            buttonsStyling: false
                        })
                            console.log(err)
                        });
                    }
                    });
                    $('#table').on('click', 'td.status', function(e, type, action){
                        e.preventDefault();
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log(row[0]);
                        if(row[0].status === 0)
                        axios.post('https://www.clearcall.tel:8081/billing/changestatus',{id:row[0].id, status:1})
                        .then((res)=>{
                            console.log(res.data);
                            row.ajax.reload();
                        })
                        .catch((err)=>console.log(err))
                        else axios.post('https://www.clearcall.tel:8081/billing/changestatus',{id:row[0].id, status:0})
                        .then((res)=>{
                            console.log(res.data);
                            row.ajax.reload();
                        })
                        .catch((err)=>console.log(err))
                    });
            }
            console.log(secureLocalStorage.getItem('companies'));
            //console.log(JSON.parse(secureLocalStorage.getItem('user')).company_id);
            axios.get('https://www.clearcall.tel:8081/companies')
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.Id,label:`${element.Title}`,bid:element.bid});
                });
                secureLocalStorage.setItem('companies',this.options2);
            })
        }
        handleCompanyChoose(e){
            axios.post('https://www.clearcall.tel:8081/prefixes/company',{id:e.value})
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.prefix_id,label:`${element.title|element.prefix}`});
                });
                secureLocalStorage.setItem('prefixes',this.options2);
                console.log(secureLocalStorage.getItem('prefixes'));
                this.setState({prefixDisabled : false, company : e.value, inputs:{...this.state.inputs,company:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
            axios.post('https://www.clearcall.tel:8081/billing/account',{company_id:e.value})
            .then((res)=>{
                console.log(res);
                this.setState({billingaccount_id:res.data[0].id})
                console.log(this.state.billingaccount_id)
            })
            .catch((err)=>console.log(err))
        }
        handlePrefixChoose(e){
            axios.get('https://www.clearcall.tel:8081/countries')
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.id,label:`${element.title}`});
                });
                secureLocalStorage.setItem('countries',this.options2);
                console.log(secureLocalStorage.getItem('countries'));
                this.setState({countryDisabled : false, prefix: e.value, inputs:{...this.state.inputs,prefix:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
        }
        handleUpdatePrefixChoose(e){
                this.setState({prefixUpdate: e.value});
        }
        handleInboundChoose(e){
            axios.post('https://www.clearcall.tel:8081/operators', {country:e.value})
            .then((res)=>{
                console.log(e.value);
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.operator_id,label:`${element.num}|${element.title}|${element.country}`});
                });
                secureLocalStorage.setItem('operators',this.options2);
                console.log(secureLocalStorage.getItem('operators'));
                this.setState({operatorDisabled : false, inbound:e.value, inputs:{...this.state.inputs,inbound:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
        }
        handleOutboundChoose(e){
                this.setState({outbound:e.value, inputs:{...this.state.inputs,outbound:true}});
                this.checkValid();
        }
        handleOperatorChoose(e){
                this.setState({priceDisabled:false,operator:e.value, inputs:{...this.state.inputs,operator:true}});
                this.checkValid();
        }
        handlePriceChange(e){
            this.setState({price:parseFloat(e.target.value), inputs:{...this.state.inputs,price:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleUpdatePriceChange(e){
            this.setState({priceUpdate:parseFloat(e.target.value)});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        checkValid(){
            if(this.state.inputs.company 
                && this.state.inputs.inbound 
                && this.state.inputs.operator 
                && this.state.inputs.outbound 
                && this.state.inputs.prefix
                && this.state.inputs.price )
            this.setState({valid:true})
            else this.setState({valid:false})

        }
        handleSubmit(){
            console.log(this.state);
            axios.post('https://www.clearcall.tel:8081/billing/insert',{
                company:this.state.company,
                prefix:this.state.prefix,
                outbound:this.state.outbound,
                inbound:this.state.inbound,
                operator:this.state.operator,
                price:this.state.price,
                billingacc_id: this.state.billingaccount_id
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                $('#table').DataTable().ajax.reload();
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
              console.log(err)
            });

        }
        handleSubmitAll(){
            console.log(this.state);
            secureLocalStorage.getItem('companies').forEach((val)=>{
                if(val.value !== 1 && val.value !==12)
                axios.post('https://www.clearcall.tel:8081/billing/insert',{
                    company:val.value,
                    prefix:this.state.prefix,
                    outbound:this.state.outbound,
                    inbound:this.state.inbound,
                    operator:this.state.operator,
                    price:this.state.price,
                    billingacc_id: val.bid
                })
                .then((res)=>{
                    console.log(res);
                    Swal.fire({
                        icon:'success',
                        title: 'Result',
                        text: res.statusText,
                        customClass: {
                        confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    })
                    $('#table').DataTable().ajax.reload();
                })
                .catch((err)=>{
                  Swal.fire({
                    icon: 'warning',
                    title: 'Result',
                    text: err,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                  console.log(err)
                });
    

            })
        }
        handleUpdate(){
            //console.log(this.state);
            axios.post('https://www.clearcall.tel:8081/billing/update',{
                id:this.state.idUpdate,
                prefix:this.state.prefixUpdate,
                price:this.state.priceUpdate
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                $('#table').DataTable().ajax.reload();
            })
            .catch((err)=>{
                Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            console.log(err)
            });

            this.forceUpdate();
        }
        render(){
            const { t } = this.props;
            return(
            <div className="table-responsive text-nowrap" style={{fontFamily:"Poppins"}}>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">{t("managementRecord")}</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <label className="form-label">{t("managementThCompany")}</label>
                        <Select options={secureLocalStorage.getItem('companies')} 
                        onChange={(e)=>this.handleCompanyChoose(e)} 
                        placeholder='Select company' id='companyLb'
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663",
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                        }}/>
                        <br />
                        <label className="form-label">{t("managementThPrefix")}</label>
                        <Select options={secureLocalStorage.getItem('prefixes')} 
                        onChange={(e)=>this.handlePrefixChoose(e)}
                        placeholder='Select prefix' id='prefixLb' isDisabled={this.state.prefixDisabled}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }} />
                        <br />
                        <label className="form-label">{t("managementThInbound")}</label>
                        <Select options={secureLocalStorage.getItem('countries')} placeholder='Select inbound country'
                        onChange={(e)=>this.handleInboundChoose(e)}
                        id='incountryLb' isDisabled={this.state.countryDisabled}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}/>
                        <br />
                        <label className="form-label">{t("managementThOutbound")}</label>
                        <Select options={secureLocalStorage.getItem('countries')} placeholder='Select outbound country'
                        onChange={(e)=>this.handleOutboundChoose(e)}
                        id='outcountryLb' isDisabled={this.state.countryDisabled}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}/>
                        <br />
                        <label className="form-label">{t("managementThOperator")}</label>
                        <Select options={secureLocalStorage.getItem('operators')} placeholder='Select operator'
                        onChange={(e)=>this.handleOperatorChoose(e)}
                        id='operatorLb' isDisabled={this.state.operatorDisabled}
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}/>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">{t("managementThPrice")}</label>
                            <input type="number"
                                step="0.001"
                                min="0" 
                                className="form-control" 
                                id="add-billing-price" 
                                placeholder="0" 
                                name="billingPrice" 
                                aria-label="Billing Price"
                                disabled={this.state.priceDisabled}
                                value={this.state.price}
                                onChange={(e)=>this.handlePriceChange(e)}
                                />   
                        </div>
                        <br />
                        <button 
                            id='submitbtn'
                            type="button" 
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleSubmit()}
                            disabled={!this.state.valid}
                            >{t("submitbtn")}</button>
                        <button 
                            id='submitbtn'
                            type="button" 
                            className="btn btn-info me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleSubmitAll()}
                            disabled={!this.state.valid}
                            >SubmitAll</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEdit" aria-labelledby="offcanvasEditLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEditLabel" className="offcanvas-title">{t("managementUpdateRecord")}</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <label className="form-label">{t("managementThPrefix")}</label>
                        <Select options={secureLocalStorage.getItem('prefixes_update')} 
                        onChange={(e)=>this.handleUpdatePrefixChoose(e)}
                        placeholder='Select prefix' id='prefixupdLb'
                        styles={{
                            control:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                                borderColor:"#444663"
                            }),
                            option: (styles, { isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                };
                            },
                            singleValue:(baseStyles,state)=>({
                                ...baseStyles,
                                color:"#b8b8cd"
                            }),
                            placeholder:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd"
                            }),
                            menuList:(baseStyles,state)=>({
                                ...baseStyles,
                                background:"#444663",
                                color:"#b8b8cd",
                            }),
                        }}
                        />
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname">{t("managementThPrice")}</label>
                            <input type="number"
                                step="0.001"
                                min="0" 
                                className="form-control" 
                                id="add-billing-price" 
                                placeholder="0" 
                                name="billingPrice" 
                                aria-label="Billing Price"
                                value={this.state.priceUpdate}
                                onChange={(e)=>this.handleUpdatePriceChange(e)}
                                />   
                        </div>
                        <br />
                        <button 
                            id='updatebtn'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleUpdate()}
                            >{t("updatebtn")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary" 
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>
                    </div>
                </div>
                <button className='btn btn-primary' style={{marginBottom:5}} 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasAddUser">
                    {t("managementAdd")}
                </button>
                <table id="table" className="table table-stripped align-items-center justify-content-center mb-0 cell-border" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{fontSize:"15px"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThCompany")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThPrefix")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThInbound")}</th>
                            {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThOutbound")}</th> */}
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThOperator")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThPrice")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThStatus")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThUpdate")}</th>
                            {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"15px"}}>{t("managementThDelete")}</th> */}
                        </tr>
                    </thead>
        
                    <tbody>
                            {
                            //this.showTable()
                            }
                    </tbody>
                </table>
            </div>
        )
        }
    }
export default withTranslation()(BillingTableOld);