import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { ReadyState } from 'react-use-websocket';
import ReactApexChart from 'react-apexcharts';
import ScaleLoader from 'react-spinners/ScaleLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import axios from 'axios';
import ReactSpeedometer from "react-d3-speedometer"
import apexchart from 'apexcharts';


export default function Individual(props) {
    const { t, i18n} = useTranslation();
    const {sip, id} = useParams();
    const { from } = useLocation();

    const [callsState,setCallsState] = useState("Today");
    const [prefixes,SetPrefixes] = useState([])
    const nav = useNavigate();
    const message = useRef();
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    const totalansw = useRef(0);
    const totalnoansw = useRef(0);
    const total = useRef(0);

    const [data,SetData] = useState({
        arr : 0,
        team: 0,
        activityarr:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        avg:0
    })
    const [isLoading,SetIsLoading] = useState(true);



    const [state,setState] = useState({
        optionsActivity:{
            chart:{
                width:"50vw",
                foreColor:'#a3a5ca',
                toolbar:{
                    show:false
                },
                dropShadow: {
                    enabled: true,
                    top: 7,
                    left: 5,
                    blur: 2,
                    color: '#6060fd',
                    opacity: 0.35
                },
                id:"activity",
            },
            dataLabels: {
                enabled: false
            },
            colors:['#6060fd'],
            series:[{
                name:t("dashboardAgentsActivity"),
                data: data.activityarr
            }
            ],
            stroke: {
                curve: 'smooth'
            },
            grid:{
                xaxis:{
                    lines:{
                        show:false
                    },
                },
                yaxis:{
                    lines:{
                        show:false
                    },
                    min:-100,
                    max:100
                },
                padding:{
                    top: 0,
                    right: 10,
                    bottom: 0,
                    left: 25
                }
            },
            xaxis: {
                // categories: ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                categories: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
                axisBorder:{
                    show: false
                },
                axisTicks:{
                    show:false
                },
                labels: {
                    show: true
                }
            },
            yaxis:{
                show:true
            },
            
        }
    })
    useEffect(()=>{
            console.log(sip,id);
            axios.post("https://www.clearcall.tel:8078/agent/individual", {
                sip:sip,
                company_id:id
            })
            .then((res)=>{
                console.log(res.data);
                let array = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
                res.data.activity.forEach(element => {
                    array[element.day-1] = element.count;
                });
                SetData({arr:res.data.arr, team:res.data.team, activityarr:array,avg:res.data.avg});
                apexchart.exec("activity",'updateSeries', [{
                    name:t("dashboardAgentsActivity"),
                    data: array
            }], true)
            })
            .catch((err)=>console.log(err));
            console.log(data);
        // console.log(JSON.parse(secureLocalStorage.getItem("user")))
        //     if(id!==undefined)
        //     {


        //     axios.get('https://www.clearcall.tel:8078/companies/'+id)
        //     .then((res)=>{
        //         message.current = "Give me dashboard,"+res.data[0].Title;
        //         sendMessage(message.current);
        //         console.log(message.current);
        //         axios.post("https://www.clearcall.tel:8078/dashboard/prefix",{
        //             company:res.data[0].Title
        //         })
        //         .then((resp)=>{
        //             SetPrefixes(resp.data);
        //             console.log(resp.data);
        //             setDataD();
        //         })
        //         .catch((error)=>console.log(error))
        //     }).catch((err)=>console.log(err));
        //     }
        //     else{
        //         message.current="Give me dashboard,"+secureLocalStorage.getItem("company_title");
        //         axios.get('https://www.clearcall.tel:8078/companies/'+JSON.parse(secureLocalStorage.getItem("user")).company_id)
        //     .then((res)=>{
        //         message.current = "Give me dashboard,"+res.data[0].Title;
        //         sendMessage(message.current);
        //         console.log(message.current);
        //         axios.post("https://www.clearcall.tel:8078/dashboard/prefix",{
        //             company:res.data[0].Title
        //         })
        //         .then((resp)=>{
        //             SetPrefixes(resp.data);
        //             console.log(resp.data);
        //             setDataD();
        //         })
        //         .catch((error)=>console.log(error))
        //     }).catch((err)=>console.log(err));
        //     }
        //     setTimeout(()=>{
        //         SetIsLoading(false);
        //     },3500)
            
    },[])
    useEffect(()=>{
        // console.log(lastMessage);
        // setDataD();
    },[isLoading])
    
    
    
    if(role === 6 || role === 5 || role === 8){
        return <Navigate to="/denied" replace={true}/>
    }
    else{
        return (
            <div className="container-xxl flex-grow-1 container-p-y"
            style={{justifyContent:"start", display:"flex", flexDirection:"column", fontFamily:"Poppins", marginInline:"0"}}>
                <div className="row" style={{height:'60vh', width:'80vw'}}>
                    <div className="col-sm">
                    <div style={{display:"flex",flexDirection:"column",flexWrap:"nowrap", marginRight:"10px"}} >
                        <div className="card" style={{width:'50vw', height:'55vh', marginBottom:'1vh', fontSize:"1.6vh"}}>
                                <div className="card-title d-flex align-items-start justify-content-between">
                                <h3 className="mb-0">{t("dashboardActivity")}</h3>
                                <small style={{position:"absolute", marginLeft:"90%"}}>Month</small>
                                </div>
                                <div style={{ maxWidth:"50vw",height:"50vh",fontSize:"1.4vh",display:"flex",alignItems:'start',justifyContent:"center" }}>
                                    <ReactApexChart 
                                        options={state.optionsActivity}
                                        series={state.optionsActivity.series}
                                        labels={state.optionsActivity.xaxis.categories}
                                        height={"95%"}
                                        type='area'
                                    />
                                </div>
                        </div>
                       
                    <div className="col-sm" 
                    style={{
                        display:"flex",
                        marginLeft:0
                    }}>
                    <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                    <div className="card" style={{ width: 495, height: 280,  padding:5, marginRight:10}}>
                            <div className="card-header" style={{fontSize:'25px', fontWeight:"bold", color:"#a3a5ca"}} >Prefixes</div>
                            <div className="card-body"  >
                                <div className="table-responsive p-0 pb-2" style={{height:"150px"}}>
                                    <table id="table" className="table table-responsive-sm table-striped table-bordered align-items-center justify-content-center mb-0" 
                                    >
                                        <thead style={{backgroundColor:"#2B2C3F", borderTopWidth:"3px", borderBottomWidth:"3px",borderLeftWidth:"3px",borderRightWidth:"3px", position:"sticky", top:0}}>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2" 
                                                style={{fontSize:"18px", textAlign:"center",
                                                borderRightWidth:"3px", borderTopWidth:"3px", borderBottomWidth:"3px",borderLeftWidth:"3px", position:"sticky", top:0}}>Prefix</th>
                                                <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2" 
                                                style={{fontSize:"18px", textAlign:"center",
                                                borderTopWidth:"3px", borderBottomWidth:"3px",borderLeftWidth:"3px",borderRightWidth:"3px", position:"sticky", top:0}}>Minutes</th>
                                                {
                                                    JSON.parse(secureLocalStorage.getItem("user")).role_id!==null?
                                                    null
                                                    :<th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-3 ps-2" 
                                                style={{fontSize:"18px", textAlign:"center"
                                                , borderTopWidth:"3px", borderBottomWidth:"3px",borderLeftWidth:"3px",borderRightWidth:"3px", position:"sticky", top:0}}>Price</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody style={{borderLeftWidth:"3px",borderRightWidth:"3px",borderBottomWidth:"3px"}}>
                                            {
                                                // JSON.parse(lastMessage.data)!==null?
                                                    data.arr.length>0?
                                                    data.arr.map((element,idx) => {
                                                           return <tr key={idx}  >
                                                                <td style={{textAlign:"center", fontWeight:"bold"}}>{element.prefix}</td>
                                                                <td>{element.sum===null?0:Number(element.sum/60).toFixed()}</td>
                                                                {
                                                    JSON.parse(secureLocalStorage.getItem("user")).role_id!==null?
                                                    null
                                                    :<td></td>}
                                                            </tr>
                                                        })
                                                    :null
                                                // :<tr><td>
                                                //     <div style={{display:"flex",flexDirection:"row"}}>
                                                //     Loading...
                                                //     <BeatLoader color="#36d7b7" />
                                                // </div>
                                                //     </td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{ width: 495, height: 280,  padding:5 }}>
                        <div className="card-header" style={{fontSize:'25px',fontWeight:"bold", alignSelf:"center", height:10}}>
                                ACD
                        </div>
                        <div className="card-body" style={{display:"flex", alignContent:'center', justifyContent:'center'}}>
                            <h1 style={{fontWeight:"bold", alignSelf:"center", justifySelf:'center', color:'#fff'}}>
                                {Math.floor(data.avg)} {t("onlineSeconds")}
                            </h1>
                        </div>
                        </div>
                    </div>
                    </div> 
                    
                    </div>
                    </div>
                    <div className="col-sm">
                        <div className="card" style={{height:789}}>
                            <div className="card-header">
                                Agent
                            </div>
                            <div className="card-body">
                            <table border={0} cellSpacing={5} cellPadding={5} style={{marginBottom:10}}>
                                <tbody><tr>
                                    <td className="form-label">Login:</td>
                                    <td><input type="text" id="min" name="min" className='form-control' readOnly value={sip}/></td>
                                    </tr>
                                    <tr>
                                    <td className="form-label">Team:</td>
                                    <td><input type="text" id="max" name="max" className='form-control' readOnly value={data.team}/></td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        
}
}
