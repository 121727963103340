import React, { Component, createRef } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import ScaleLoader from 'react-spinners/ScaleLoader';
import { WaveSurferPlayer } from './AudioWaveForm';
import $ from "jquery";
import {ic_phone_missed} from 'react-icons-kit/md/ic_phone_missed'
import { withBaseIcon } from 'react-icons-kit';
import { Tooltip } from 'react-tooltip';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import moon from '../Fly Me To The Moon.mp3'
import AudioSpectrum from 'react-audio-spectrum';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../css/table.css";
import '../css/journal.css';
import ReactWaves from '@dschoon/react-waves';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'
import { Link } from 'react-router-dom';
import DataTable from 'datatables.net-dt';
import DateTime from 'datatables.net-datetime';
import MoonLoader from 'react-spinners/MoonLoader';
import moment from 'moment/moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const SideIconContainer = 
    withBaseIcon({ size: 32, style: {color: '#a3a5ca'}})


export const PhoneMissedIcon = () => <SideIconContainer icon={ic_phone_missed}/>
class Table extends Component {
        
        company = '';
        state = { audiosrc: "", filename: 'song', playing : false, playSpeed:1, brands: [], minDuration: 0, prefix:"", audioLoading:true, audioNotFound:false, downloaded: false }
        audioRef = createRef();
        componentDidMount(){
            const self = this;
            const { t , i18n , pathname, company, team, agent } = this.props; 
            //:id/:teamid/:agentid
            const companies = secureLocalStorage.getItem('brands')
            console.log(company,team,agent);
            function SetCompany (){
                if(company === undefined && JSON.parse(secureLocalStorage.getItem('user')).role_id===1) return "0"
                let str = ``;
                let obj = companies.find(el=> el.Id == company)
                str+=obj.Title;
                console.log(str.split('_').length);
                if(team!== undefined)str+=`_${team}`;
                if(agent!==undefined)str+=`_${agent}`;
                console.log(str.split('_').length);
                return str
            }    
           

        if (!$.fn.DataTable.isDataTable("#table")) {
                $(document).ready(function () {
                    setTimeout(function () {
                    $("#table").DataTable({

                        orderCellsTop: true,
                            fixedHeader: true,
                            initComplete: function () {
                                var api = this.api();
                    
                                // For each column
                                api
                                    .columns()
                                    .eq(0)
                                    .each(function (colIdx) {
                                        // Set the header cell to contain the input element
                                        //if(colIdx!==0 && colIdx!==6 && colIdx!==7 && colIdx!==8&& colIdx!==9){
                                        if(colIdx==99){
                                        var cell = $('.filters th').eq(
                                            $(api.column(colIdx).header()).index()
                                        );
                                        var title = $(cell).text();
                                        $(cell).html('<input type="text" class="searchT'+colIdx+' form-control" placeholder="' + "..." + '" />');
                    
                                        // On every keypress in this input
                                        $(
                                            'input',
                                            $('.filters th').eq($(api.column(colIdx).header()).index())
                                        )
                                            .off('keyup change')
                                            .on('change', function (e) {
                                                // Get the search value
                                                $(this).attr('title', $(this).val());
                                                var regexr = '({search})'; //$(this).parents('th').find('select').val();
                    
                                                var cursorPosition = this.selectionStart;
                                                // Search the column for that value
                                                api
                                                    .column(colIdx)
                                                    .search(
                                                        this.value != ''
                                                            ? regexr.replace('{search}', '(((' + this.value + ')))')
                                                            : '',
                                                        this.value != '',
                                                        this.value == ''
                                                    )
                                                    .draw();
                                            })
                                            .on('keyup', function (e) {
                                                e.stopPropagation();
                                                var cursorPosition = this.selectionStart;
                    
                                                $(this).trigger('change');
                                                $(this)
                                                    .focus()[0]
                                                    .setSelectionRange(cursorPosition, cursorPosition);
                                            });
                                            
                                        }
                                    });
                            },
                            deferRender:true,
                            pagingType: "full_numbers",
                            pageLength: 10,
                            processing: false,
                            searching: true,
                            //serverSide:true,
                            dom: "frtip",
                            bPaginate:true,
                            lengthChange:true,
                            lengthMenu: [
                                [10, 20, 30, 50],
                                [10, 20, 30, 50],
                                ],
                            select: {
                            style: "single",
                            },
                            language: {
                                "processing": '',
                                "search":'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #9d9fff"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path><path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z"></path></svg>'
                            },
                            createdRow:function (row,data,dataIndex) {
                              if(data.status=="ANSWER"){
                                $(row).css({"background-color":"#22c55e29"});
                                
                              }  
                              else{
                                $(row).css({"background-color":"#ffab0029"});
                              }
                            },
                            ajax:
                            company==undefined&&team==undefined&&agent==undefined?{    
                            url:'https://www.clearcall.tel:8078/records/new',
                            type:'POST',
                            data:{
                                "company_id":JSON.parse(secureLocalStorage.getItem('user')).company_id,
                                "username":JSON.parse(secureLocalStorage.getItem('user')).login
                            },
                            dataType:"json",
                            dataSrc: 'data',
                            error: function (xhr, textStatus, errorThrown) {
                                console.log(xhr);
                            }}
                            :
                            {    
                                url:'https://www.clearcall.tel:8078/records',
                                type:'POST',
                                data:{
                                    "id":SetCompany()
                                },
                                dataType:"json",
                                dataSrc: 'data',
                                error: function (xhr, textStatus, errorThrown) {
                                    console.log(xhr);
                                }},
                            columns:JSON.parse(secureLocalStorage.getItem('user')).role_id===1?[
                                { data: 'time',
                                sortable:false,
                                    orderable: false,name: 'time',
                                render: function(data){
                                    const date = new Date(data); 
                                    const formattedDate = moment.parseZone(date).local().format('YYYY-MM-DD HH:mm:ss')
                                    return formattedDate
                                }},
                                { data: 'dst',
                                sortable:false,
                                    orderable: false,name: 'dst'},
                                { data: 'duration',
                                sortable:false,
                                    orderable: false,name: 'duration',
                                render: function(data,type,row){
                                return data<3600?new Date(data * 1000).toISOString().substring(14, 19)
                                    :new Date(data * 1000).toISOString().substring(14, 19)
                                }},
                                { data: 'sip_user',
                                sortable:false,
                                    orderable: false,name: 'sip_user'},
                                {
                                    data:null,
                                    name:'record',
                                    className: 'record-show',
                                    orderable: false,
                                    sortable:false,
                                    defaultContent: '<button class="btn btn-dark" type="button" style="font-size:1.5vh">Details</button>'
                                },
                                {
                                    data:'hangup_cause',
                                    name:'hangup',
                                    orderable: false,
                                    defaultContent: `<button class="btn btn-label-dark" style="width:20px"><i class="material-icons">report_problem</i></button>`,
                                    render: function(data,type,row){
                                        //console.log(data);
                                        return data!==null?data:"Не выставлено"
                                    },
                                },
                            ]:[
                                { data: 'time',
                                sortable:false,
                                    orderable: false,name: 'time',
                                render: function(data){
                                    const date = new Date(data); 
                                    const formattedDate = moment.parseZone(date).local().format('YYYY-MM-DD HH:mm:ss')
                                    return formattedDate
                                }},
                                { data: 'dst',
                                sortable:false,
                                    orderable: false,name: 'dst'},
                                { data: 'duration',
                                sortable:false,
                                    orderable: false,name: 'duration',
                                render: function(data,type,row){
                                return data<3600?new Date(data * 1000).toISOString().substring(14, 19)
                                    :new Date(data * 1000).toISOString().substring(14, 19)
                                }},
                                { data: 'sip_user',
                                sortable:false,
                                    orderable: false,name: 'sip_user'},
                                {
                                    data:null,
                                    name:'record',
                                    className: 'record-show',
                                    orderable: false,
                                    sortable:false,
                                    defaultContent: '<button class="btn btn-dark" type="button" style="font-size:1.5vh">Details</button>'
                                }
                            ],
                            bDestroy:true,
                            buttons: [
                                {
                                    extend: "copy",
                                    text:t("onlineButtonsCopy"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "print",
                                    text:t("onlineButtonsPrint"),
                                    customize: function (win) {
                                    $(win.document.body).css("font-size", "10pt");
                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css("font-size", "inherit");
                                    },
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: 'spacer',
                                    style: 'bar',
                                    text: 'Export files:'
                                },
                                {
                                    extend: "csv",
                                    text:t("onlineButtonsCSV"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "excel",
                                    text:"Excel",
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend:'pdf',
                                    text:"PDF",
                                    className: "btn btn-secondary bg-primary",
                                }
        
                                
                            ],
                            // fnRowCallback: function (
                            // nRow,
                            // aData,
                            // iDisplayIndex,
                            // iDisplayIndexFull
                            // ) {
                            // var index = iDisplayIndexFull + 1;
                            // $("td:first", nRow).html(index);
                            // return nRow;
                            // },
                            columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                                },
                            },
                            {
                                targets: [0,1,2,3,4],
                                className: 'table-rounded-header',
                            },
                            {
                                targets: [4],
                                createdCell: function (td, cellData, rowData, row, col) {
                                      $(td).find(">:first-child").attr('id',`${row}${col}`)
                                  }
                            }
                            ],
                        });
                        }, 1000);
                    });
                    $('#table').on('click', 'td.record-show', function(e, type, action){
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        window.location.pathname = `/record/${row[0].uuid}/${row[0].time}/${row[0].dst}/${row[0].conversation}/${row[0].duration}/${row[0].sip_user}/${row[0].status}`;
                        // console.log($('#table').DataTable().cell(this).index());
                        // console.log(row[0].uuid);
                        // console.log(row[0]);
                        // console.log(e.target.closest('tr').cells);
                        // self.filename = 'song';
                        // axios.post('https://www.pdxdiamondcall.com:8081/getrecord', {filename:row[0].uuid},
                        // {responseType:'blob'})
                        // .then((res)=>{
                        //     if(res.status===400){
                        //         console.log("audio not found");
                        //         self.setState({audioNotFound:true});
                        //     }
                        //     else{
                        //     var binaryData = [];
                        //     binaryData.push(res.data)
                        //     const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
                        //     console.log(audioUrl);
                        //     self.setState({audioLoading:false});
                        //     self.setState(prevState=>{return{...prevState,audiosrc : audioUrl}});
                        //     if(document.getElementById("record")!=null)
                        //     document.getElementById("record").load();
                        //     self.setState({downloaded:true});
                        //     }
                        // })
                        // .catch((err)=>{console.log(err)});
                        
                    });
                    $('#table').on('click', 'td.record-download', function(e, type, action){
                        self.setState({downloaded:false});
                        self.setState({audioLoading:true, audioNotFound:false});
                        e.preventDefault();
                        let counter = 0;
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log($('#table').DataTable().cell(this).index());
                        console.log(row[0].uuid);
                        console.log(row[0]);
                        console.log(e.target.closest('tr').cells);
                        self.filename = 'song';
                        axios.post('https://www.clearcall.tel:8078/getrecord', {filename:row[0].uuid},
                        {responseType:'blob'})
                        .then((res)=>{
                            if(res.status===400){
                                console.log("audio not found");
                                self.setState({audioNotFound:true});
                            }
                            else{
                            var binaryData = [];
                            binaryData.push(res.data)
                            const audioUrl = URL.createObjectURL(new Blob(binaryData, {type:"audio/mp3"}));
                            console.log(audioUrl);
                            self.setState({audioLoading:false});
                            self.setState(prevState=>{return{...prevState,audiosrc : audioUrl}});
                            if(self.state.downloaded == false){
                            const link = document.createElement('a');
                            link.href = audioUrl;
                            link.setAttribute(
                              'download'
                              ,`${row[0].uuid}`,
                            );
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                            }
                            if(document.getElementById("record")!=null)
                            document.getElementById("record").load();
                            self.setState({downloaded:true});
                            }
                        })
                        .catch((err)=>{console.log(err)});
                        
                    });
 
                    $('#next').on( 'click', function () {
                        $('#table').DataTable().page( 'next' ).draw( 'page' );
                    } );
                     
                    $('#previous').DataTable().on( 'click', function () {
                        $('#table').page( 'previous' ).draw( 'page' );
                    } );

                    axios.get("https://www.clearcall.tel:8078/companies")
                        .then((res)=>{
                            var arr = [];
                            if(res.data.length>0){

                                if(JSON.parse(secureLocalStorage.getItem("user")).role_id===1){
                                res.data.forEach((item) => {
                                    arr.push({
                                        value:item.Id,
                                        label:item.Title
                                    });
                                })
                                self.setState({brands:arr});
                                }
                                else{
                                    res.data.forEach((item) => {JSON.parse(secureLocalStorage.getItem("user")).company_id !== item.Id
                                        ?console.log(item)
                                        :arr.push({
                                            value:item.Id,
                                            label:item.Title
                                        })
                                    })
                                    self.setState({brands:arr});
                                }
                            }
                        })
                        .catch((err)=>console.log(err))
            }
            
            let minDate, maxDate;
 
            // DataTable.ext.search.push(function (settings, data, dataIndex) {
            //     let min = self.state.minDuration;
            //     let duration = parseInt(data[4].split(':')[0])*60+parseInt(data[4].split(':')[1]);
            //     if (
            //         (min === null) ||
            //         (min <= duration)
            //     ) {
            //         return true;
            //     }
            //     return false;
            // });
            // document.querySelector('#prefix').addEventListener('change', () => $('#table').DataTable().draw());
            
            // DataTable.ext.search.push(function (settings, data, dataIndex) {
            //     let pref = self.state.prefix;
            //     let dst = data[3];
            //     if (
            //         (pref === null) ||
            //         (pref === "") ||
            //         (dst.slice(0,3) === pref)
            //     ) {
            //         return true;
            //     }
            //     return false;
            // });
            document.querySelector('#duration').addEventListener('change', () => $('#table').DataTable().draw());
            // Custom filtering function which will search data in column four between two values
            DataTable.ext.search.push(function (settings, data, dataIndex) {
                let min = minDate.val();
                let max = maxDate.val();
                let date = new Date(data[0]);
                console.log(data);
                if (
                    (min === null && max === null) ||
                    (min === null && date <= max) ||
                    (min <= date && max === null) ||
                    (min <= date && date <= max)
                ) {
                    return true;
                }
                return false;
            });
             
            // Create date inputs
            minDate = new DateTime('#min', {
                format: 'MMMM Do YYYY'
            });
            maxDate = new DateTime('#max', {
                format: 'MMMM Do YYYY'
            });
             
             
            // Refilter the table
            document.querySelectorAll('#min, #max').forEach((el) => {
                el.addEventListener('change', () => $('#table').DataTable().draw());
            });
            //console.log(JSON.parse(secureLocalStorage.getItem('user')).company_id);
            axios.get('https://www.clearcall.tel:8078/companies/'+JSON.parse(secureLocalStorage.getItem('user')).company_id)
            .then((res)=>{
                this.company = res.data[0].Title;
                console.log(this.company);
            })
            
        }  
        
        defaultFormatTimeCallback(seconds, pxPerSec) {
            if (seconds / 60 > 1) {
              // calculate minutes and seconds from seconds count
              const minutes = parseInt(seconds / 60, 10);
              seconds = parseInt(seconds % 60, 10);
              // fill up seconds with zeroes
              seconds = seconds < 10 ? '0' + seconds : seconds;
              return `${minutes}:${seconds}`;
            }
            return Math.round(seconds * 1000) / 1000;
          }
        
          defaultTimeInterval(pxPerSec) {
            if (pxPerSec >= 25) {
              return 1;
            } else if (pxPerSec * 5 >= 25) {
              return 5;
            } else if (pxPerSec * 15 >= 25) {
              return 15;
            }
            return Math.ceil(0.5 / pxPerSec) * 60;
          }
        
          defaultPrimaryLabelInterval(pxPerSec) {
            if (pxPerSec >= 25) {
              return 10;
            } else if (pxPerSec * 5 >= 25) {
              return 6;
            } else if (pxPerSec * 15 >= 25) {
              return 4;
            }
            return 4;
          }
        
          defaultSecondaryLabelInterval(pxPerSec) {
            if (pxPerSec >= 25) {
              return 5;
            } else if (pxPerSec * 5 >= 25) {
              return 2;
            } else if (pxPerSec * 15 >= 25) {
              return 2;
            }
            return 2;
          }
        
            render(){
                const { t, i18n } = this.props;

            return(
            <div className="table-responsive text-nowrap" width="1800px" style={{fontFamily:"Poppins", paddingTop:20, paddingRight:5,borderRadius:17,backgroundColor:"#f5f5f9", boxShadow:'none'}}>
                <div>
                    <div className="offcanvas offcanvas-top" tabIndex={-1} id="offcanvasTop" aria-labelledby="offcanvasTopLabel" style={{height:500}}>
                        <div className="offcanvas-header">
                        <h5 id="offcanvasTopLabel" className="offcanvas-title">Audio records</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"  onClick={()=>this.setState({playing: false})} />
                        </div>
                        {
                             this.state.audioNotFound
                             ?<div className="offcanvas-body" style={{justifyContent:"center",alignContent:'center', display:'flex', flexDirection:'column'}}> 
                                 <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', marginLeft:"10%"}}>
                                     <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>Audio not found. Try again later</h1>
                                 </div>
                            </div>
                            :
                            this.state.audioLoading
                            ?
                            <div className="offcanvas-body" style={{justifyContent:"center",alignContent:'center', display:'flex', flexDirection:'column'}}> 
                              <div className="card" style={{width:1400, height:500, justifyContent:'center',alignContent:'center', marginLeft:"10%"}}>
                                <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto'}}>Preparing record...</h1>
                                <MoonLoader
                                    color="#33cc66"
                                    height={270}
                                    margin={10}
                                    radius={2}
                                    speedMultiplier={0.8}
                                    width={120}
                                    cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                                </div>
                            </div>
                            :<div className="offcanvas-body" style={{justifyContent:"center",alignContent:'center', display:'flex', flexDirection:'column'}}>
                        
                        <div style={{display:"flex",flexDirection:'row', margin:10, alignContent:'center', justifyContent:'center'}}>
                        
                        <div style={{width:1000, height:200, marginTop:"-2.2%"}} >
                            {
                                this.state.playing && (
                                    <WaveSurferPlayer
                                    height={300}
                                    waveColor="#D1D6DA"
                                    progressColor="#33cc66"
                                    url={this.state.audiosrc}
                                    plugins={[Timeline.create() , Hover.create({labelColor: 'white' , labelTop:'30px',position:'absolute' , top:'50px' , labelBackground: 'black',labelSize: '11px' ,lineColor: 'black'  })]}
                                />
                                )
                            }

                       
                        </div>
                        </div>
                        <br />
                        <br />
                        
                        <div style={{display:"flex",flexDirection:"row", alignSelf:"center", justifySelf:"center", fontWeight:"bold"}}>
                           
                        <button type="button" className="btn btn-label-secondary" data-bs-dismiss="offcanvas" 
                        style={{width:150, alignSelf:'center', margin:10,height:40,display:"none", fontWeight:"bold", marginTop:75}} onClick={()=>this.setState({playing: false})}>{t("cancelbtn")}</button>
        
                        <a href={this.state.audiosrc} download className='btn btn-label-info' 
                        style={{margin:10,width:150, height:40, fontWeight:"bold",
                        position:"absolute", top:'201px', left:"150px"}}>
                            Download<i className="fa-solid fa-download" style={{marginLeft:5}}></i>
                        </a>
                        </div>
                                </div>
                        }
                        
                    </div>
                </div>
                <div style={{display:"flex",flexDirection:"row"}}>
                <table border={0} cellSpacing={5} cellPadding={5} style={{ position:"absolute", top:'0.5vh'}}>
                <tbody style={{display:"flex",flexDirection:"row"}}><tr>
                    <td className="form-label" style={{display:'flex',flexDirection:"row", alignItems:'center',fontSize:"1.5vh"}}><svg xmlns="http://www.w3.org/2000/svg" 
                    width="24" height="24" viewBox="0 0 24 24" style={{fill: '#9d9fff', marginRight:"3px", marginTop:"-3px"}}><path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path><path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path></svg>
                    Start:<input type="text" id="min" name="min" className='form-control' style={{marginLeft:"3px", borderColor:"#aaaaaa", borderRadius:3
                ,zIndex:1, width:'10vw', height:'3.4vh'}}/></td>
                    </tr>
                    <tr style={{ marginLeft:"250px"}}>
                    <td className="form-label" style={{display:'flex',flexDirection:"row", alignItems:'center',fontSize:"1.5vh"}}><svg xmlns="http://www.w3.org/2000/svg" 
                    width="24" height="24" viewBox="0 0 24 24" style={{fill: '#9d9fff', marginRight:"3px", marginTop:"-3px"}}><path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path><path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path></svg>
                    End:<input type="text" id="max" name="max" className='form-control' style={{marginLeft:"3px", borderColor:"#aaaaaa", borderRadius:3,
                zIndex:1, width:'10vw', height:'3.4vh'}}/></td>
                    </tr>
                </tbody></table>
                <div style={{display:"none",flexDirection:"column",alignItems:"start", textAlign:"center",marginLeft:10, marginTop:3}}>
                <label className="form-label" htmlFor="add-team-title" style={{marginTop:0, marginRight:5, marginBottom:18}}><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" 
                style={{fill: '#9d9fff', marginRight:"3px", marginTop:"-3px", transform: '', msfilter: ''}}><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M13 7h-2v6h6v-2h-4z"></path></svg>
                Minimal duration:</label>
                <input type="number" 
                    className="form-control" 
                    id="duration" 
                    placeholder="min duration"
                    name="teamTitle"
                    min={0} 
                    onChange={(e)=>this.setState({minDuration:e.target.value})}
                    value={this.state.minDuration}
                    style={{height:40}}
                    />  
                </div>
                <div style={{display:"none",flexDirection:"column",alignItems:"start", textAlign:"center",marginLeft:10, marginTop:3}}>
                <label className="form-label" htmlFor="add-team-title" style={{marginTop:0, marginRight:5, marginBottom:18}}>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" 
                style={{fill: '#9d9fff', marginRight:"3px", marginTop:"-3px", transform: '', msfilter: ''}}><path d="m21.781 13.875-2-2.5A1 1 0 0 0 19 11h-6V9h6c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2H5a1 1 0 0 0-.781.375l-2 2.5a1.001 1.001 0 0 0 0 1.25l2 2.5A1 1 0 0 0 5 9h6v2H5c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2h6v4h2v-4h6a1 1 0 0 0 .781-.375l2-2.5a1.001 1.001 0 0 0 0-1.25zM4.281 5.5 5.48 4H19l.002 3H5.48L4.281 5.5zM18.52 16H5v-3h13.52l1.2 1.5-1.2 1.5z" /></svg>
                From:</label>
                <input type="text" 
                    className="form-control" 
                    id="prefix" 
                    placeholder="enter from"
                    name="prefix"
                    onChange={(e)=>this.setState({prefix:e.target.value})}
                    value={this.state.prefix}
                    style={{height:40}}
                    />  
                </div>
                </div>
                
                    
                <table id="table" className="table table-stripped align-items-center justify-content-center mb-0 hover compact row-border stripe"
                style={{width:'100%', marginTop:'15vh',fontSize:"2.3vh"}}>
                    <thead  style={{width:'1400px'}} className='bolded'>
                        <tr style={{ fontWeight:"300"}}>
                            <th className="text-uppercase text-secondary text-sm  opacity-7 ps-2 table-rounded-header sorting" style={i18n.language !== 'en'?{fontSize:"1.6vh"}:{fontSize:"1.8vh"}}>Date</th>
                            <th className="text-uppercase text-secondary text-sm  opacity-7 ps-2 table-rounded-header" style={i18n.language !== 'en'?{fontSize:"1.6vh"}:{fontSize:"1.8vh"}}>To</th>
                            <th className="text-uppercase text-secondary text-sm  opacity-7 ps-2 table-rounded-header" style={i18n.language !== 'en'?{fontSize:"1.6vh"}:{fontSize:"1.8vh", width:80}}>length</th>
                            <th className="text-uppercase text-secondary text-sm  opacity-7 ps-2 table-rounded-header" style={i18n.language !== 'en'?{fontSize:"1.6vh"}:{fontSize:"1.8vh"}}>Agent</th>
                            <th className="text-uppercase text-secondary text-sm  opacity-7 ps-2 table-rounded-header" style={i18n.language !== 'en'?{fontSize:"1.6vh"}:{fontSize:"1.8vh"}}></th>
                            {
                                JSON.parse(secureLocalStorage.getItem('user')).role_id===1?
                                <th className="text-uppercase text-secondary text-sm  opacity-7 ps-2 table-rounded-header" style={i18n.language !== 'en'?{fontSize:"1.6vh"}:{fontSize:"1.8vh"}}>HangupCause</th>
                                :null
                            }
                        </tr>
                        {/* <tr className='filters'>
                            <th style={{fontSize:"18px"}} className='filters'></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px", width:80}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px", width:80}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2 filters" style={{fontSize:"18px"}} ></th>
                        </tr> */}
                    </thead>
        
                    <tbody style={{width:'1400px', color:'#000', fontSize:"2.3vh"}}>
                            {
                            //this.showTable()
                            }
                    </tbody>
                </table>
            </div>
        )
        }
    }
export default withTranslation()(Table)