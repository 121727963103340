import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import BillingTableOld from './BillingTableOld';
import axios from 'axios';
import BillingRecords from './BillingRecords';

export default function BillingManagementOld() {
    const [state, setState] = useState({
        optionsBalance:{
            chart:{
                foreColor:'#60628b',
                toolbar:{
                    show:false
                },
                dropShadow: {
                    enabled: true,
                    top: 7,
                    left: 5,
                    blur: 2,
                    color: '#df972d',
                    opacity: 0.35
                }
            },
            dataLabels: {
                enabled: false
            },
            colors:['#df972d'],
            series:[{
                data:[10000,7200,4100,12000,8000,3000,150,15000,12000,9500,6350,3750],
                name:"Balance"},
            ],
            stroke: {
                curve: 'smooth'
            },
            grid:{
                xaxis:{
                    lines:{
                        show:false
                    }
                },
                yaxis:{
                    lines:{
                        show:false
                    }
                },
                padding:{
                    top: 0,
                    right: 10,
                    bottom: 0,
                    left: 25
                }
            },
            markers:{
                discrete: [{
                    seriesIndex: 0,
                    dataPointIndex: 11,
                    fillColor: '#fff',
                    strokeColor: '#df972d',
                    size: 7,
                    shape: "circle"
                }]
            },
            xaxis: {
                categories: ["January","February","March","April","May","June","July","August","September","October","November","December"],
                axisBorder:{
                    show: false
                },
                axisTicks:{
                    show:false
                }
            },
            yaxis:{
                show:false
            },
            
        }
        })

    const renderData = () => {
        return <BillingTableOld/>
    }
    
    useEffect(()=>{
        

        let timer;
        
        
        timer = setInterval(()=>{
            axios.post('https://www.clearcall.tel:8081/billing/balance', {
                id:JSON.parse(secureLocalStorage.getItem('user')).billingaccount_id
            })
            .then((res)=>{
                secureLocalStorage.setItem("balance", res.data[0].balance);
            })
            .catch((err)=>console.log(err));

        },10000)  
        return ()=>{
            clearInterval(timer);
        }
    },[])
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role !== 1){
        return <Navigate to="/denied" replace={true}/>
    }
    else
    return (
    <div className='container-xxl flex-grow-1 container-p-y' style={{fontFamily:"Poppins"}}>
        {
            JSON.parse(secureLocalStorage.getItem('user')).role_id===1
            ?
            <div className="card flex-grow-1" style={{width:1400, padding:10, marginTop:10}}>
            <div className="card-body" style={{position: 'relative'}}>
                {
                    renderData()
                }
            </div>
            </div>
            :null
        }
    </div>
)
}