import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export default function FreeswitchPanel() {

  const { t,i18n } = useTranslation();
    const [apicom1,setApi1] = useState("");
    const [apicom2,setApi2] = useState("");
    const [bgapicom1,setBgApi1] = useState("");
    const [bgapicom2,setBgApi2] = useState("");
    const [output,setOutput] = useState("");
  function handleReload(){
    axios.post("https://www.clearcall.tel:8078/reloadxml")
    .then((res)=>console.log(res))
    .catch((err)=>console.log(err))
}
function handleReloadAcl(){
    axios.post("https://www.clearcall.tel:8078/reloadacl")
    .then((res)=>console.log(res))
    .catch((err)=>console.log(err))
}
function handleReloadProfile1(){
    axios.post("https://www.clearcall.tel:8078/reloadserverone")
    .then((res)=>console.log(res))
    .catch((err)=>console.log(err))
}
function handleReloadProfile2(){
    axios.post("https://www.clearcall.tel:8078/reloadservertwo")
    .then((res)=>console.log(res))
    .catch((err)=>console.log(err))
}
    const sendApi1=()=>{
      console.log(apicom1);
      axios.post("https://www.clearcall.tel:8078/freeswitch/sendApi1",{
        command:apicom1
      })
      .then((res)=>setOutput("Server #1\nApi:"+res.data))
      .catch((err)=>setOutput(err))
    }
    const sendApi2=()=>{
      axios.post("https://www.clearcall.tel:8078/freeswitch/sendApi2",{
        command:apicom2
      })
      .then((res)=>setOutput("Server #2\nApi:"+res.data))
      .catch((err)=>setOutput(err))
    }
    const sendBgApi1=()=>{
      axios.post("https://www.clearcall.tel:8078/freeswitch/sendBgApi1",{
        command:bgapicom1
      })
      .then((res)=>setOutput("Server #2\nBgApi:"+res.data))
      .catch((err)=>setOutput(err))
    }
    const sendBgApi2=()=>{
      axios.post("https://www.clearcall.tel:8078/freeswitch/sendBgApi2",{
        command:bgapicom2
      })
      .then((res)=>setOutput("Server #2\nBgApi:"+res.data))
      .catch((err)=>setOutput(err))
    }
  let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
  if(role !== 1){
      return <Navigate to="/denied" replace={true}/>
  }
  else
  return (
    <div className='container-xxl flex-grow-1 container-p-y' style={{fontFamily:"Poppins",marginInline:0}}>
      <div className="card" style={{width:"70vw"}}>
        <div className="card-header">
          <label className="form-label" style={{fontWeight:"bold",fontSize:"2.5vh"}}>Freeswitch commands</label>
          <br /><br />
          <div style={{width:'60vw', display:'flex'}}>
              <div style={{width:'42vw', marginLeft:"20%"}}>
                  <button className='btn btn-label-primary' style={{width:'10vw', margin:'0.25vw',fontSize:"1.5vh"}} onClick={()=>handleReload()}>{t("adminxml")}</button>
                  <button className='btn btn-label-primary' style={{width:'10vw', margin:'0.25vw',fontSize:"1.5vh"}} onClick={()=>handleReloadAcl()}>{t("adminacl")}</button>
                  <button className='btn btn-label-primary' style={{width:'10vw', margin:'0.25vw',fontSize:"1.5vh"}} onClick={()=>handleReloadProfile1()}>{t("adminprofilesone")}</button>
                  <button className='btn btn-label-primary' style={{width:'10vw', margin:'0.25vw',fontSize:"1.5vh"}} onClick={()=>handleReloadProfile2()}>{t("adminprofilestwo")}</button>
              </div>
          </div>
        </div>
        <div className='card-body' style={{fontSize:"1.5vh"}}>
          <div style={{display:"flex",flexDirection:'column'}}>
            <div>
              <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{fontSize:"1.5vh"}}>Command output</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows={20} defaultValue={""} readOnly={true} 
              value={output} style={{fontSize:"1.5vh"}} />
            </div>
            <br />
            <div style={{display:"flex",flexDirection:'row', justifyContent:"space-between"}}>
              <div style={{display:"flex",flexDirection:'row'}}>
                <div class="mb-3" >
                <label for="defaultFormControlInput" class="form-label" style={{fontSize:"1.5vh"}}>Command</label>
                <input type="text" class="form-control" id="defaultFormControlInput" placeholder="sofia status" 
                aria-describedby="defaultFormControlHelp" style={{width:'17.5vw',fontSize:"1.5vh"}} onChange={(e)=>setApi1(e.target.value)}/>
                </div>
                <button className='btn btn-label-success' style={{height:'4vh', margin:'0.5vw', width:'11vw', marginTop:"2.8vh",fontSize:"1.5vh"}}
                disabled={apicom1===""?true:false} onClick={()=>sendApi1()}>Submit to api server #1</button>
              </div>
              <div style={{display:"flex",flexDirection:'row'}}>
                <div class="mb-3" >
                <label for="defaultFormControlInput" class="form-label" style={{fontSize:"1.5vh"}}>Command</label>
                <input type="text" class="form-control" id="defaultFormControlInput" placeholder="show calls" 
                aria-describedby="defaultFormControlHelp" style={{width:'17.5vw',fontSize:"1.5vh"}} onChange={(e)=>setApi2(e.target.value)}/>
                </div>
                <button className='btn btn-label-success' style={{height:'4vh', margin:'0.5vw', width:'11vw', marginTop:"2.8vh",fontSize:"1.5vh"}}
                disabled={apicom2===""?true:false} onClick={()=>sendApi2()}>Submit to api server #2</button>
              </div>
            </div>
            <div style={{display:"flex",flexDirection:'row', justifyContent:"space-between"}}>
              <div style={{display:"flex",flexDirection:'row'}}>
                <div class="mb-3" >
                <label for="defaultFormControlInput" class="form-label" style={{fontSize:"1.5vh"}}>Command</label>
                <input type="text" class="form-control" id="defaultFormControlInput" placeholder="sofia gateway_restart <gateway_name>" 
                aria-describedby="defaultFormControlHelp" style={{width:'17.5vw',fontSize:"1.5vh"}} onChange={(e)=>setBgApi1(e.target.value)}/>
                </div>
                <button className='btn btn-label-success' style={{height:'4vh', margin:'0.5vw', width:'11vw', marginTop:"2.8vh",fontSize:"1.5vh"}}
                disabled={bgapicom1===""?true:false} onClick={()=>sendBgApi1()}>Submit to bgapi server #1</button>
              </div>
              <div style={{display:"flex",flexDirection:'row'}}>
                <div class="mb-3" >
                <label for="defaultFormControlInput" class="form-label" style={{fontSize:"1.5vh"}}>Command</label>
                <input type="text" class="form-control" id="defaultFormControlInput" placeholder="sofia gateway_shutdown <gateway_name>" 
                aria-describedby="defaultFormControlHelp" style={{width:'17.5vw',fontSize:"1.5vh"}} onChange={(e)=>setBgApi2(e.target.value)} />
                </div>
                <button className='btn btn-label-success' style={{height:'4vh', margin:'0.5vw', width:'11vw', marginTop:"2.8vh",fontSize:"1.5vh"}}
                disabled={bgapicom2===""?true:false} onClick={()=>sendBgApi2()}>Submit to bgapi server #2</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
