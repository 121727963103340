import React, {useState,useEffect, useRef} from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Navigate } from 'react-router-dom';
import MoonLoader from 'react-spinners/MoonLoader';
import OnlineTable from './OnlineTable';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { ReadyState } from 'react-use-websocket';

export default function OnlineCalls() {
    const {t,i18n} = useTranslation();
    const [isPageActive,setActive] = useState(true);
    const [socketUrl] = useState('wss://www.clearcall.tel:8078/echo');
    const [data,SetData] = useState([]);
    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {
        onMessage: (event)=>{
            try {
                SetData(event.data);
                SetTable(renderData(JSON.parse(event.data).tp,JSON.parse(event.data).tp1))
            } catch (error) {
                console.log(error)
            }
        },
        shouldReconnect: (closeEvent) => true,
        onClose:(event)=>{
        },
        onReconnectStop:(event)=>{
            // sendMessage("Give me calls");
        },
        onError:(event)=>{
            // sendMessage("Give me calls");
        },
        reconnectAttempts:10,
        reconnectInterval:10000,
    });
    const renderData = (message, message1) => {
        return (message.length === 0 && message1.length === 0)
        ?
        <div className='container-xxl flex-grow-1 container-p-y' style={{fontFamily:"Poppins"}}>
                <div className="card" style={{width:1400, height:800, justifyContent:'center',alignContent:'center', backgroundColor:"#f5f5f9", boxShadow:'none'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto',}}>Loading...</h1>
                    <MoonLoader
                        color="#33cc66"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
        :
        <OnlineTable t={t} names={message} names1={message1}/>
    }
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];
    const [table,SetTable] = useState();
    useEffect(()=>{
        sendMessage(`${JSON.parse(secureLocalStorage.getItem('user')).id},Give me calls`);
        window.addEventListener('focus', function () {
            console.log('focused')
            sendMessage(`${JSON.parse(secureLocalStorage.getItem('user')).id},Give me calls`)
            setActive(true);
        });
    },[])
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role === 6 ){
        return <Navigate to="/denied" replace={true}/>
    }
    else if(lastMessage === null) {
        return <div className='container-xxl flex-grow-1 container-p-y' style={{fontFamily:"Poppins"}}>
                <div className="card" style={{width:1400, height:800, justifyContent:'center',alignContent:'center', backgroundColor:"#f5f5f9", boxShadow:'none'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto',}}>Loading...</h1>
                    <MoonLoader
                        color="#33cc66"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
    }
    else if(lastMessage.data!== undefined && JSON.parse(lastMessage?.data)?.tp.length === 0 && JSON.parse(lastMessage?.data)?.tp1.length === 0){
        //console.log('tp',JSON.parse(lastMessage.data.tp));
        return <div className='container-xxl flex-grow-1 container-p-y'style={{fontFamily:"Poppins"}}>
                <div className="card" style={{width:1400, height:800, justifyContent:'center',alignContent:'center', backgroundColor:"#f5f5f9", boxShadow:'none'}}>
                    <h1 style={{display:'block',marginLeft:'auto',marginRight:'auto',}}>Loading...</h1>
                    <MoonLoader
                        color="#33cc66"
                        height={270}
                        margin={10}
                        radius={2}
                        speedMultiplier={0.8}
                        width={80}
                        cssOverride={{display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                </div>
            </div>
    }

    else{ 
    return (
        <div className='container-xxl flex-grow-1 container-p-y' style={{fontFamily:"Poppins", backgroundColor:"#f5f5f9",maxWidth:"70vw", boxShadow:'none'}}>   
        <div className="card flex-grow-1" style={{width:"70vw",backgroundColor:"#f5f5f9", boxShadow:'none', border:"3px solid lightgray",
        borderRadius:17, paddingBottom:5,fontSize:"1.5vh"}}>
            <div className="card-header" style={{display:'none'}}>
                <div className="row" style={{display:'none', flexDirection:'row', justifyContent:'space-between'}}>
                    <h5 style={{fontSize:"2.5vh", fontWeight:"bold"}}>On line</h5>
                </div>
            </div>
            {
                        table
            }  
        </div>
    </div>
)
        }

}
