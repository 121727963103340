import React, {useState,useEffect} from 'react'
import { ReadyState } from 'react-use-websocket';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Navigate, useParams } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader'
import Table from './Table';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import '../css/journal.css';
import TableOld from './TableOld';

export default function JournalOld() {
    const { t,i18n } = useTranslation();
    const {company} = useParams()
    const {team} = useParams()
    const {agent} = useParams()


    
    useEffect(()=>{
    },[])
    const renderData = () => {
        return <TableOld t={t} i18n={i18n} company={company} team={team} agent={agent}/>
    }
    let role = JSON.parse(secureLocalStorage.getItem('user')).role_id;
    if(role === 6 ){
        return <Navigate to="/denied" replace={true}/>
    }
    else {
    return (
        
        <div className='container-xxl flex-grow-1 container-p-y' style={{fontFamily:"Poppins", backgroundColor:"#f5f5f9", boxShadow:'none'}}>   
            <div className="card flex-grow-1" style={{width:1400,backgroundColor:"#f5f5f9", boxShadow:'none', border:"3px solid lightgray",
            borderRadius:17, paddingBottom:5}}>
            <div className="card-header" style={{display:"none"}}>
                <div className="row" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <h5 style={{fontSize:"25px", fontWeight:"bold"}}>Audio records</h5>
                </div>
            </div>
            {
                renderData()
            }   
                
            </div>
        </div>
    );
        }
}

