import React, { Component } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {ic_phone_missed} from 'react-icons-kit/md/ic_phone_missed'
import { withBaseIcon } from 'react-icons-kit';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
const SideIconContainer = 
    withBaseIcon({ size: 32, style: {color: '#a3a5ca'}})

export const PhoneMissedIcon = () => <SideIconContainer icon={ic_phone_missed}/>
class GatewayTable extends Component {
        company = '';
        options = [
            { value: 2, label: 'Company admin'},
            { value: 3, label: 'Office admin'},
            { value: 4, label: 'Department admin'},
            { value: 5, label: 'Team admin'},
            { value: 6, label: 'Agent'},
            { value: 7, label: 'BrandManager'},
            { value: 8, label: 'Coach'},
        ]
        options1 = [
            { value: 1, label: 'Russia'},
            { value: 2, label: 'Armenia'},
            { value: 3, label: 'Kazakhstan'},
            { value: 4, label: 'Azerbaijan'},
        ]
        options2 = []
        state = { provider:0,providerUpdate:0,company:1,prefix:1,inbound:"",outbound:"",operator:1,title:"",titleUpdate:"",prefixUpdate:0,status:0, calee_name:0,calee_num:0,calee_name_update:0,calee_num_update:0,
        prefixDisabled:true, titleDisabled:true,idUpdate:0, domain:"",phone_use:"",phone_use_update:"",domain_update:"",params:"",params_update:"",prefixes:[],
        billingaccount_id:0, additional_prefix:"",additional_prefixUpdate:"",additional_prefix_disabled:true,additional_prefix_disabled_add:false,titlemask:"",titlemask_update:"",
        inputs:{
            prefix:false,
            title:false,
            domain:false,
            phone_use:false,
            params:false
        }, valid:false }
        SwalDel(){
            return Swal.fire({ // <-- return Promise
                title: 'Delete record?',
                text: 'This action cannot be undone.',
                icon: 'question',
                confirmButtonText: 'Delete',
                confirmButtonColor: '#DC004E',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
                scrollbarPadding: false
                }).then((result) => {
                return result.isConfirmed;
                });
        }
        componentDidMount(){
            axios.get('https://www.clearcall.tel:8078/prefixes')
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                this.setState({prefixes:res.data});
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.id,label:`${element.title|element.prefix}`});
                });
                secureLocalStorage.setItem('prefixes',this.options2);
                console.log(secureLocalStorage.getItem('prefixes'));
                this.setState({prefixDisabled : false, inputs:{...this.state.inputs,company:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
            const self = this;
            const { t } = this.props;
            if (!$.fn.DataTable.isDataTable("#myTable")) {
                    $(document).ready(function () {
                        setTimeout(function () {
                        $("#table").DataTable({
                            pagingType: "full_numbers",
                            pageLength: 20,
                            processing: true,
                            dom: "Bfrtip",
                            paging:true,
                            select: {
                            style: "single",
                            },
                            language: {
                                "processing": t("loadingData")+'...'
                            },
                            ajax:{
                            url:'https://www.clearcall.tel:8078/gateways',
                            type:'GET',
                            dataType:"json",
                            dataSrc: 'data',
                            error: function (xhr, textStatus, errorThrown) {
                                console.log(xhr);
                            }},
                            columns: [
                                { data: null,name: '#'},
                                { data: 'title',name: 'Title'},
                                //{ data: 'title_prefix',name: 'Prefix_Title'},
                                //{ data: 'prefix',name: 'Prefix'},
                                { data: 'domain',name: 'Domain'},
                                { data: 'phone_use',name: 'Phone_use',
                                render: function(data,type,row){
                                    //console.log(data);
                                    if(data===1)
                                    return 'true'
                                    else return 'false'
                                },},
                                { 
                                    data: 'params',name: 'params',
                                    render: function(data,type,row){
                                        //console.log(data);
                                        return `<textarea class='form-control' rows='5' style='font-size:1.5vh' readonly='true'>${data}</textarea>`
                                    },
                                },
                                // { data: 'outbound_country',name: 'Outbound country'},
                                // { data: 'operator',name: 'Operator'},
                                // { data: 'price',name: 'Price'},
                                // { data: 'status',className:'status',name: 'Status', render: function(data,type,row){
                                //     //console.log(data);
                                //     return data===1?`<button  class='btn btn-label-success' disabled>&nbsp&nbsp${t("managementActive")}&nbsp</button>`:`<button  class='btn btn-label-danger' disabled>${t("managementInActive")}</button>`
                                // }, orderable:false},
                                { 
                                    data: null,name: 'action',className:'update', orderable:false,
                                    render: function(data,type,row){
                                        //console.log(data);
                                        return `<button  class='btn btn-label-warning' data-bs-toggle="offcanvas" 
                                        data-bs-target="#offcanvasEdit"  style="font-size:1.5vh">${t("updatebtn")}</button>`
                                    },
                                },
                                { data: null,name: 'action',className:'delete', render: function(data,type,row){
                                    //console.log(data);
                                    return `<button  class='btn btn-label-danger' style="font-size:1.5vh">${t("deletebtn")}</button>`
                                }, orderable:false}
                              
                            ],
                            bDestroy:true,
                            buttons: [
                                {
                                    extend: "copy",
                                    text:t("onlineButtonsCopy"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "print",
                                    text:t("onlineButtonsPrint"),
                                    customize: function (win) {
                                    $(win.document.body).css("font-size", "10pt");
                                    $(win.document.body)
                                        .find("table")
                                        .addClass("compact")
                                        .css("font-size", "inherit");
                                    },
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: 'spacer',
                                    style: 'bar',
                                    text: 'Export files:'
                                },
                                {
                                    extend: "csv",
                                    text:t("onlineButtonsCSV"),
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend: "excel",
                                    text:"Excel",
                                    className: "btn btn-secondary bg-primary",
                                },
                                {
                                    extend:'pdf',
                                    text:"PDF",
                                    className: "btn btn-secondary bg-primary",
                                }
        
                                
                                ],
                
                            fnRowCallback: function (
                            nRow,
                            aData,
                            iDisplayIndex,
                            iDisplayIndexFull
                            ) {
                            var index = iDisplayIndexFull + 1;
                            $("td:first", nRow).html(index);
                            return nRow;
                            },
                
                            lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "All"],
                            ],
                            columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                                },
                            }
                            ],
                        });
                        }, 1000);
                    });
                    $('#table').on('click', 'td.update', function(e, type, action){
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log(row[0]);
                        
                        axios.get('https://www.clearcall.tel:8078/prefixes')
                        .then((res)=>{
                            console.log(res.data);
                            axios.post("https://www.clearcall.tel:8078/gateway/prefixes",
                            {gateway:row[0].id})
                            .then((ress)=>{
                            console.log(ress);
                            res.data.forEach(element => {
                                    document.getElementById(`${element.id}_upd`).checked = false;
                            });
                            res.data.forEach(element => {
                                ress.data.forEach(el=>{
                                if(el.prefix_id === element.id){
                                    document.getElementById(`${element.id}_upd`).checked = true;
                                    console.log(document.getElementById(`${element.id}_upd`));
                                }
                                })
                            });
                            })
                            .catch((err)=>console.log(err));
                        })
                        .catch((err)=>console.log(err))
                        self.setState({
                            idUpdate:row[0].id,
                            titleUpdate:row[0].title,
                            domain_update:row[0].domain,
                            phone_use_update:row[0].phone_use,
                            params_update:row[0].params,
                            calee_name_update:row[0].calee_name,
                            calee_num_update:row[0].calee_num,
                            providerUpdate:row[0].IsProvider,
                            additional_prefixUpdate:row[0].additional_prefix?row[0].additional_prefix:"",
                            additional_prefix_disabled:true,
                            additional_prefix_disabled_add:false,
                            titlemask_update:row[0].titlemask,
                            
                        })
                    });
                    $('#table').on('click', 'td.delete', async function(e, type, action){
                        let isdel = await self.SwalDel();
                        console.log(isdel);
                        if(isdel){
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        axios.post('https://www.clearcall.tel:8078/gateway/delete',{id:row[0].id})
                        .then((res)=>{
                            console.log(res);
                            Swal.fire({
                                icon:'success',
                                title: 'Result',
                                text: res.statusText,
                                customClass: {
                                confirmButton: 'btn btn-primary'
                                },
                                buttonsStyling: false
                            })
                            $('#table').DataTable().ajax.reload();
                        })
                        .catch((err)=>{
                            Swal.fire({
                            icon: 'warning',
                            title: 'Result',
                            text: err,
                            customClass: {
                            confirmButton: 'btn btn-primary'
                            },
                            buttonsStyling: false
                        })
                            console.log(err)
                        });
                    }
                    });
                    $('#table').on('click', 'td.status', function(e, type, action){
                        e.preventDefault();
                        var row = $('#table').DataTable().rows($('#table').DataTable().cell(this).index().row).data();
                        console.log(row[0]);
                        if(row[0].status === 0)
                        axios.post('https://www.clearcall.tel:8078/billing/changestatus',{id:row[0].id, status:1})
                        .then((res)=>{
                            console.log(res.data);
                            row.ajax.reload();
                        })
                        .catch((err)=>console.log(err))
                        else axios.post('https://www.clearcall.tel:8078/billing/changestatus',{id:row[0].id, status:0})
                        .then((res)=>{
                            console.log(res.data);
                            row.ajax.reload();
                        })
                        .catch((err)=>console.log(err))
                    });
            }
            console.log(secureLocalStorage.getItem('companies'));
            //console.log(JSON.parse(secureLocalStorage.getItem('user')).company_id);
            axios.get('https://www.clearcall.tel:8078/companies')
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.Id,label:`${element.Title}`,bid:element.bid});
                });
                secureLocalStorage.setItem('companies',this.options2);
            })

        }
        handleCompanyChoose(e){
            axios.post('https://www.clearcall.tel:8078/prefixes',{id:e.value})
            .then((res)=>{
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.prefix_id,label:`${element.title|element.prefix}`});
                });
                secureLocalStorage.setItem('prefixes',this.options2);
                console.log(secureLocalStorage.getItem('prefixes'));
                this.setState({prefixDisabled : false, company : e.value, inputs:{...this.state.inputs,company:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
            axios.post('https://www.clearcall.tel:8078/billing/account',{company_id:e.value})
            .then((res)=>{
                console.log(res);
                this.setState({billingaccount_id:res.data[0].id})
                console.log(this.state.billingaccount_id)
            })
            .catch((err)=>console.log(err))
        }
        handleAddPrefixChoose(e){
            console.log(e);
                this.setState({titleDisabled:false,additional_prefix: e.target.value, inputs:{...this.state.inputs,additional_prefix:true}});
                this.checkValid();
        }
        handleUpdateAddPrefixChoose(e){
                this.setState({titleDisabled:false,additional_prefixUpdate: e.target.value});
        }
        handlePrefixChoose(e){
            console.log(e);
                this.setState({titleDisabled:false,prefix: e.value, inputs:{...this.state.inputs,prefix:true}});
                this.checkValid();
        }
        handleUpdatePrefixChoose(e){
                this.setState({titleDisabled:false,prefixUpdate: e.value});
        }
        handlePhoneChoose(e){
            console.log(e);
                this.setState({phone_use: e.target.checked});
                console.log(this.state);
        }
        handleUpdatePhoneChoose(e){
                this.setState({phone_use_update: e.target.checked});
        }
        handleCaleeNameChoose(e){
                this.setState({calee_name: e.target.checked});
                console.log(this.state);
        }
        handleAddPrefDisabledChoose(e){
                this.setState({additional_prefix_disabled: e.target.checked});
                console.log(this.state);
        }
        handleAddPrefDisabledChooseAdd(e){
                this.setState({additional_prefix_disabled_add: e.target.checked});
                console.log(this.state);
        }
        handleUpdateCaleeNameChoose(e){
                this.setState({calee_name_update: e.target.checked});
        }
        handleCaleeNumChoose(e){
                this.setState({calee_num: e.target.checked});
                console.log(this.state);
        }
        handleUpdateCaleeNumChoose(e){
                this.setState({calee_num_update: e.target.checked});
        }
        handleProviderChoose(e){
                this.setState({provider: e.target.checked});
                console.log(this.state);
        }
        handleUpdateProviderChoose(e){
                this.setState({providerUpdate: e.target.checked});
        }
        handleDomainChoose(e){
            console.log(e);
                this.setState({domain:e.target.value});
        }
        handleUpdateDomainChoose(e){
                this.setState({domain_update:e.target.value});
        }
        handleInboundChoose(e){
            axios.post('https://www.clearcall.tel:8078/operators', {country:e.value})
            .then((res)=>{
                console.log(e.value);
                console.log(res.data);
                this.options2 = [];
                res.data.forEach((element, idx) => {
                    this.options2.push({value:element.operator_id,label:`${element.num}|${element.title}|${element.country}`});
                });
                secureLocalStorage.setItem('operators',this.options2);
                console.log(secureLocalStorage.getItem('operators'));
                this.setState({operatorDisabled : false, inbound:e.value, inputs:{...this.state.inputs,inbound:true}});
                this.checkValid();
            })
            .catch((err)=>console.log(err))
        }
        handleOutboundChoose(e){
                this.setState({outbound:e.value, inputs:{...this.state.inputs,outbound:true}});
                this.checkValid();
        }
        handleOperatorChoose(e){
                this.setState({priceDisabled:false,operator:e.value, inputs:{...this.state.inputs,operator:true}});
                this.checkValid();
        }
        handlePriceChange(e){
            this.setState({title:e.target.value, inputs:{...this.state.inputs,title:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handlePriceMaskChange(e){
            this.setState({titlemask:e.target.value, inputs:{...this.state.inputs,titlemask:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleParamsChange(e){
            this.setState({params:e.target.value, inputs:{...this.state.inputs,params:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleUpdateParamsChange(e){
            console.log(e.target.value);
            this.setState({params_update:e.target.value});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleUpdatePriceMaskChange(e){
            this.setState({titlemask_update:e.target.value, inputs:{...this.state.inputs,titlemask_update:true}});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        handleUpdatePriceChange(e){
            this.setState({titleUpdate:e.target.value});
            this.checkValid();
            //console.log(this.state.inputs);
        }
        checkValid(){
            if(this.state.inputs.title)
            this.setState({valid:true})
            else this.setState({valid:false})

        }
        handleSubmit(){
            var arr = [];
              this.state.prefixes.forEach(element => {
                arr.push({
                  id:element.id,
                  access:document.getElementById(element.id).checked
                });
              });
            console.log(this.state);
            axios.post('https://www.clearcall.tel:8078/gateway',{
                prefixes:arr,
                title:this.state.title,
                phone_use:this.state.phone_use,
                domain:this.state.domain,
                params:this.state.params,
                calee_num:this.state.calee_num,
                calee_name:this.state.calee_name,
                provider:this.state.provider,
                additional_prefix:this.state.additional_prefix,
                titlemask:this.state.titlemask
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                $('#table').DataTable().ajax.reload();
            })
            .catch((err)=>{
              Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
              console.log(err)
            });

        }
        handleSubmitAll(){
            console.log(this.state);
            secureLocalStorage.getItem('companies').forEach((val)=>{
                if(val.value !== 1 && val.value !==12)
                axios.post('https://www.clearcall.tel:8078/billing/insert',{
                    company:val.value,
                    prefix:this.state.prefix,
                    outbound:this.state.outbound,
                    inbound:this.state.inbound,
                    operator:this.state.operator,
                    price:this.state.price,
                    billingacc_id: val.bid
                })
                .then((res)=>{
                    console.log(res);
                    Swal.fire({
                        icon:'success',
                        title: 'Result',
                        text: res.statusText,
                        customClass: {
                        confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    })
                    $('#table').DataTable().ajax.reload();
                })
                .catch((err)=>{
                  Swal.fire({
                    icon: 'warning',
                    title: 'Result',
                    text: err,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                  console.log(err)
                });
    

            })
        }
        handleUpdate(){
            var arr = [];
            this.state.prefixes.forEach(element => {
              arr.push({
                id:element.id,
                access:document.getElementById(`${element.id}_upd`).checked
              });
            });
            axios.post('https://www.clearcall.tel:8078/gateway/update',{
                id:this.state.idUpdate,
                prefixes:arr,
                title:this.state.titleUpdate,
                phone_use:this.state.phone_use_update,
                domain:this.state.domain_update,
                params:this.state.params_update,
                calee_num:this.state.calee_num_update,
                calee_name:this.state.calee_name_update,
                provider:this.state.providerUpdate,
                additional_prefix:this.state.additional_prefixUpdate,
                titlemask:this.state.titlemask_update
            })
            .then((res)=>{
                console.log(res);
                Swal.fire({
                    icon:'success',
                    title: 'Result',
                    text: res.statusText,
                    customClass: {
                    confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                })
                $('#table').DataTable().ajax.reload();
            })
            .catch((err)=>{
                Swal.fire({
                icon: 'warning',
                title: 'Result',
                text: err,
                customClass: {
                confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            console.log(err)
            });

            this.forceUpdate();
        }
        render(){
            const { t } = this.props;
            return(
            <div className="table-responsive text-nowrap" style={{width:"70vw"}}>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel" style={{width:'22.5vw',fontSize:"1.5vh"}}>
                    <div className="offcanvas-header">
                        <h3 id="offcanvasAddUserLabel" className="offcanvas-title">Gateway</h3>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0" style={{height:"100%"}}>
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <br />
            <label className="form-label" htmlFor="teams-list">{t("companiesPrefixes")}</label>
            <div className="list-group" style={{maxHeight:'30vh', overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                {
                    this.state.prefixes.map((val,ind)=>{
                        return <label className="list-group-item" key={ind}>
                            <label class="switch">
                            <input type="checkbox" class="switch-input" id={val.id}  />
                            <span class="switch-toggle-slider">
                                <span class="switch-on">
                                <i class="bx bx-check"></i>
                                </span>
                                <span class="switch-off">
                                <i class="bx bx-x"></i>
                                </span>
                            </span>
                            <span class="switch-label" style={{fontSize:"1.5vh"}}>{val.prefix}_{val.title}</span>
                            </label>
                        </label>
                    })
                }
                
            </div>
            <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Trunk name</label>
                            <input type="text"
                                className="form-control"  style={{fontSize:"1.5vh"}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={this.state.title}
                                onChange={(e)=>this.handlePriceChange(e)}
                                />   
                        </div>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Title</label>
                            <input type="text"
                                className="form-control"  style={{fontSize:"1.5vh"}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={this.state.titlemask}
                                onChange={(e)=>this.handlePriceMaskChange(e)}
                                />   
                        </div>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Domain</label>
                            <input type="text"
                                className="form-control" 
                                id="add-gateway-title"  style={{fontSize:"1.5vh"}}
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={this.state.domain}
                                onChange={(e)=>this.handleDomainChoose(e)}
                                />   
                        </div>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Additional prefix</label>
                            <input type="text"
                                className="form-control"  style={{fontSize:"1.5vh"}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                disabled={this.state.additional_prefix_disabled_add}
                                value={this.state.additional_prefix}
                                onChange={(e)=>this.handleAddPrefixChoose(e)}
                                />  
                                <div> 
                                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Active</label>
                                    <br/>
                                    <input type="checkbox" id="use" className="form-check-input" checked={this.state.additional_prefix_disabled_add}
                                    onChange={(e)=>{this.handleAddPrefDisabledChooseAdd(e)}}/>
                                </div> 
                        </div>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Params</label>
                                <textarea  style={{fontSize:"1.5vh"}}
                                className="form-control" 
                                id="exampleFormControlTextarea1" 
                                rows={5} defaultValue={""}
                                value={this.state.params}
                                onChange={(e)=>this.handleParamsChange(e)} />
                        </div>
                        <br />
                        <div className="col mb-0" style={{display:'flex',flexDirection:"row", justifyContent:"space-between",flexWrap:'wrap',width:"200px"}}>
                            <div>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Use phone</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.phone_use}
                                onChange={(e)=>{this.handlePhoneChoose(e)}}/>
                            </div>
                            <div> 
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Show number</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.calee_num}
                                onChange={(e)=>{this.handleCaleeNumChoose(e)}}/>
                            </div>
                            <div>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Provider</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.provider}
                                onChange={(e)=>{this.handleProviderChoose(e)}}/>
                                </div>
                            <div> 
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Show prefix</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.calee_name}
                                onChange={(e)=>{this.handleCaleeNameChoose(e)}}/>
                            </div>
                        </div>
                        <br />
                        <br />
                        <button type="button" className='btn btn-info me-sm-3 me-1' 
                        data-bs-toggle="modal" data-bs-target="#modalTop"
                        style={{marginRight:5,fontSize:"1.5vh"}}
                        >Методичка</button>
                        <button 
                            id='submitbtn'
                            type="button" 
                            className="btn btn-success me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleSubmit()}
                            disabled={!this.state.valid} style={{fontSize:"1.5vh"}}
                            >{t("submitbtn")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary"  style={{fontSize:"1.5vh"}}
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>
                    </div>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasEdit" aria-labelledby="offcanvasEditLabel" style={{width:'22.5vw',fontSize:"1.5vh"}}>
                    <div className="offcanvas-header">
                        <h3 id="offcanvasEditLabel" className="offcanvas-title">Gateway update</h3>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0">
                        <form className="add-new-user pt-0" id="addNewTeam" onsubmit="return false">
                        <br />
            <label className="form-label" htmlFor="teams-list">{t("companiesPrefixes")}</label>
            <div className="list-group" style={{maxHeight:'40vh', overflow:"scroll", WebkitOverflowScrolling:'touch'}}>
                {
                    this.state.prefixes.map((val,ind)=>{
                        return <label className="list-group-item" key={ind}>
                            <label class="switch">
                            <input type="checkbox" class="switch-input" id={`${val.id}_upd`}  />
                            <span class="switch-toggle-slider">
                                <span class="switch-on">
                                <i class="bx bx-check"></i>
                                </span>
                                <span class="switch-off">
                                <i class="bx bx-x"></i>
                                </span>
                            </span>
                            <span class="switch-label" style={{fontSize:"1.5vh"}}>{val.prefix}_{val.title}</span>
                            </label>
                        </label>
                    })
                }
                
            </div>
            <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Trunk name</label>
                            <input type="text"
                                className="form-control" 
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title" style={{fontSize:"1.5vh"}}
                                value={this.state.titleUpdate}
                                onChange={(e)=>this.handleUpdatePriceChange(e)}
                                />   
                        </div>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Title</label>
                            <input type="text"
                                className="form-control" 
                                id="add-gateway-title"  style={{fontSize:"1.5vh"}}
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={this.state.titlemask_update}
                                onChange={(e)=>this.handleUpdatePriceMaskChange(e)}
                                />   
                        </div>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Domain</label>
                            <input type="text"
                                className="form-control"  style={{fontSize:"1.5vh"}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                value={this.state.domain_update}
                                onChange={(e)=>this.handleUpdateDomainChoose(e)}
                                />   
                        </div>
                        <br />
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Additional prefix</label>
                            <input type="text"
                                className="form-control"  style={{fontSize:"1.5vh"}}
                                id="add-gateway-title" 
                                name="gatewayTitle" 
                                aria-label="Title"
                                disabled={this.state.additional_prefix_disabled}
                                value={this.state.additional_prefixUpdate}
                                onChange={(e)=>this.handleUpdateAddPrefixChoose(e)}
                                />   
                                <div> 
                                    <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Active</label>
                                    <br/>
                                    <input type="checkbox" id="use" className="form-check-input" checked={this.state.additional_prefix_disabled}
                                    onChange={(e)=>{this.handleAddPrefDisabledChoose(e)}}/>
                                </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="add-agent-fullname" style={{fontSize:"1.5vh"}}>Params</label>
                                <textarea  style={{fontSize:"1.5vh"}}
                                className="form-control" 
                                id="exampleFormControlTextarea1" 
                                rows={5} defaultValue={""}
                                value={this.state.params_update}
                                onChange={(e)=>this.handleUpdateParamsChange(e)} />
                        </div>
                        <br />
                        <div className="col mb-0" style={{display:'flex',flexDirection:"row", justifyContent:"space-between",flexWrap:'wrap',width:"200px"}}>
                            <div>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Use phone</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.phone_use_update}
                                onChange={(e)=>{this.handleUpdatePhoneChoose(e)}}/>
                            </div>
                            <div> 
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Show number</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.calee_num_update}
                                onChange={(e)=>{this.handleUpdateCaleeNumChoose(e)}}/>
                            </div>
                            <div>
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Provider</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.providerUpdate}
                                onChange={(e)=>{this.handleUpdateProviderChoose(e)}}/>
                                </div>
                            <div> 
                                <label htmlFor="use" className="form-label" style={{fontSize:"1.5vh"}}>Show prefix</label>
                                <br/>
                                <input type="checkbox" id="use" className="form-check-input" checked={this.state.calee_name_update}
                                onChange={(e)=>{this.handleUpdateCaleeNameChoose(e)}}/>
                            </div>
                        </div>
                        <br />
                        <button type="button" className='btn btn-info me-sm-3 me-1' 
                        data-bs-toggle="modal" data-bs-target="#modalTop"
                        style={{marginRight:5,fontSize:"1.5vh"}}
                        >Методичка</button>
                        <button 
                            id='updatebtn'
                            type="button" 
                            className="btn btn-warning me-sm-3 me-1 data-submit"
                            onClick={()=>this.handleUpdate()} style={{fontSize:"1.5vh"}}
                            >{t("updatebtn")}</button>
                        <button 
                            type="button" 
                            className="btn btn-label-secondary"  style={{fontSize:"1.5vh"}}
                            data-bs-dismiss="offcanvas">
                            {t("cancelbtn")}</button>
                        </form>
                    </div>
                </div>
                <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1} >
                    <div className="modal-dialog modal-dialog-centered modal-lg"  style={{maxWidth:'50vw !important',fontSize:'1.5vh'}}>
                        <form className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="modalTopTitle">Методичка</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                <table id="table1212" className="table table-stripped align-items-center justify-content-center mb-0 cell-border" style={{width:'30vw'}}>
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>Значение</th>
                                        <th className="text-uppercase text-secondary font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>переменная</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Название транка</td>
                                        <td>{'${resss[0].title}'}</td>
                                    </tr>
                                    <tr>
                                        <td>Ююайди нового звонка</td>
                                        <td>{'${uuid_call}'}</td>
                                    </tr>
                                    <tr>
                                        <td>Номер куда звоним</td>
                                        <td>{"${telephResponse['called_number']}"}</td>
                                    </tr>
                                    <tr>
                                        <td>Номер куда звоним(без префикса)</td>
                                        <td>{"${telephResponse['called_number'].slice(3)}"}</td>
                                    </tr>
                                    <tr>
                                        <td>Префикс</td>
                                        <td>{"${telephResponse['called_number'].slice(0,3)}"}</td>
                                    </tr>
                                    <tr>
                                        <td>Имя того, кто звонит</td>
                                        <td>{"${telephResponse['caller_number']}"}</td>
                                    </tr>
                                    <tr>
                                        <td>Номер для подставления</td>
                                        <td>{'${item.number}'}</td>
                                    </tr>
                                </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </form>
                    </div>
                </div>
                <button className='btn btn-primary' style={{marginBottom:5,fontSize:"1.5vh"}} 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasAddUser">
                    Add new gateway
                </button>
                <table id="table" className="table table-stripped align-items-center justify-content-center mb-0 cell-border" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th style={{fontSize:"1.5vh"}}></th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>Title</th>
                            {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>Prefix_Title</th> */}
                            {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>Prefix</th> */}
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>Domain</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>Phone_use</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>Params</th>
                            {/* <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThPrefix")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThInbound")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThOutbound")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThOperator")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThPrice")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThStatus")}</th> */}
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThUpdate")}</th>
                            <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2" style={{fontSize:"1.5vh"}}>{t("managementThDelete")}</th>
                        </tr>
                    </thead>
        
                    <tbody style={{fontSize:"1.8vh"}}>
                            {
                            //this.showTable()
                            }
                    </tbody>
                </table>
            </div>
        )
        }
    }
export default withTranslation()(GatewayTable);